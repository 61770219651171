import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetAllTreatments = async (search, pageNo, pageSize) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAllTreatments/`,
    {
      clinicId,
      search,
      pageNo,
      pageSize,
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};

// export const GetTreatmentDetails = async (id) => {
//   const promise = axios.get(
//     `/${process.env.REACT_APP_API_VERSION_V1}/Treatment/${id}`
//   );

//   const dataPromise = promise.then((response) => response.data);

//   return dataPromise;
// };


export const GetTreatmentDetails = async (slug) => {
  try {
    const response = await axios.post(
      `/${process.env.REACT_APP_API_VERSION_V1}/Treatment/`,
      {
        clinicId: clinicId,
        slug: slug
      }
    );

    return response.data;
  } catch (error) {
    // Handle errors here
    console.error('Error fetching treatment details:', error);
    throw error; // Re-throwing the error for the caller to handle
  }
};
