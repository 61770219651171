import React from 'react';
import PhoneSolidIcon from '../Icons/PhoneSolidIcon';
import error from '../../static/home/404.png';
import MailSolidIcon from '../Icons/MailSolidIcon';
import { useSelector } from 'react-redux';

const ServiceExpired = () => {

    const defaultHealthCenter = useSelector(
        (state) => state.clinic.clincObj.defaultHealthCenter
    );
    
    function formatPhoneNumber(param) {
        const countryCode = param.countryCode;
        const phoneNumber = param.phoneNumber;
    
        const formattedNumber = `+${countryCode} ${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    
        return formattedNumber ;
    }
    
    console.log(defaultHealthCenter        );
    return (
        <div className='main_expired'>
            <div className='expired'>
                <div className='upper'>
                    <img src={error} alt="" />
                    <h2>Sorry! <br />Service has been  expired. </h2>
                </div>
                <div className='contect'>
                    <div className='subCon'>
                        <p>Please contact your clinic/hospital.</p>
                        <div className='contect_info'>
                            <div className='info'>
                                <PhoneSolidIcon />
                                <span>{formatPhoneNumber(defaultHealthCenter)}</span>
                            </div>
                            <div  className='info'>
                                <MailSolidIcon />
                                <span>{defaultHealthCenter.email}</span>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    );
};

export default ServiceExpired;