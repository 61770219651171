import React, { useEffect, useRef, useState } from 'react';
import img from "../../static/Widget/img.png";
import apptType from "../../static/Widget/apptType.svg";
import date from "../../static/Widget/date.svg";
import time from "../../static/Widget/time.svg";
import persone from "../../static/Widget/persone.svg";
import location from "../../static/Widget/location.svg";
import clock from "../../static/Widget/tik_mark.svg";
import DocDrop from './Common/DocDrop';
import { Link, useNavigate } from 'react-router-dom';
import TimeDrop from './Common/TimeDrop';
import { GetHealthCenterDetails } from '../../services/clinicservice';
import { useSelector } from 'react-redux';
import { GetUpcomingAppointments } from '../../services/apptservice';
import moment from 'moment';
import noUpAppoinment from "../../static/Appointments/amico.png";
import { Skeleton } from '@mui/material';
import VideoCallButton from '../VideoCall/VideoCallButton';

const MyApptWidget = ({ healthCenterObj }) => {

  const [isActive, setIsActive] = useState({
    select: "Video",
    DocDrop: false,
    TimeDrop: false,
    ApptDrop: false,
    DateDrop: false,
    TreatmentDrop: false,
  });

  const navigate = useNavigate()

  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  const userObj = useSelector(state => state.user.userObj);

  const PAGE_SIZE = 10;
  const APP_OBJ = {
    doctorId: 0,
    pageNo: 1,
    healthCenterId: 0,
    pageSize: PAGE_SIZE,
    imageSize: 0,
    patientId: 2,
    type: "",
    appointmentType: "",
  };

  const [upcommingAppointment, setUpcommingAppointment] = useState({
    list: {},
    isLoading: false,
    isSuccess: false,
    msg: "",
    pageNo: 1,
    noOfPages: true,
    count: 0,
  });

  useEffect(() => {
    let temmObjUpcomming = {
      ...APP_OBJ,
      healthCenterId: healthCenterObj?.id,
      type: "UpComing",
      pageNo: upcommingAppointment.pageNo,
      pageSize: 100,
      patientId: userObj?.patientData?.id,
    };
    fetchAppointments(temmObjUpcomming);
  }, [userObj]);

  const fetchAppointments = (obj) => {
    if (obj.type === "UpComing") {
      setUpcommingAppointment({
        ...upcommingAppointment,
        isLoading: true,
      });

      let tempObj = {
        ...obj,
        type: "",
      };

      GetUpcomingAppointments(tempObj, userObj.token)
        .then((res) => {

          if (res.appointments.length > 1) {
            res.appointments.sort((a, b) => new Date(a.scheduledAtUtc) - new Date(b.scheduledAtUtc));
          }

          let earliestAppointment = res.appointments.length > 0 ? res.appointments[0] : null;

          let tempPrevObj = {
            ...upcommingAppointment,
            isLoading: false,
            isSuccess: true,
            count: res.count,
            list: earliestAppointment ? earliestAppointment : null,
          };

          setUpcommingAppointment({
            ...tempPrevObj,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    }
  };


  const getPeriod = (time) => {

    const formattedTime = moment
      .utc(time)
      .local()
      .format("h:mm A");

    const hour = moment(formattedTime, "h:mm A").hour();

    if (hour >= 5 && hour < 12) {
      return "Morning";
    } else if (hour >= 12 && hour < 17) {
      return "Afternoon";
    } else if (hour >= 17 && hour < 21) {
      return "Evening";
    } else {
      return "Night";
    }
  };

  const [apptfor, setApptfor] = useState("Myself");

  useEffect(() => {
    if (upcommingAppointment?.list?.appointmentForRelation) {
      setApptfor(upcommingAppointment?.list?.appointmentForRelation)
    } else {
      setApptfor("Myself")
    }
  }, [upcommingAppointment?.list]);

  const [remainingTime, setRemainingTime] = useState({ time: "Join Now", text: "" });

  const getTimeDifference = () => {
    const currentTime = moment.utc();
    const upcommingTime = moment.utc(upcommingAppointment?.list?.scheduledAtUtc).local();

    const duration = moment.duration(upcommingTime.diff(currentTime));

    const days = duration.days();
    const hours = duration.hours();
    const minutes = duration.minutes();
    const seconds = duration.seconds();

    const formatTime = (time, time2, unit) => ({
      time: `${time.toString().padStart(2, '0')}:${time2.toString().padStart(2, '0')}`,
      text: time > 1 ? `${unit}S` : unit
    });

    if (days > 0 || hours > 0 || minutes > 0 || seconds > 0) {
      setRemainingTime(
        days > 0
          ? formatTime(days, hours, 'DAY')
          : hours > 0
            ? formatTime(hours, minutes, 'HOUR')
            : minutes > 0
              ? formatTime(minutes, seconds, 'MINUTE')
              : seconds > 0
              && formatTime(minutes, seconds, 'SECOND')
      );
    } else {
      setRemainingTime({ time: "Join Now", text: "" });
      clearInterval(intervalRef.current);
    }
  };

  const intervalRef = useRef(null);

  useEffect(() => {
    intervalRef.current = setInterval(() => getTimeDifference(), 1000);
    return () => clearInterval(intervalRef.current);
  }, [upcommingAppointment]);

  const userToken = useSelector((state) => state.user.userObj.token);

  const navigateToAppt = () => {
    navigate(`/appointmentsDetails/${upcommingAppointment.list?.id}`)
  }

  const loadingSkeleton = (
    <>
      <div className='widget'>
        <div className='widget_box'>

          <div className='widgetItem doc_info'>
            <Skeleton variant='circular' width={48} height={48} />
            <div className='widDetails'>
              <Skeleton variant='text' width="50%" height={25} />
              <div className='docName'>
                <Skeleton variant='text' width="100%" height={25} />
              </div>

              <Skeleton variant='text' width="70%" height={20} />
            </div>

          </div>
          <div className='widgetItem loct'>
            <Skeleton variant='rectangle' width={40} height={50} />
            <div className='widDetails '>
              <Skeleton variant='text' width="50%" height={20} />
              <Skeleton variant='text' width="100%" height={20} />
              <Skeleton variant='text' width="50%" height={20} />
            </div>
          </div>
          <div className='widgetItem date'>
            <Skeleton variant='rectangle' width={40} height={50} />
            <div className='widDetails'>
              <Skeleton variant='text' width="50%" height={20} />
              <Skeleton variant='text' width="100%" height={20} />
              <Skeleton variant='text' width="50%" height={20} />
            </div>
          </div>
          <div className='widgetItem time'>
            <Skeleton variant='rectangle' width={40} height={50} />
            <div className='widDetails'>
              <Skeleton variant='text' width="50%" height={20} />
              <Skeleton variant='text' width="100%" height={20} />
              <Skeleton variant='text' width="50%" height={20} />
            </div>
          </div>
          <div className='widgetItem appt'>
            <Skeleton variant='rectangle' width={40} height={50} />
            <div className='widDetails'>
              <Skeleton variant='text' width="50%" height={20} />
              <Skeleton variant='text' width="100%" height={20} />
              <Skeleton variant='text' width="50%" height={20} />
            </div>
          </div>
        </div>
      </div>
    </>
  )

  return (
    <>
      {upcommingAppointment.isLoading ?
        loadingSkeleton
        :
        <>
          {upcommingAppointment.list ?
            <>
              <div className='widget'>
                <div className='widget_header'>
                  <div className='headerTitle'>
                    Upcoming Appoinment
                  </div>
                  <div className='headerTime'>
                    <div className='time'>
                      <img src={clock} alt="" />
                      <span>{remainingTime?.time}</span>
                    </div>
                    <span className='mTime'>{remainingTime?.text}</span>
                  </div>
                  {
                    remainingTime?.time !== "Join Now" &&
                    <span className='rem_span'>Remaining</span>
                  }
                </div>
                <div className='widget_box'>

                  <div className='widgetItem doc_info'>
                    <div className="img">

                      <img src={upcommingAppointment?.list?.doctorPictureUrl} alt="" />
                    </div>
                    <div className='widDetails' onClick={() => setIsActive((prev) => ({ ...prev, DocDrop: !prev.DocDrop }))}>
                      <span className='sTitle'>DOCTOR</span>
                      <div className='docName'>
                        <span className='title'>
                          {upcommingAppointment?.list?.doctorName}
                        </span>
                      </div>
                      <span>{upcommingAppointment?.list?.doctorTitle}</span>
                    </div>

                    {/* <DocDrop dropActive={dropActive} /> */}
                  </div>
                  <div className='widgetItem loct'>
                    <img src={apptType} alt="" />
                    <div className='widDetails '>
                      <span className='sTitle'>APPOINTMENT TYPE</span>
                      <span className='title'>{upcommingAppointment?.list?.appointmentType === "InPerson" ? "Clinic Visit" : "Video Call"}</span>
                      <span>{upcommingAppointment?.list?.doctorTitle}</span>
                    </div>
                    {/* <span className="free_tag">Free</span> */}
                  </div>
                  <div className='widgetItem date'>
                    <img src={date} alt="" />
                    <div className='widDetails'>
                      <span className='sTitle'>DATE</span>
                      <span className='title'>
                        {
                          moment
                            .utc(upcommingAppointment?.list?.scheduledAtUtc)
                            .local()
                            .format("D MMMM, YY")
                        }
                      </span>
                      <span>{moment.utc(upcommingAppointment?.list?.scheduledAtUtc).local().format("dddd")}</span>
                    </div>
                  </div>
                  <div className='widgetItem time' onClick={() => setIsActive((prev) => ({ ...prev, TimeDrop: !prev.TimeDrop }))}>
                    <img src={time} alt="" />
                    <div className='widDetails'>
                      <span className='sTitle'>TIME</span>
                      <span className='title'>
                        {
                          moment
                            .utc(upcommingAppointment?.list?.scheduledAtUtc)
                            .local()
                            .format("hh:mm A")
                        }
                      </span>
                      <span>{getPeriod(upcommingAppointment?.list?.scheduledAtUtc)}</span>
                    </div>
                    {/* <TimeDrop timeDrop={timeDrop} /> */}
                  </div>
                  <div className='widgetItem appt' onClick={() => setIsActive((prev) => ({ ...prev, ApptDrop: !prev.ApptDrop }))}>
                    <div className="img">

                      <img src={upcommingAppointment?.list?.patientPictureUrl} alt="" />
                    </div>
                    {/* <img src={persone} alt="" /> */}
                    <div className='widDetails'>
                      <span className='sTitle'>APPOINMENTS FOR</span>
                      <span className='title'>{upcommingAppointment?.list?.name}</span>
                      <span>{apptfor}</span>
                    </div>
                    {/* <DocDrop dropActive={apptDrop} /> */}
                  </div>
                </div>

                <div className='view_more text-center'>
                  <Link to="/appointments" className='text-center'>View more</Link>
                </div>

              </div>
              {
                remainingTime?.time !== "Join Now" && (
                  <div className="widgetBtn">
                    <button
                      className='btnr btnr-primary'
                      onClick={navigateToAppt}
                    >
                      {upcommingAppointment?.list?.appointmentType === "VideoCall" ?
                        "Join Now": "Check In"
                      }
                    </button>
                  </div>
                )
              }
            </>
            :
            <div className="col-12 noUpAppoin">
              <img className="img" src={noUpAppoinment} alt="" />
              <div>

                <h3>There is no upcoming appointment !</h3>
                {
                  (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) && (clinicObj.subscriptionPackage !== "Basic") &&
                  <Link to="/selectdoctor" className="btnr btnr-primary btnr-hover app_button">
                    Book Appointment
                    <span className="arr"></span>
                  </Link>
                }
              </div>
            </div>}

        </>
      }

    </>
  );
};

export default MyApptWidget;