import React from "react";
import { useState } from "react";
import Modal from "react-bootstrap/Modal";
import { Step, StepLabel, Stepper } from "@mui/material";
import { Col, Spinner } from "react-bootstrap";
import weight from "../../static/appt/weight.svg";
import temp from "../../static/appt/temp.svg";
import bp from "../../static/appt/bp.svg";

import Select from "@mui/material/Select";
import MenuItem from "@mui/material/MenuItem";

import { AnimatePresence, motion } from "framer-motion";
import { useSelector } from "react-redux";
import EditIcon from "../Icons/EditIcon";
import { updateUserHealthRecord } from "../../services/healthrecordService";

function HealthUpdate(props) {
    const { userHealth, setHealth } = props;
    const [show, setShow] = useState(false);
    const handleClose = () => {
        if (!isSaving) {
            setUserProfileState({
                weight: userHealth?.health?.weight ? userHealth?.health?.weight : "",
                weightUnit: userHealth?.health?.weightUnit ? userHealth?.health?.weightUnit : "kg",
                temperature: userHealth?.health?.temparature ? userHealth?.health?.temparature : "",
                temperatureUnit: userHealth?.health?.temparatureUnit ? userHealth?.health?.temparatureUnit : "cl",
                bloodPressureSystolic: userHealth?.health?.bloodPressureSys ? userHealth?.health?.bloodPressureSys : "",
                bloodPressureDiastolic: userHealth?.health?.bloodPressureDia ? userHealth?.health?.bloodPressureDia : "",
                pulseRate: userHealth?.health?.pulse ? userHealth?.health?.pulse : "",
                patientId: userHealth?.health ? userHealth?.health.patientId : "",
            });
            setStepsState(0);
            setShow(false);
        }
    };


    const handleShow = () => setShow(true);

    const steps = ["Weight", "Blood Pressure", "Temperature"];

    function ColorlibStepIcon(props) {
        const { active, completed, className } = props;

        return (
            <div>
                {completed ? (
                    <svg
                        aria-hidden="true"
                        className="MuiSvgIcon-root MuiSvgIcon-fontSizeMedium QontoStepIcon-completedIcon css-vubbuv"
                        data-testid="CheckIcon"
                        viewBox="0 0 24 24"
                    >
                        <path d="M9 16.17L4.83 12l-1.42 1.41L9 19 21 7l-1.41-1.41z"></path>
                    </svg>
                ) : (
                    <div className="QontoStepIcon-circle" />
                )}
            </div>
        );
    }

    const containerVariats = {
        hidden: {
            opacity: 0,
            x: "100vw",
        },
        visible: {
            opacity: 1,
            x: 0,
            transition: {
                delay: 0.2,
                type: "tween",
                // damping: 15,
                // mass: 0.5,
            },
        },
        exit: {
            opacity: 0,

            x: "-150px",
            transition: {
                delay: 0,
                type: "tween",
                // damping: 15,
                // mass: 0.5,
            },
        },
    };

    const [userProfileState, setUserProfileState] = useState({
        bloodPressureDia: userHealth.bloodPressureDia ? userHealth.bloodPressureDia : "",
        bloodPressureSys: userHealth.bloodPressureSys ? userHealth.bloodPressureSys : "",
        bloodSugar: userHealth.bloodSugar ? userHealth.bloodSugar : "",
        fastingBloodSugar: userHealth.fastingBloodSugar ? userHealth.fastingBloodSugar : "",
        pulse: userHealth.pulse ? userHealth.pulse : "",
        temparature: userHealth.temparature ? userHealth.temparature : "",
        temparatureUnit: userHealth.temparatureUnit ? userHealth.temparatureUnit : "cl",
        weight: userHealth.weight ? userHealth.weight : "",
        weightUnit: userHealth.weightUnit ? userHealth.weightUnit : " kg",
        patientId: userHealth.patientId ? userHealth.patientId : ""
    });


    const [stepsState, setStepsState] = useState(0);

    const userToken = useSelector((state) => state.user.userObj.token);

    const submit = () => {
        if (stepsState === 2) {
            try {
                setIsSaving(true);
                updateUserHealthRecord(userProfileState, userToken)
                    .then((res) => {
                        setHealth((prevValue) => ({
                            ...prevValue,
                            health: {
                                ...prevValue.health,
                                ...res.patientData,
                            },
                        }));
                    })

            } catch (err) {
                console.log(err);
            } finally {
                setIsSaving(false)
                handleClose();
            }

        } else {
            setStepsState(stepsState + 1);
        }
    };



    const checkIfNumber = (value) => {
        const numberValue = value.replace(/[^0-9]/g, '');
        return numberValue;
    }

    const [isSaving, setIsSaving] = useState(false);

    return (
        <>
            <button
                className="btnr btnr-primary-outline"
                onClick={handleShow}
            >
                <EditIcon />update
            </button>

            <Modal
                show={show}
                onHide={handleClose}
                size="lg"
                className="hpmodal "
                centered={true}
            >
                <Modal.Header closeButton>
                    <div className="header">
                        Update Health profile{" "}
                        <span>
                            For better consultation experience please update patient’s health
                            condition
                        </span>
                    </div>
                </Modal.Header>
                <Modal.Body>
                    <Stepper alternativeLabel activeStep={stepsState}>
                        {steps.map((label) => (
                            <Step key={label}>
                                <StepLabel StepIconComponent={ColorlibStepIcon}>
                                    {label}
                                </StepLabel>
                            </Step>
                        ))}
                    </Stepper>
                    <div className="body">
                        <AnimatePresence initial={false}>
                            {stepsState === 0 && (
                                <motion.div
                                    className="row"
                                    variants={containerVariats}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                    <Col sm={8} xs={12} className="bpox-p">
                                        <div className="bpox">
                                            <div className="hd">What’s your current weight?</div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    value={userProfileState.weight}
                                                    placeholder="Weight"
                                                    onChange={(e) => {
                                                        setUserProfileState((prevValue) => ({
                                                            ...prevValue,
                                                            weight: checkIfNumber(e.target.value),
                                                        }));
                                                    }}
                                                />

                                                <div>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        className="dropper"
                                                        // open={open}
                                                        // onClose={handleClose}
                                                        // onOpen={handleOpen}
                                                        defaultValue="kg"
                                                        value={userProfileState.weightUnit}
                                                        label="Age"
                                                        onChange={(e) =>
                                                            setUserProfileState((prevValue) => ({
                                                                ...prevValue,
                                                                weightUnit: e.target.value,
                                                            }))
                                                        }
                                                    >
                                                        <MenuItem value={"kg"}>kg</MenuItem>
                                                        <MenuItem value={"lbs"}>lbs</MenuItem>
                                                    </Select>

                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <img src={weight} alt="weight" />
                                    </Col>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {stepsState === 1 && (
                                <motion.div
                                    className="row"
                                    variants={containerVariats}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                    <Col sm={8} xs={12} className="bpox-p">
                                        <div className="bpox">
                                            <div className="hd">Blood Pressure</div>
                                            <div className="input">
                                                <input
                                                    value={userProfileState.bloodPressureSys}
                                                    type="text"
                                                    placeholder="Systolic"
                                                    onChange={(e) =>
                                                        setUserProfileState((prevValue) => ({
                                                            ...prevValue,
                                                            bloodPressureSys: checkIfNumber(e.target.value),
                                                        }))
                                                    }
                                                />

                                                <div className="staticspan">
                                                    <span>mm/hg</span>
                                                </div>
                                            </div>
                                            <div className="input">
                                                <input
                                                    value={userProfileState.bloodPressureDia}
                                                    type="text"
                                                    placeholder="Diastolic"
                                                    onChange={(e) =>
                                                        setUserProfileState((prevValue) => ({
                                                            ...prevValue,
                                                            bloodPressureDia: checkIfNumber(e.target.value),
                                                        }))
                                                    }
                                                />

                                                <div className="staticspan">
                                                    <span>mm/hg</span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="bpox mt-3">
                                            <div className="hd">Pulse Rate</div>
                                            <div className="input">
                                                <input
                                                    type="text"
                                                    value={userProfileState.pulse}
                                                    placeholder="Heart beat"
                                                    onChange={(e) =>
                                                        setUserProfileState((prevValue) => ({
                                                            ...prevValue,
                                                            pulse: checkIfNumber(e.target.value),
                                                        }))
                                                    }
                                                />

                                                <div className="staticspan">
                                                    <span>bpm</span>
                                                </div>
                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <img src={bp} alt="bp" />
                                    </Col>
                                </motion.div>
                            )}
                        </AnimatePresence>
                        <AnimatePresence>
                            {stepsState === 2 && (
                                <motion.div
                                    className="row"
                                    variants={containerVariats}
                                    initial="hidden"
                                    animate="visible"
                                    exit="exit"
                                >
                                    <Col sm={8} xs={12} className="bpox-p">
                                        <div className="bpox">
                                            <div className="hd">What’s the body temperature now?</div>
                                            <div className="input">
                                                <input
                                                    value={userProfileState.temparature}
                                                    type="number"
                                                    placeholder="Temperature"
                                                    onChange={(e) =>
                                                        setUserProfileState((prevValue) => ({
                                                            ...prevValue,
                                                            temparature: e.target.value,
                                                        }))
                                                    }
                                                />

                                                <div>
                                                    <Select
                                                        labelId="demo-controlled-open-select-label"
                                                        id="demo-controlled-open-select"
                                                        className="dropper"
                                                        // open={open}
                                                        // onClose={handleClose}
                                                        // onOpen={handleOpen}
                                                        defaultValue="cl"
                                                        value={userProfileState.temperatureUnit}
                                                        label="Age"
                                                        onChange={(e) =>
                                                            setUserProfileState((prevValue) => ({
                                                                ...prevValue,
                                                                temperatureUnit: e.target.value,
                                                            }))
                                                        }
                                                    >
                                                        <MenuItem value={"cl"}>°C</MenuItem>
                                                        <MenuItem value={"fh"}>°F</MenuItem>
                                                    </Select>
                                                </div>

                                            </div>
                                        </div>
                                    </Col>
                                    <Col sm={4} xs={12}>
                                        <img src={temp} alt="temp" />
                                    </Col>
                                </motion.div>
                            )}
                        </AnimatePresence>
                    </div>
                </Modal.Body>

                <Modal.Footer>
                    {
                        stepsState !== 0 && (
                            <button
                                className="btnr btnr-secondary"
                                disabled={stepsState === 0 ? "disabled" : ""}
                                onClick={() => setStepsState(stepsState - 1)}
                            >
                                Previous
                            </button>
                        )
                    }

                    <button className="btnr btnr-primary" onClick={() => submit()}>
                        {stepsState === 2 && (
                            <>
                                {isSaving && (
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                    />
                                )}
                                Submit
                            </>
                        )}
                        {stepsState < 2 && <>Next</>}
                    </button>
                </Modal.Footer>
            </Modal>
        </>
    );
}

export default HealthUpdate;