import axios from 'axios';

const clinicId = process.env.REACT_APP_CLINIC_ID;


export const userHealthRecord = async (Obj, token) => {
    const promise = axios.post(
        `/${process.env.REACT_APP_API_VERSION_V1}/GetPatientHealthProfileById/`,
        {
            clinicId
        },
        {
            headers: { Authorization: `Bearer  ${token}` },
        }
    );

    const dataPromise = promise.then((response) => response.data.response);

    return dataPromise;
};


export const updateUserHealthRecord = async (Obj, token) => {
    const promise = axios.post(
        `/${process.env.REACT_APP_API_VERSION_V1}/UpdatePatientHealthProfile/`,
        { ...Obj },
        { headers: { Authorization: `Bearer  ${token}` } }
    );

    const dataPromise = promise.then((response) => response.data.response);

    return dataPromise;
};

// const clinicId = process.env.REACT_APP_CLINIC_ID;

// export const userHealthRecord = async (obj, token) => {
//     const url = `https://uat.digitalrx.io/api/v1/HealthRecordSummary/`;

//     const headers = {
//         Authorization: `Bearer ${token}`,
//         'Content-Type': 'application/json', // Set the Content-Type if needed
//     };
//     console.log(token)

//     const options = {
//         method: 'POST',
//         headers: headers,
//         body: JSON.stringify({
//             ...obj,
//             clinicId,
//             pageIndex: 1,
//             pageSize: 10
//         }),
//     };

//     try {
//         const response = await fetch(url, options);
//         const data = await response.json();
//         return data.response;
//     } catch (error) {
//         console.error('Error fetching user health record:', error);
//         throw error; // Re-throw the error to handle it in the component
//     }
// };
