import React from "react";
import { GetClinicFollowUpAppointmentsApi } from "../../services/apptservice";
import { useState } from "react";
import { useEffect } from "react";
import moment from "moment";
import ip from "../../static/appt/ip.svg";
import vc from "../../static/appt/vc.svg";
import cal from "../../static/userapp/cal.svg";
import fup from "../../static/appt/fup.svg";

import clksm from "../../static/userapp/clk-sm.svg";
import noUpAppoinment from "../../static/Appointments/amico.png";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { Row } from "react-bootstrap";

function FollowUpList(props) {
  const [FollowUpList, setFollowUpList] = useState([]);
  const userToken = useSelector((state) => state.user.userObj.token);
  const { patientId } = props;

  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  let tempObj = {
    doctorId: 0,
    pageNo: 1,
    healthCenterId: 0,
    pageSize: 100,
    imageSize: 0,
    patientId: patientId,
    appointmentType: "",
  };

  useEffect(() => {
    GetClinicFollowUpAppointmentsApi(tempObj, userToken)
      .then((res) => {
        setFollowUpList(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const navigate = useNavigate();
  const location = useLocation();

  const navigateToBookAppt = (stateforNextPage) => {

    navigate("/bookappt", { state: stateforNextPage });
  };

  return (
    FollowUpList.length > 0 && (
      <>

        <div className="FollowUp">
          <div className="header">
            <div className="h1">Follow-ups
              <span>{FollowUpList?.length > 0 && ` (${FollowUpList.length})`}</span>
            </div>
          </div>
          <div className="body">
            <div className="list">
              <Swiper
                slidesPerView={"auto"}
                centeredSlides={false}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                // modules={[Pagination, Navigation]}
                // navigation={true}

                className="app-sch-card-parent mySwiper"
              >
                {FollowUpList.length > 0 ? (
                  FollowUpList.map((appt, index) => {
                    return (
                      <SwiperSlide
                        className={`apt-slide`}
                        // className={`apt-card ${getType(appt.appointmentType)}`}
                        key={index}
                      >
                        <div className={`apt-card`}>
                          <div className="folowUpTop">
                            <span>
                              Follow-up Request
                            </span>
                          </div>

                          <div className="doc">
                            <div className="img">
                              <img
                                src={appt.doctorDto.pictureUrl}
                                alt="doc"
                              />
                            </div>
                            <div className="txt">
                              <div className="name">
                                {appt.doctorDto.name}
                              </div>
                              <div className="title">
                                {appt.doctorDto.title}
                              </div>
                              <div className="date">
                                {moment
                                  .utc(appt.scheduledAtUtc)
                                  .local()
                                  .format("dddd, MMMM D")}

                                {
                                  appt.isFreeFollowUp &&
                                  <span className="free">free</span>
                                }
                              </div>
                            </div>

                          </div>
                          <div className="blwbody">
                            {/* <div className="cldr">
                              <div className="img">
                                <img src={clksm} alt="sd" />
                              </div>
                              <div className="text">
                                <div className="title">
                                  Starts at{" "}
                                  <span>
                                    {`${moment
                                      .utc(appt.scheduledAtUtc)
                                      .local()
                                      .format("hh:mm a")} `}
                                  </span>
                                </div>
                              </div>
                            </div> */}
                            <div className="">
                              <button className="btnr btnr-primary ms-2 d-none">
                                Book Follow Up <span></span>{" "}
                              </button>
                              <button
                                // to={`/appointmentsDetails/${appt.id}`}
                                className="btnr btnr-sm btnr-primary"
                                onClick={() =>
                                  navigateToBookAppt({
                                    doctor: appt.doctorDto,
                                    apptType: appt.appointmentType,
                                    processObj: appt.treatment,
                                    processId: appt.treatmentId,
                                    followup: true,
                                    apptId: appt.id,
                                    isFreeFollowUp: appt.isFreeFollowUp,
                                  })
                                }
                              >
                                Confirm
                              </button>
                            </div>
                          </div>
                        </div>

                      </SwiperSlide>
                    );
                  })
                ) : (
                  <div className="col-12 noUpAppoin">
                    <img className="img" src={noUpAppoinment} alt="" />
                    <h3>There is no upcoming appointment !</h3>
                    {
                      (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) && (clinicObj.subscriptionPackage !== "Basic") &&
                      <Link to="/selectdoctor" className="btnr btnr-primary btnr-hover app_button">
                        Book Appointment
                        <span className="arr"></span>
                      </Link>
                    }
                  </div>
                )
                }
              </Swiper>

            </div>
          </div>
        </div>

        {/* <div className="follow-up-list-parent">
          <div className="header">
            <div className="img">
              <img src={fup} alt="s" />
            </div>

            <span>Follow-ups</span>
          </div>
          <Swiper
            slidesPerView={"auto"}
            centeredSlides={false}
            spaceBetween={20}
            pagination={{
              clickable: true,
            }}
            modules={[Pagination]}
            className="list"
          >
            {FollowUpList.map((fup, index) => (
              <SwiperSlide className="fup-card" key={index}>
                <div className="doctor">
                  <div className="img">
                    <img src={fup.doctorDto.pictureUrl} alt="doc" />
                  </div>

                  <div className="title">
                    <span className="header">{fup.doctorDto.name}</span>
                    {fup.doctorDto.title}
                  </div>
                </div>
                <div className="icn">
                  <div className="img">
                    {fup.appointmentType === "VideoCall" && (
                      <img src={vc} alt="asd" />
                    )}
                    {fup.appointmentType === "InPerson" && (
                      <img src={ip} alt="asd" />
                    )}
                    {fup.appointmentType === "Treatment" && (
                      <img src={ip} alt="asd" />
                    )}
                  </div>

                  <div className="title">
                    <span className="header">Appointment type </span>
                    {fup.appointmentType === "VideoCall" && <>Video Call</>}
                    {fup.appointmentType === "InPerson" && <>In Person</>}
                    {fup.appointmentType === "Treatment" && <>Treatment</>}
                  </div>
                </div>
                <div className="icn">
                  <div className="img">
                    <img src={cal} alt="doc" />
                  </div>

                  <div className="title">
                    <span className="header">Date </span>
                    {moment(fup.followUpDate).format("DD, MMMM YYYY")}
                  </div>
                </div>
                <div className="text-center w-100">
                  <button
                    className="btnr btnr-primary"
                    onClick={() =>
                      navigateToBookAppt({
                        doctor: fup.doctorDto,
                        apptType: fup.appointmentType,
                        processObj: fup.treatment,
                        processId: fup.treatmentId,
                        followup: true,
                        apptId: fup.id,
                        isFreeFollowUp: fup.isFreeFollowUp,
                      })
                    }
                  >
                    Book Appointment
                  </button>
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div> */}
      </>
    )
  );
}

export default FollowUpList;
