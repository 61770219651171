import React from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import { Col, Row } from "react-bootstrap";

import loc from "../static/clinic/loc.svg";
import phone from "../static/clinic/phone.svg";
import mail from "../static/clinic/mail.svg";
import pat1 from "../static/clinic/pat1.svg";
import pat2 from "../static/clinic/pat2.svg";

import lab from "../static/home/lab.svg";
import pser from "../static/home/pser.svg";
import equip from "../static/home/equip.svg";
import team from "../static/home/team.svg";

import mission from "../static/clinic/mission.svg";
import vission from "../static/clinic/vission.svg";
import clock from "../static/clinic/clock.svg";

import { useSelector } from "react-redux";

import Carousel from "react-bootstrap/Carousel";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination, Navigation } from "swiper";
import "swiper/css";
import "swiper/scss/navigation";

import {
  GetHealthCenterDetails,
  Getfacility,
  Getstaff,
} from "../services/clinicservice";
import { useEffect } from "react";
import { useState } from "react";
import Skeleton from "@mui/material/Skeleton";
import Gallery from "../components/microcomponenets/Gallery";

import daysImg from "../static/clinic/open7days.png";
import daysImgSm from "../static/clinic/open7daysSmall.png";

function Clinic() {
  useDocumentTitle("Clinic");

  //check for clinic Details
  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );
  // console.log(defaultHealthCenter)
  const clinicObj = useSelector(
    (state) => state.clinic.clincObj.clinic
  );

  const gallaryObj = useSelector(
    (state) => state.clinic.clincObj.clinic.galleryImages
  );

  //doctors
  const doctorsList = useSelector(
    (state) => state.homepage.ourDoctorsHome.list
  );

  //default health center obj
  const [healthCenterObj, setHealthCenterObj] = useState(null);

  //facility Obj
  const [facility, setFacility] = useState([]);
  //staff Obj
  const [staff, setStaff] = useState([]);

  useEffect(() => {
    GetHealthCenterDetails(defaultHealthCenter.id)
      .then((res) => {
        setHealthCenterObj(res.response.healthCenterDetails);
      })
      .catch((err) => {
        console.log(err);
      });

    Getfacility()
      .then((res) => {
        setFacility(res.response);
      })
      .catch((err) => {
        console.log(err);
      });

    Getstaff()
      .then((res) => {
        setStaff(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const getPlan = () => {
    let plan = "Basic";
    if (clinicObj?.subscriptionPackage) {
      plan = clinicObj?.subscriptionPackage
    } else {
      return plan = undefined;
    }
    return plan;
  }

  function formatPhoneNumber(param) {
    const countryCode = param.countryCode;
    const phoneNumber = param.phoneNumber;
    const emergencyCode = param.emergencyCountryCode;
    const emergencyNumber = param.emergencyPhoneNumber;

    const formattedNumber = `+${countryCode} ${phoneNumber?.slice(0, 3)}-${phoneNumber?.slice(3, 6)}-${phoneNumber?.slice(6)}`;
    const emergencyPhoneNumber = `+${emergencyCode} ${emergencyNumber?.slice(0, 3)}-${emergencyNumber?.slice(3, 6)}-${emergencyNumber?.slice(6)}`;

    return { formattedNumber, emergencyPhoneNumber };
  }

  return healthCenterObj ? (
    <div className="main-body abc">
      <section className="abt-clinic-main">
        <div className="container">
          <div className="hero">
            <Row>
              <Col xs={12} sm={7} className="left">
                <div>
                  <div className="header">
                    {defaultHealthCenter?.healthCenterName}
                  </div>
                  <div className="para">{defaultHealthCenter?.tagLine}</div>
                  <div className="reg">
                    Reg: {defaultHealthCenter?.registrationNumber}
                  </div>
                </div>
                <div>
                  <div className="ico">
                    <img src={loc} alt="asd" />
                    <span>{defaultHealthCenter?.address} </span>
                  </div>
                  <div className="ico">
                    <img src={phone} alt="asd" />
                    <a
                      href={`tel:${defaultHealthCenter?.countryCode}${defaultHealthCenter?.phoneNumber}`}
                    >
                      {formatPhoneNumber(defaultHealthCenter).formattedNumber}
                      {defaultHealthCenter?.emergencyPhoneNumber && <br />}

                      {defaultHealthCenter.emergencyPhoneNumber && (
                        <>
                          {formatPhoneNumber(defaultHealthCenter).emergencyPhoneNumber}{' '}
                          <span className="emergency">(emergency)</span>
                        </>
                      )}

                    </a>
                  </div>
                  <div className="ico">
                    <img src={mail} alt="ads" />
                    <a href={`mailto:${defaultHealthCenter?.email}`}>
                      {defaultHealthCenter?.email}
                    </a>
                  </div>
                </div>
                <div>
                  {/* {
                    getPlan() !== "Basic" &&
                    <Link to="/selectdoctor" className="btnr btnr-white btnr-hover">
                      Schedule Visit
                      <span className="arr"></span>
                    </Link>
                  } */}
                  {
                    (defaultHealthCenter?.latitude !== 0 && defaultHealthCenter?.longitude !== 0) && (
                      <a
                        target="_blank" rel="noreferrer"
                        href={`https://maps.google.com/?q=${defaultHealthCenter?.latitude},${defaultHealthCenter?.longitude}`}
                        className="btnr btnr-primary-outline-white btnr-hover"
                      >
                        Locate on Map
                        <span className="arr"></span>
                      </a>
                    )}
                </div>
              </Col>
              <Col xs={12} sm={5} className="right">
                <img src={defaultHealthCenter?.profilePicture} alt="sad" />
              </Col>
            </Row>
          </div>
        </div>
      </section>
      <section className="aboutus-home">
        <div className="container">
          <div className="about-grid2">
            <div className="abcontent">
              <div>
                <span className="section-pill">About Us</span>
              </div>
              <div>
                <span className="section-title section-title-primary">
                  {defaultHealthCenter?.healthCenterName}
                </span>
              </div>

              <span className="para">{clinicObj?.about}</span>
            </div>
            <div className="abslider">
              <div className="cardsa">
                {
                  healthCenterObj?.is24x7 ?
                    null
                    :
                    <div className="header">
                      <img src={clock} alt="asd" />
                      Open Hours
                    </div>
                }
                <div className={`body ${healthCenterObj?.is24x7 && "available24/7"}`}>
                  {healthCenterObj?.is24x7 ? (
                    <div className="imageFor24/7">
                      <img className="img" src={daysImg} alt="24/7" />
                      <img className="imgSm" src={daysImgSm} alt="24/7" />
                    </div>
                  ) : (
                    <Row>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.mon ? (
                          <>
                            Mon : {healthCenterObj.monOpening} -{" "}
                            {healthCenterObj.monClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Monday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.tue ? (
                          <>
                            Tue : {healthCenterObj.tueOpening} -{" "}
                            {healthCenterObj.tueClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Tuesday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.wed ? (
                          <>
                            Wed : {healthCenterObj.wedOpening} -{" "}
                            {healthCenterObj.wedClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            wednesday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.thu ? (
                          <>
                            Thu : {healthCenterObj.thuOpening} -{" "}
                            {healthCenterObj.thuClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Thrusday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.fri ? (
                          <>
                            Fri : {healthCenterObj.friOpening} -{" "}
                            {healthCenterObj.friClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Friday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day">
                        {" "}
                        {healthCenterObj.sat ? (
                          <>
                            Sat : {healthCenterObj.satOpening} -{" "}
                            {healthCenterObj.satClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Saturday Closed
                          </button>
                        )}{" "}
                      </Col>
                      <Col xs={6} className="day close_btn">
                        {" "}
                        {healthCenterObj.sun ? (
                          <>
                            Sun : {healthCenterObj.sunOpening} -{" "}
                            {healthCenterObj.sunClosing}{" "}
                          </>
                        ) : (
                          <button className="btnr btnr-danger btnr-sm">
                            Sunday Closed
                          </button>
                        )}{" "}
                      </Col>
                    </Row>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="mission">
        <div className="container">
          <Row>
            <Col xs={12} sm={6}>
              <Row>
                <Col sm={6} xs={6} className="mb-4 justify-content-center">
                  <div className="high_lights">
                    <img src={team} alt="sd" />
                    <div>
                      <div className="count">{clinicObj?.team ? clinicObj?.team : 0}</div>
                      <div className="txt">
                        Professional <br /> Medical team
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xs={6} className=" mb-4 justify-content-center">
                  <div className="high_lights">
                    <img src={equip} alt="sd" />
                    <div>
                      <div className="count">{clinicObj?.equipment ? clinicObj?.equipment : 0}</div>
                      <div className="txt">
                        Hi-tech Medical <br /> Tools
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xs={6} className="justify-content-center">
                  <div className="high_lights">
                    <img src={lab} alt="sd" />
                    <div>
                      <div className="count">{clinicObj?.labs ? clinicObj?.labs : 0}</div>
                      <div className="txt">
                        Laboratory for <br /> medical test
                      </div>
                    </div>
                  </div>
                </Col>
                <Col sm={6} xs={6} className="justify-content-center mb-4">
                  <div className="high_lights">
                    <img src={pser} alt="sd" />
                    <div>
                      <div className="count">
                        {clinicObj?.patientsServed ? clinicObj?.patientsServed : 0}
                      </div>
                      <div className="txt">
                        Patients served <br /> so far
                      </div>
                    </div>
                  </div>
                </Col>
              </Row>
            </Col>
            <Col xs={12} sm={6}>
              <Carousel
                interval={null}
                controls={true}
                touch={true}
                className="slider-parent carousel-zoom"
              >
                <Carousel.Item>
                  <div className="cardcs">
                    <div className="img">
                      <img src={mission} alt="" />
                    </div>
                    <div className="header">Our Mission</div>
                    <div className="para">{clinicObj?.mission}</div>
                  </div>
                </Carousel.Item>
                <Carousel.Item>
                  <div className="cardcs">
                    <div className="img">
                      <img src={vission} alt="" />
                    </div>
                    <div className="header">Our Vision</div>
                    <div className="para">{clinicObj?.vision}</div>
                  </div>
                </Carousel.Item>
              </Carousel>
            </Col>
          </Row>
        </div>
        <img src={pat2} className="ph1" alt="sd" />
        <img src={pat1} className="ph2" alt="sd" />
      </section>
      {staff.length > 0 && (
        <section className="gall-home indic">
          <div className="container">
            <Row>
              <Col xs={12}>
                <span className="section-pill">Team members</span>
              </Col>
              <Col xs={12}>
                <span className="section-title section-title-primary">
                  Meet Our Team
                </span>
              </Col>
              <Col xs={12}>
                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={staff.length > 1 ? false : false}
                  spaceBetween={20}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper "
                >
                  {staff.map((imge, index) => (
                    <SwiperSlide className="facal-card t_facal_card" key={index}>
                      <div className="img">
                        <img src={imge.pictureUrl} alt="gallery" />
                      </div>

                      <div className="header">{imge.name}</div>
                      <div className="title">{imge.title}</div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </div>
        </section>
      )}
      {facility.length > 0 && (
        <section className="gall-home dark indic">
          <div className="container facilities">
            <Row>
              <Col xs={12}>
                <span className="section-pill section-pill-invert">
                  Our Facilities
                </span>
              </Col>
              <Col xs={12}>
                <span className="section-title section-title-primary">
                  Facilities at our clinic
                </span>
              </Col>
              <Col xs={12}>
                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={facility.length > 1 ? false : false}
                  spaceBetween={20}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination, Navigation]}
                  // navigation={true}
                  className="mySwiper "
                >
                  {facility.map((imge, index) => (
                    <SwiperSlide className="facal-card" key={index}>
                      <div className="img">
                        <img src={imge.pictureUrl} alt="gallery" />
                      </div>

                      <div className="header">{imge.name}</div>
                      <div className="room">{imge.description}</div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </Col>
            </Row>
          </div>
        </section>
      )}

      {gallaryObj && (
        <>
          {gallaryObj.length > 0 && (
            <section className="gall-home">
              <div className="container">
                <Row>
                  <Col xs={12}>
                    <span className="section-pill">Gallery</span>
                  </Col>
                  <Col xs={12}>
                    <span className="section-title section-title-primary">
                      More about inside
                    </span>
                  </Col>

                  <Col xs={12}>
                    <Gallery mainIMages={clinicObj.galleryImages} />
                    {/* <Gallery mainIMages={testGallary} /> */}
                  </Col>


                </Row>
              </div>
            </section>
          )}
        </>
      )}
    </div>
  ) : (
    <div className="main-body skeleton abc">
      <section className="abt-clinic-main">
        <div className="container">
          <div className="hero">
            <Row>
              <Col xs={12} sm={7} className="left">
                {/* Left Column Skeleton */}
                <div>
                  <Skeleton variant="text" width="70%" height={50} />
                  <Skeleton variant="text" width="50%" height={35} />
                  <Skeleton variant="text" width="30%" height={35} />
                </div>
                <div>
                  <Skeleton variant="rectangular" width="30%" height={30} />
                  <Skeleton variant="rectangular" width="50%" height={30} />
                  <Skeleton variant="rectangular" width="70%" height={30} />
                </div>
                <div className="skeleton_button">
                  <Skeleton variant="text" height={70} width="50%" />
                  <Skeleton variant="text" height={70} width="50%" />
                </div>
              </Col>
              <Col xs={12} sm={5} className="right">
                {/* Right Column Skeleton */}
                <Skeleton variant="rectangular" width="100%" height={300} />
              </Col>
            </Row>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Clinic;
