import axios from "axios";
const clinicId = process.env.REACT_APP_CLINIC_ID;

export const GetUpcomingAppointments = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetUpcomingAppointments/`,
    {
      ...obj,
      clinicId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};

export const GetPreviousAppointments = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetPreviousAppointments/`,
    {
      ...obj,
      clinicId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response.data);

  return dataPromise;
};
export const GetAppointmentsById = async (id, token) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAppointmentById/${id}`,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data.response);

  return dataPromise;
};
export const CancelAppointment = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/CancelAppointment`,
    {
      clinicId,
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
export const RescheduleAppointment = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/RescheduleAppointment`,
    {
      clinicId,
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
export const PatientJoinedVideoCall = async (apptId, token) => {
  console.log(token);
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/videoCallAppointment/${apptId}/patientJoined`,
    null,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
export const PatientReJoinedVideoCall = async (apptId, token) => {
  console.log(token);
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/videoCallAppointment/${apptId}/patientRejoined`,
    null,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};
export const PatientEndedCall = async (apptId, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/videoCallAppointment/${apptId}/patientEnded`,
    null,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const PatientCheckinInPerson = async (apptId, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/inPersonAppointment/${apptId}/patientCheckIn`,
    null,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const PatientCheckinTreatment = async (apptId, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/treatmentAppointment/${apptId}/patientCheckIn`,
    null,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};



export const CreateOrUpdateAppointmentHealthRecord = async (apptObj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/CreateOrUpdateAppointmentHealthRecord/`,
    {
      ...apptObj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const CheckIfDoctorAvailable = async (obj) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/CheckIfDoctorAvailable/`,
    {
      ...obj,
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const BookAppointmentAPI = async (obj, token) => {
  console.log(token);
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/BookAppointment/`,
    {
      ...obj,
      clinicId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const GetClinicFollowUpAppointmentsApi = async (obj, token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetClinicFollowUpAppointments/`,
    {
      ...obj,
      clinicId,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};


export const PostPatientInformationAPI = async (obj,token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/appointment/${obj.appointmentId}/patientInformation`,
    {
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const GetAppointmentTotalAPI = async (obj,token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/GetAppointmentTotal`,
    {
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};


export const GenerateTokenAPI = async (obj,token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/Pay/GenerateToken`,
    {
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const FreezeSlotAPI = async (obj,token) => {
  const promise = axios.post(
    `/${process.env.REACT_APP_API_VERSION_V1}/ReserveSlot`,
    {
      ...obj,
    },
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};

export const GetAvailablePaymentMethodsAsync = async (token) => {
  const promise = axios.get(
    `/${process.env.REACT_APP_API_VERSION_V1}/available-payment-methods`,
    {
      headers: { Authorization: `Bearer  ${token}` },
    }
  );

  const dataPromise = promise.then((response) => response.data);

  return dataPromise;
};