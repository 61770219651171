import moment from 'moment';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';

const TimeDrop = (props) => {

  const { slotlist, timeDrop, handleSlotClick, selectedSlot, dayInfo } = props

  return (
    <div className={`drop_list ${timeDrop ? "active" : ""}`} style={{ width: "280px", left: "0" }}>

      <div className="appt-slots">
        <div className="day">{moment(dayInfo.date).format('dddd')}</div>
        <div className="date">{moment(dayInfo.date).format('D MMM YYYY')}</div>
        <div className="timeslotslistparent">
          <div className="h1">Select time slot</div>

          <div className="list row">

            {slotlist && slotlist.length > 0 ? (
              slotlist.map((slot, index) => {
                const startTime = moment(slot.startAtUtc);
                const endTime = moment(slot.endAtUtc);

                const formattedStartTime = startTime.format('h:mm ');
                const formattedEndTime = endTime.format('h:mm ');

                return (
                  <Col key={index} xs={6}>
                    <div
                      className={`slot ${selectedSlot === slot ? "checked" : ""}`}
                      onClick={() => handleSlotClick(index)}
                    >
                      {`${formattedStartTime}-${formattedEndTime}`}
                    </div>
                  </Col>
                );
              })
            ) : (
              <div>No time slots available</div>
            )}

          </div>
        </div>
      </div>
    </div>
  );
};

export default TimeDrop;