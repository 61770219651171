import React from 'react';

export const MyApptIcon = () => {
    return (
        <svg width="15" height="16" viewBox="0 0 15 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path fillRule="evenodd" clipRule="evenodd" d="M12 2.5H13.5C13.8978 2.5 14.2794 2.65804 14.5607 2.93934C14.842 3.22064 15 3.60218 15 4V14C15 14.3978 14.842 14.7794 14.5607 15.0607C14.2794 15.342 13.8978 15.5 13.5 15.5H1.5C1.10218 15.5 0.720644 15.342 0.43934 15.0607C0.158035 14.7794 0 14.3978 0 14L0 4C0 3.60218 0.158035 3.22064 0.43934 2.93934C0.720644 2.65804 1.10218 2.5 1.5 2.5H3V0.5H4V2.5H11V0.5H12V2.5ZM6 8.5H3V7.5H6V8.5ZM12 7.5H9V8.5H12V7.5ZM6 11.5H3V10.5H6V11.5ZM9 11.5H12V10.5H9V11.5Z" fill="#B7BFCC" />
        </svg>
    );
};

