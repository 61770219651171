import React from "react";
import { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import { Link, useParams } from "react-router-dom";
import { GetTreatmentDetails } from "../services/treatmentservice";
import { useState } from "react";

import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import gal from "../static/doctor/gal.svg";
import Treatments from "../components/homepagecomps/Treatments";
import { useSelector } from "react-redux";
import TreatmentTrack from "../components/homepagecomps/treatmentTrack/TreatmentTrack";
import { Skeleton } from "@mui/material";
import Gallery from "../components/microcomponenets/Gallery";

function ViewTreatment() {

  const { id } = useParams();
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  //const treatmentId = location.state?.treatmentId;

  const [treatmentObj, setTreatmentObj] = useState(null);
  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const treatmentsList = useSelector((state) => state.homepage.treatments.list);

  useEffect(() => {
    GetTreatmentDetails(id)
      .then((res) => {
        setTreatmentObj(res.response);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [id]);

  const getPlan = () => {
    let plan = "Basic";
    if (clinicObj?.subscriptionPackage) {
      plan = clinicObj?.subscriptionPackage
    } else {
      return plan = undefined;
    }
    return plan;
  }

  return (
    treatmentObj ? (
      <div className="main-body">
        <div className="container">
          <div className="treatment-main">
            <div className="upper">
              <Row className="row-rev">
                <Col sm={7} xs={12}>
                  <div className="header">{treatmentObj.name}</div>

                  <div className="doctor-parrent">
                    {treatmentObj.doctors &&
                      treatmentObj.doctors.length > 0 && (
                        <>
                          <div className="icon">
                            <img
                              className="star"
                              src={treatmentObj.doctors[0].pictureUrl}
                              alt="bmi"
                            />
                          </div>
                          <div className="txt">
                            <span className="t1">
                              {treatmentObj.doctors[0].name}
                            </span>
                            <span className="t2">
                              {treatmentObj.doctors[0].title}
                            </span>
                            <span className="t3">
                              {treatmentObj.doctors[0].shortEducation}
                            </span>
                          </div>
                        </>
                      )}
                  </div>
                  
                  {
                    ((clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) && treatmentObj.doctors.length > 0) && (
                      getPlan() !== "Basic" ? (

                        <Link
                          to="/selectdoctor"
                          className="btnr btnr-primary btnr-hover"
                          state={{
                            data: {
                              type: "Treatment",
                              processId: treatmentObj.id,
                              processObj: {
                                name: treatmentObj.name,
                                pictureUrl: treatmentObj.pictureUrl,
                                price: treatmentObj.price,
                              },
                            },
                          }}
                        >
                          Book Treatment at {currencySymbol}
                          {treatmentObj.price} <span className="arr"></span>
                        </Link>
                      ) : (
                        <Link
                          to="/contact-us"
                          className="btnr btnr-primary btnr-hover"
                        >
                          Contact us for Booking
                          <span className="arr"></span>
                        </Link>
                      )
                    )}
                </Col>
                <Col sm={5} xs={12}>
                  <div className="img">
                    <img src={treatmentObj.pictureUrl} alt="ds" />
                  </div>
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <div className="about-h">About {treatmentObj.name}</div>
                </Col>
                <Col xs={12}>
                  <div className="about-p" dangerouslySetInnerHTML={{ __html: treatmentObj.description }}>

                  </div>
                </Col>
              </Row>
            </div>

            {treatmentObj.mediaPictures && (
              <div className="gall dp-card">
                <div className="gen-title">
                  <div className="doc-nav-img">
                    <img src={gal} alt="ico" />
                  </div>
                  <span>Gallery</span>
                </div>
                <div className="list ">


                  <Gallery mainIMages={treatmentObj.mediaPictures} />
                </div>
              </div>
            )}

            <div className="treat dp-card">
              <div className="gen-title">
                <span>Explore other Treatments</span>
              </div>
              <div className="tlist ">
                <TreatmentTrack treatmentsList={treatmentsList} />
              </div>
            </div>
          </div>
        </div>
      </div>
    ) : (
      <div className="main-body">
        <div className="container">
          <div className="treatment-main">
            <div className="upper">
              <Row className="row-rev">
                <Col sm={7} xs={12}>
                  <div className="header">
                    <Skeleton variant="text" width="60%" height={50} />
                  </div>

                  <div className="doctor-parrent">
                    {Array.from({ length: 1 }).map((_, index) => (
                      <React.Fragment key={index}>
                        <div className="icon">
                          <Skeleton variant="circular" width={40} height={40} />
                        </div>
                        <div className="txt">
                          <span className="t1">
                            <Skeleton variant="text" width={160} />
                          </span>
                          <span className="t2">
                            <Skeleton variant="text" width={200} />
                          </span>
                          <span className="t3">
                            <Skeleton variant="text" width={80} />
                          </span>
                        </div>
                      </React.Fragment>
                    ))}
                  </div>
                </Col>
                <Col sm={5} xs={12}>
                  <Skeleton variant="rect" height={300} />
                </Col>
              </Row>
              <Row>
                <Col xs={12}>
                  <Skeleton variant="text" width="100%" height={80} />
                </Col>
                <Col xs={12}>
                  <Skeleton variant="text" width="100%" height={200} />
                </Col>
              </Row>
            </div>

            <div className="gall dp-card">
              <div className="gen-title">
                <Skeleton variant="text" width="30%" />
              </div>
              <div className="list">
                <Skeleton variant="rect" height={150} />
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  );
}

export default ViewTreatment;
