import React, { useEffect, useState } from 'react';
import hImg1 from '../../static/profile/health/h1.svg';
import hImg2 from '../../static/profile/health/h2.svg';
import hImg3 from '../../static/profile/health/h3.svg';
import hImg4 from '../../static/profile/health/h4.svg';
import mark from '../../static/profile/health/mark.svg';
import HealthUpdate from './HealthUpdate';
import { userHealthRecord } from '../../services/healthrecordService';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

const HealthProfile = () => {
    const userObj = useSelector((state) => state.user.userObj);

    const [userHealth, setHealth] = useState({
        health: {},
        isLoading: false
    });

    useEffect(() => {
        const fetchData = async () => {
            try {
                setHealth((prevState) => ({ ...prevState, isLoading: true }));
                const res = await userHealthRecord({ patientId: userObj.patientData.id }, userObj.token);
                setHealth((prevState) => ({ ...prevState, health: res.patientData }));
            } catch (err) {
                console.error(err);
                setHealth({
                    health: {}
                });
            } finally {
                setHealth((prevState) => ({ ...prevState, isLoading: false }));
            }
        };

        fetchData();
    }, [userObj]);

    const skeleton = <>
        <div className='health_container'>
            <div className="header">
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={150} />
            </div>
            <div className="healthOjbs">
                {[1, 2, 3, 4].map((index) => (
                    <div className='health_info'>
                        <Skeleton variant="text" width={150} />
                        <span className='d-flex' style={{ gap: "8px" }}>
                            <Skeleton className='' variant="text" width={30} />
                            <Skeleton variant="text" width={100} />
                        </span>
                        <Skeleton variant="rect" width={50} />
                    </div>
                ))}
            </div>
            <div className="lastUpdate">
                <img src={mark} alt="" />
                <span>
                    <Skeleton variant="text" width={200} />
                </span>
            </div>
        </div>
    </>
    return (!userHealth.isLoading ?
        <>
            <div className='health_container'>
                <div className="header">
                    <span>Health Profile</span>
                    <HealthUpdate userHealth={userHealth.health} setHealth={setHealth} />
                </div>
                <div className="healthOjbs">
                    <div className='health_info'>
                        <span className='title'>Blood pressure</span>
                        <span className='info'>{userHealth.health.bloodPressureDia ? userHealth.health.bloodPressureDia : "00/00"} <span>mm/hg</span></span>
                        <img src={hImg1} alt="" />
                    </div>
                    <div className='health_info'>
                        <span className='title'>Pulse Rate</span>
                        <span className='info'>{userHealth.health.pulse ? userHealth.health.pulse : "00"}<span>bpm</span></span>
                        <img src={hImg2} alt="" />
                    </div>
                    <div className='health_info'>
                        <span className='title'>Body temparature</span>
                        <span className='info'>{userHealth.health.temparature ? userHealth.health.temparature : "0°C"}</span>
                        <img src={hImg3} alt="" />
                    </div>
                    <div className='health_info'>
                        <span className='title'>Weight</span>
                        <span className='info'>{userHealth?.health.weight ? userHealth?.health.weight : "0"} kg</span>
                        <img src={hImg4} alt="" />
                    </div>
                </div>
                <div className="lastUpdate">
                    <img src={mark} alt="" />
                    <span>Last updated: 10:00 AM, 22 July, 23.</span>
                </div>
            </div>
        </>
        :
        skeleton
    );
};

export default HealthProfile;