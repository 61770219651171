import React from "react";
import { Col } from "react-bootstrap";
import { useNavigate } from "react-router";

import file from "../../static/upload/file.svg";
import EditIcon from "../Icons/EditIcon";

function PatientDocs(props) {
  const { patientInformationDto, appointmentId } = props;
  const navigate = useNavigate();
  const navigateToUploadReports = (stateforNextPage) => {

    if (stateforNextPage) {
      let tempFilesArray = stateforNextPage.attachments.map((attach) => {
        let tempAttachment = {
          ...attach,
          isUploading: false,
          isUploaded: true,
        };

        return tempAttachment;
      });

      let modifiedState = {
        ...stateforNextPage,
        attachments: [...tempFilesArray],
      };
      console.log(modifiedState)
      navigate("/uploadreports", { state: modifiedState });
    } else {

      let newState = { id: 0, appointmentId: appointmentId, reasonForVisit: "", attachments: [] };
      navigate("/uploadreports", {
        state: newState,
      });
    }

  };

  return (
    <div className="patinetDocs">

      <div className="title pb-3">
        Uploaded medical reports
        <button
          className="btnr btnr-primary-outline btnr-sm"
          onClick={() => {
            if (patientInformationDto) {
              navigateToUploadReports(patientInformationDto)
            } else {
              navigateToUploadReports(null)
            }
          }}
        >
          <EditIcon />
          {
            !patientInformationDto ? "Upload" : "Edit"
          }
        </button>
      </div>

      {patientInformationDto && (
        <div className="count">
          {patientInformationDto.attachments.length > 0 ? (
            // <div>
            <div className="left_side">
              <div xs={12} className="file-status justify-content-center">
                <div className="file_text">
                  <img src={file} alt="file" />
                  <span className="counter">
                    {patientInformationDto.attachments.length}
                  </span>
                  <span className="sub">
                    File{patientInformationDto.attachments.length > 1 ? "s" : ""}
                  </span>
                </div>
              </div>
              <div xs={12} className="d-flex justify-content-center">
                <button
                  className="btnr btnr-primary-outline btnr-hover btnr-sm border-0"
                  onClick={() =>
                    navigateToUploadReports({
                      ...patientInformationDto,
                    })
                  }
                >
                  View All <span className="arr"></span>
                </button>
              </div>
            </div>

            // </div>
          ) : (
            <Col xs={12} className="text-center">
              <button
                className="btnr btnr-primary-outline  btnr-sm "
                onClick={() =>
                  navigateToUploadReports({
                    ...patientInformationDto,
                  })
                }
              >
                Upload Documents <span className="arr"></span>
              </button>
            </Col>
          )}

          {patientInformationDto.reasonForVisit.length > 0 && (

            <div className="rov-parent">
              <div className="rov">Reason of visit</div>
              <div className="para">
                {patientInformationDto.reasonForVisit}
              </div>
            </div>

          )}
        </div>
      )}
    </div>
  );
}

export default PatientDocs;
