import React from 'react';
import { Col, Offcanvas } from 'react-bootstrap';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom'
import logo from "../../static/header/logo.svg";
import doc from "../../static/MegaMenu/doc.png";
import treat from "../../static/MegaMenu/treat.png";
import spac from "../../static/MegaMenu/spec.png";
import ArrowIcon from '../Icons/ArrowIcon';

const SubCanvas = ({ title, subCanvas, setSubCanvas, list, setShowSidebar }) => {

    const navigate = useNavigate();

    const { pathname } = useLocation();
    const parts = pathname.split('/');
    const extractedId = parts[2];

    const isActive = (item) => {
        if (extractedId === item.slug) {
            return "active";
        } else {
            return "";
        }
    };

    const [content, setContent] = React.useState([]);

    React.useEffect(() => {
        const updatedContent = list.map((item, index) => {
            return {
                name: item.name,
                decs: item.shortDescription,
                link: getLink(item.slug),
                slug: item.slug,
                // cost: item.price > 0 ? `(${currencySymbol} ${item.price})` : ''
            };
        });

        setContent(updatedContent);
    }, [list]);

    const getLink = (slug) => {
        if (title === 'Doctors') {
            return `/doctorprofile/${slug}`;
        } else if (title === 'Treatments') {
            return `/treatment/${slug}`;
        } else if (title === 'Specialites') {
            return `/speciality/${slug}`;
        }
    }

    const getImage = () => {
        if (title === 'Doctors') {
            return doc;
        } else if (title === 'Treatments') {
            return treat;
        } else if (title === 'Specialites') {
            return spac;
        }
    }

    const closeCanvas = () => {
        setSubCanvas(false);
        setShowSidebar(false);
    }

    const showAll = () => {
        let path = '';
        if (title === 'Doctors') {
            path = '/selectdoctor';
        } else if (title === 'Treatments') {
            path = '/treatments';
        } else if (title === 'Specialites') {
            path = '/selectspeciality';
        }
        closeCanvas();
        return navigate(path);
    }

    return (
        <Offcanvas show={subCanvas}
            onHide={() => setSubCanvas(false)}
            placement="start" className="subCanvas custom-offcanvas d-sm-none">
            <Offcanvas.Header closeButton>
                <Offcanvas.Title className="c_logo">
                    <Link className="logo" to="/">
                        <img alt="logo" src={logo} />
                    </Link>
                </Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div className="subCanvas_header">
                    <div className="subCanvas_header_title">
                        <span className='icon-arrow-left-solid'></span><h2>{title}</h2>
                    </div>
                    <div className="subCanvas_header_img">
                        <img src={getImage()} alt="doc" />
                        <div className='canvas_text'>
                            <h1>{title}</h1>
                            <button className='btnr btnr-primary-outline' onClick={() => showAll()}>
                                See All <span className='arr'></span>
                            </button>
                        </div>
                    </div>
                </div>
                <div className="subCanvas__body">
                    <Col xs={12} className="bodyTitle">
                        {title}
                    </Col>
                    <div className="subCanvas_body_list">
                        <div className="subMega">
                            {content.map((item, index) => (
                                <Link
                                    to={item.link}
                                    className="col-12 mega-item"
                                    key={index}
                                    onClick={(e) => {
                                        e.stopPropagation();
                                        closeCanvas();
                                    }}
                                >
                                    <div className="blob">
                                        <ArrowIcon/>
                                    </div>
                                    <div className="txtbox">
                                        <div className={`name ${isActive(item)}`}>
                                            {/* <div className={`name `}> */}
                                            {item.name} {item?.cost}
                                        </div>
                                        <div className="desc">{item.decs}</div>
                                    </div>
                                </Link>
                            ))}
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </Offcanvas>
    );
};

export default SubCanvas;