import React from 'react';

const ArrowFillIcon = () => {
    return (
        <svg width="25" height="24" viewBox="0 0 25 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <mask id="mask0_4265_6854" style={{maskType:"luminance"}} maskUnits="userSpaceOnUse" x="1" y="1" width="23" height="22">
                <path d="M12.5 22C18.023 22 22.5 17.523 22.5 12C22.5 6.477 18.023 2 12.5 2C6.977 2 2.5 6.477 2.5 12C2.5 17.523 6.977 22 12.5 22Z" fill="white" stroke="white" strokeWidth="1.66667" strokeLinejoin="round" />
                <path d="M17.0002 10.5L12.5002 15L8.00024 10.5" stroke="black" strokeWidth="1.66667" strokeLinecap="round" strokeLinejoin="round" />
            </mask>
            <g mask="url(#mask0_4265_6854)">
                <path d="M0.500244 0H24.5002V24H0.500244V0Z" fill="#B7BFCC" />
            </g>
        </svg>
    );
};

export default ArrowFillIcon;