import React, { useRef, useState, useEffect } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import "swiper/css";
import "swiper/scss/navigation";

import Lightbox from "react-image-lightbox";
import "react-image-lightbox/style.css";
import { Row } from "react-bootstrap";




function Gallery(props) {
  const { mainIMages } = props;

  const [currentIndex, setCurrentIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);

  const openImageGallery = (indx) => {
    setIsLightboxOpen(true);
    setCurrentIndex(indx);
    document.body.style.overflow = "hidden";
  };

  const closeImageGallery = () => {
    setIsLightboxOpen(false);
    document.body.style.overflow = "scroll";
  };

  const onScreenChange = (isOpen) => {
    if (!isOpen) {
      // Lightbox is closed
      closeImageGallery();
    }
  };

  return (
    <>
      <div className="d-none d-md-block">
        <div className="imageGallary">
          {mainIMages.map((imge, index) => (
            <div className="gal-card gy-4" key={index}>
              <div className="img" style={{
                backgroundImage: `url(${imge.pictureUrl})`,
              }}
                onClick={() => openImageGallery(index)}
              />
            </div>
          ))}
        </div>
      </div>
      <div className="d-md-none">
        <Swiper
          slidesPerView={"auto"}
          centeredSlides={mainIMages.length > 1 ? false : false}
          spaceBetween={0}
          pagination={{
            clickable: true,
          }}
          modules={[Pagination]}
          className="mySwiper"
        >
          {mainIMages.map((imge, index) => (
            <SwiperSlide className="gal-card" key={index}>
              <div className="gal-card">

                <div className="img" style={{
                  backgroundImage: `url(${imge.pictureUrl})`,
                }}
                  onClick={() => openImageGallery(index)}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={mainIMages[currentIndex].pictureUrl}
          nextSrc={mainIMages[(currentIndex + 1) % mainIMages.length].pictureUrl}
          prevSrc={
            mainIMages[(currentIndex + mainIMages.length - 1) % mainIMages.length].pictureUrl
          }
          onCloseRequest={closeImageGallery}
          onMovePrevRequest={() =>
            setCurrentIndex((currentIndex + mainIMages.length - 1) % mainIMages.length)
          }
          onMoveNextRequest={() =>
            setCurrentIndex((currentIndex + 1) % mainIMages.length)
          }
        />
      )}
    </>
  );
}


export default Gallery;
