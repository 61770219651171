import React from 'react';

const SignoutIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.33203 12.618C6.1428 13.0862 7.06251 13.3326 7.99873 13.3327C8.93495 13.3327 9.85468 13.0863 10.6655 12.6182C11.4763 12.1501 12.1496 11.4769 12.6177 10.6661C13.0858 9.85529 13.3323 8.93557 13.3323 7.99935C13.3323 7.06313 13.0858 6.14341 12.6177 5.33262C12.1496 4.52184 11.4763 3.84857 10.6655 3.38048C9.85468 2.91239 8.93495 2.66598 7.99873 2.66602C7.06251 2.66605 6.1428 2.91253 5.33203 3.38068" stroke="#ff4c5e" strokeWidth="1.33333" />
            <path d="M1.3299 7.99902L0.809896 7.58236L0.476562 7.99902L0.809896 8.41569L1.3299 7.99902ZM7.3299 8.66569C7.50671 8.66569 7.67628 8.59545 7.8013 8.47043C7.92632 8.3454 7.99656 8.17583 7.99656 7.99902C7.99656 7.82221 7.92632 7.65264 7.8013 7.52762C7.67628 7.40259 7.50671 7.33236 7.3299 7.33236V8.66569ZM3.47656 4.24902L0.809896 7.58236L1.8499 8.41569L4.51656 5.08236L3.47656 4.24902ZM0.809896 8.41569L3.47656 11.749L4.51656 10.9157L1.8499 7.58236L0.809896 8.41569ZM1.3299 8.66569H7.3299V7.33236H1.3299V8.66569Z" fill="#ff4c5e" />
        </svg>
    );
};

export default SignoutIcon;