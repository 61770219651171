import appt_booked from "../../static/notif/appt_confirmed.svg";
import appt_reschedule from "../../static/notif/Reschedule.svg";
import appt_followup from "../../static/notif/follow_up_req.svg";
import appt_recommendation from "../../static/notif/recomendation.svg";
import users from "../../static/notif/users.svg";
import promotion_selected from "../../static/notif/doctor_share_post.svg";
import video_fill from "../../static/notif/video_fill.svg";
import patient_end_call from "../../static/notif/y_call-ended.svg";
import joined_call from "../../static/notif/joined_v_call.svg";
import appt_cancle from "../../static/notif/appt_cancel.svg";
import appt_reminder from "../../static/notif/remainder.svg";
import PatientCheckIn from "../../static/notif/patient_check-in.svg";
import treatment_confirm from "../../static/notif/treatment_confirmed.svg";
import d_check from "../../static/notif/D_check.svg";

export const getNotifIcon = (item) => {
  if (item?.subType === "Doctor_Wall_Post") {
    return promotion_selected;
  } else if (item?.subType === "VideoCallAppointmentBooked") {
    return video_fill;
  } else if (item?.subType === "InPersonAppointmentBooked") {
    return users;
  } else if (item?.subType === "TreatmentAppointmentBooked") {
    return users;
  } else if (item?.subType === "CancelAppointment") {
    return appt_cancle;
  } else if (item?.subType === "PatientJoined") {
    return joined_call;
  } else if (item?.subType === "PatientCallEnded") {
    return patient_end_call;
  } else if (item?.subType === "DoctorJoined") {
    return joined_call;
  } else if (item?.subType === "DoctorCallEnded") {
    return patient_end_call;
  } else if (item?.subType === "AppointmentReminder" || item?.subType === "AppointmentVideoCallReminder") {
    return appt_reminder;
  } else if (item?.subType === "AppointmentCompleted") {
    return treatment_confirm;
  } else if (item?.subType === "AppointmentReschedule") {
    return appt_reschedule;
  } else if (item?.subType === "AppointmentFollowUp") {
    return appt_followup;
  } else if (item?.subType === "AppointmentRecommendation") {
    return appt_recommendation;
  } else if (item?.subType === "PatientCheckIn") {
    return PatientCheckIn;
  } else if (item?.subType === "DoctorCheckout") {
    return d_check;
  }
};

export const getColor = (item) => {
  let color = "primaryColor";
  if (item?.subType === "Doctor_Wall_Post") {
    color = "primaryColor";
  } else if (item?.subType === "VideoCallAppointmentBooked") {
    color = "seaGreen";
  } else if (item?.subType === "InPersonAppointmentBooked") {
    color = "seaGreen";
  } else if (item?.subType === "TreatmentAppointmentBooked") {
    color = "seaGreen";
  } else if (item?.subType === "AppointmentReschedule") {
    color = "blue";
  } else if (item?.subType === "AppointmentFollowUp") {
    color = "yellow";
  } else if (item?.subType === "AppointmentRecommendation") {
    color = "purple";
  } else if (item?.subType === "PatientCheckIn") {
    color = "purple";
  } else if (item?.subType === "CancelAppointment") {
    color = "warningColor";
  } else if (item?.subType === "AppointmentCompleted") {
    color = "greenLight";
  } else if (item?.subType === "PatientJoined") {
    color = "seaGreen";
  } else if (item?.subType === "PatientCallEnded") {
    color = "warningColor";
  } else if (item?.subType === "DoctorJoined") {
    color = "greenLight";
  } else if (item?.subType === "DoctorCallEnded") {
    color = "warningColor";
  } else if (item?.subType === "AppointmentReminder" || item?.subType === "AppointmentVideoCallReminder") {
    color = "warningColor";
  } else if (item?.subType === "DoctorCheckout") {
    color = "warningColor";
  }
  return color;
};

export const getnotifStatus = (item) => {
  let status = "";

  if (item?.subType === "Doctor_Wall_Post") {
    status = "New Post";
  } else if (item?.subType === "VideoCallAppointmentBooked") {
    status = "Confirmed";
  } else if (item?.subType === "InPersonAppointmentBooked") {
    status = "Confirmed";
  } else if (item?.subType === "TreatmentAppointmentBooked") {
    status = "Confirmed";
  } else if (item?.subType === "AppointmentReschedule") {
    status = "Rescheduled";
  } else if (item?.subType === "AppointmentFollowUp") {
    status = "Follow-up Request";
  } else if (item?.subType === "AppointmentRecommendation") {
    status = "Recommendation";
  } else if (item?.subType === "PatientCheckIn") {
    status = "Check in";
  } else if (item?.subType === "CancelAppointment") {
    status = "Cancelled";
  } else if (item?.subType === "AppointmentCompleted") {
    status = "Completed";
  } else if (item?.subType === "PatientJoined") {
    status = "joined Call";
  } else if (item?.subType === "PatientCallEnded") {
    status = "Call Ended";
  } else if (item?.subType === "DoctorJoined") {
    status = "joined Call";
  } else if (item?.subType === "DoctorCallEnded") {
    status = "Call ended";
  } else if (item?.subType === "AppointmentReminder" || item?.subType === "AppointmentVideoCallReminder") {
    status = "will begin in some time";
  } else if (item?.subType === "DoctorCheckout") {
    status = "Checkout";
  }
  return status;
};