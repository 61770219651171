import moment from 'moment';
import React from 'react';

const DateDrop = ({ dateDrop, days, handleDateSelection, dayInfo }) => {

    return (
        <div className={`drop_list fordoctor ${dateDrop ? "active" : ""}`} style={{ width: "280px" }}>
            <div className='title'>
                Select Date
            </div>
            <div className='day_drop'>

                {days?.map((day, index) => {
                    const isSelected = day?.date === dayInfo?.date;
                    return (
                        <div
                            key={index}
                            className={`single_day ${isSelected ? 'active' : ''}`}
                            onClick={() => handleDateSelection(day?.date)}
                        >
                            <div className="date">{moment(day?.date).format('DD MMM YYYY')}</div>
                            <div className="dayname">{day?.day}</div>
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default DateDrop;