import React, { useEffect, useState } from 'react';
import DocDrop from './Common/DocDrop';
import TimeDrop from './Common/TimeDrop';
import { Skeleton } from '@mui/material';
import moment from 'moment';
import DateDrop from './Common/DateDrop';
import location from "../../static/Widget/location.svg";
import date from "../../static/Widget/date.svg";
import time from "../../static/Widget/time.svg";
import persone from "../../static/Widget/persone.svg";
import { useDispatch, useSelector } from 'react-redux';
import { filterDoctors, getDates, fixedFilter, callCheckIfDoctorAvailable, DocAvailObj, navigateToBookAppt } from './Common/commonUtils';
import ApptDrop from './Common/ApptDrop';
import { useNavigate } from 'react-router-dom';
import { updateLoginDrawerState } from '../../redux/webAppSlice';

const InPersoneWidget = ({ healthCenterObj, patientData, setPatientData }) => {

    const [isActive, setIsActive] = useState({
        select: "Video",
        DocDrop: false,
        TimeDrop: false,
        ApptDrop: false,
        DateDrop: false,
        TreatmentDrop: false,
    });

    const { selected } = patientData
    const relation = selected?.relation ? selected?.relation : "Myself";

    const [doctors, setDoctors] = useState({ list: [], selectedDoctor: {}, loading: false });
    const [slots, setSlots] = useState({ slotlist: [], isLoading: false });
    const { slotlist } = slots;
    const [selectedSlot, setSelectedSlot] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null)
    const [pageState, setPageState] = useState({});
    const [dayInfo, setDayInfo] = useState({ day: "", date: "", });
    const [days, setDays] = useState([]);
    const [initialFilter, setInitialFilter] = useState({
        ...fixedFilter,
        appointmentType: "InPerson",
    });

    const clinic = useSelector(state => state.clinic);
    const { clincObj } = clinic;

    useEffect(() => {
        getDates(setDays, healthCenterObj, setDayInfo);
        filterDoctors(setDoctors, doctors, initialFilter);
    }, []);

    useEffect(() => {

        if (!doctors?.selectedDoctor?.id) return;
        if (dayInfo.date === "") return;

        let tempDocAvailObj = {
            ...DocAvailObj,
            doctorId: doctors?.selectedDoctor?.id,
            type: initialFilter.appointmentType,
            ScheduleDateAtLocalTime: dayInfo.date,
        };

        callCheckIfDoctorAvailable(tempDocAvailObj, setSlots, slots, setSelectedSlot, setPaymentDetails)
    }, [doctors?.selectedDoctor?.id, dayInfo.date]);


    const handleDateSelection = (selectedDate) => {
        if (selectedDate === dayInfo.date) return;
        setDayInfo({
            day: moment(selectedDate).format("dddd"),
            date: selectedDate,
        });
    };


    const handleSlotClick = (index) => {
        const clickedSlot = slotlist[index];
        setSelectedSlot(clickedSlot);
    };

    const navigate = useNavigate();

    const userObj = useSelector(state => state.user?.userObj);
    const dispatch = useDispatch();

    const handleBookAppt = () => {
        if (userObj) {
            
            const stateforNextPage = {
                doctor: doctors.selectedDoctor,
                apptType: initialFilter.appointmentType,
                timeslot: selectedSlot,
                dayInfo: dayInfo,
                paymentDetails: paymentDetails,
                processToPayment: true,
            }
            navigateToBookAppt(stateforNextPage, setIsActive, selectedSlot, navigate)
        } else {
            dispatch(updateLoginDrawerState(true));
        }
    }

    return (
        <>
            <div className='widget'>
                <div className='widget_box '>

                    <div className='widgetItem doc_info' onClick={() => setIsActive((prev) => ({ ...prev, DocDrop: !prev.DocDrop }))}>
                        {doctors?.loading ?
                            <>
                                <div className='img'>
                                    <Skeleton variant="circular" width={48} height={48} />
                                </div>

                                <div className='widDetails'>
                                    <Skeleton width="70%" height={20} />
                                    <Skeleton width="100%" height={20} />
                                    <Skeleton width="50%" height={15} />
                                </div>
                            </>
                            :
                            <>
                                <div className='img'>
                                    <div className='img'>
                                        <img src={doctors?.selectedDoctor?.pictureUrl ? doctors?.selectedDoctor?.pictureUrl : persone} alt="" />
                                    </div>
                                </div>
                                <div className='widDetails'>
                                    <span className='sTitle'>DOCTOR</span>
                                    <div className='docName'>
                                        <span className='title'>
                                            {doctors?.selectedDoctor?.name ? doctors?.selectedDoctor?.name : "Not available"}
                                        </span>
                                        <span className='icon-arrow-line'></span>
                                    </div>
                                    <span>{doctors?.selectedDoctor?.title}</span>
                                </div>
                            </>
                        }
                        <DocDrop doctors={doctors} dropActive={isActive.DocDrop} setDoctors={setDoctors} />
                    </div>

                    <div className='widgetItem loct'>
                        <img src={location} alt="" />
                        <div className='widDetails'>
                            <span className='sTitle'>LOCATION</span>
                            <span className='title'>{clincObj?.defaultHealthCenter?.city}</span>
                            <span>{clincObj?.defaultHealthCenter?.address}</span>
                        </div>
                        {/* <span className='icon-arrow-line'></span> */}
                    </div>
                    <div className='widgetItem date' onClick={() => setIsActive((prev) => ({ ...prev, DateDrop: !prev.DateDrop }))}>
                        <img src={date} alt="" />
                        <div className='widDetails'>
                            <span className='sTitle'>DATE</span>
                            <span className='title'>
                                {moment(dayInfo.date).format('DD MMM YYYY')}
                            </span>
                            <span>{dayInfo.day}</span>
                        </div>
                        <DateDrop
                            dateDrop={isActive.DateDrop}
                            days={days}
                            handleDateSelection={handleDateSelection}
                            dayInfo={dayInfo}
                        />
                    </div>
                    <div className='widgetItem time' onClick={() => setIsActive((prev) => ({ ...prev, TimeDrop: !prev.TimeDrop }))}>
                        <img src={time} alt="" />
                        <div className='widDetails'>
                            <span className='sTitle'>TIME</span>
                            {
                                !slots.isLoading ? (
                                    selectedSlot ? (
                                        <>
                                            <span className='title'>
                                                {moment(selectedSlot?.startAtUtc).format('h:mm')} - {moment(selectedSlot?.endAtUtc).format('h:mm')}
                                            </span>
                                            <span>
                                                {moment(selectedSlot?.startAtUtc).format('A')} - {moment(selectedSlot?.endAtUtc).format('A')}
                                            </span>
                                        </>
                                    ) : (
                                        <span
                                            className='sTitle'
                                            style={{ textWrap: "wrap" }}
                                        >
                                            {slots?.error ?
                                                slots?.error
                                                :
                                                "Doctor is not available"
                                            }
                                        </span>
                                    )
                                ) : (
                                    <>
                                        <Skeleton width="100%" height={25} />
                                        <Skeleton width="100%" height={15} />
                                    </>
                                )
                            }

                        </div>
                        <TimeDrop
                            slotlist={slotlist}
                            timeDrop={isActive.TimeDrop}
                            handleSlotClick={handleSlotClick}
                            selectedSlot={selectedSlot}
                            dayInfo={dayInfo}
                        />
                    </div>
                    <div className='widgetItem appt' onClick={() => setIsActive((prev) => ({ ...prev, ApptDrop: !prev.ApptDrop }))}>
                        <div className="img">
                            <img src={patientData?.selected?.pictureUrl ? patientData?.selected?.pictureUrl : persone} alt="" />
                        </div>
                        <div className='widDetails'>
                            <span className='sTitle'>APPOINMENTS FOR</span>
                            <span className='title'>{selected?.name}</span>
                            <span>{relation}</span>
                        </div>
                        <ApptDrop
                            patientData={patientData}
                            setPatientData={setPatientData}
                            dropActive={isActive.ApptDrop}
                        />
                    </div>
                </div>

            </div>
            <div className="widgetBtn">
                <button
                    onClick={handleBookAppt}
                    className='btnr btnr-primary'
                >
                    Book Appoinment
                    <span className='arr'></span>
                </button>
            </div>
        </>
    );
};

export default InPersoneWidget;