import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { getTopics } from '../services/getTopics';
import { Link, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Skeleton } from '@mui/material';

const Policy = () => {

    const [content, setContent] = useState("")
    const [isLoading, setIsLoading] = useState("")

    const { systemName } = useParams()

    const clinic = useSelector((state) => state.clinic.clincObj.clinic)

    const { typeString, link } = content

    useEffect(() => {
        const fetchData = async () => {
            try {
                setIsLoading(true);
                const res = await getTopics(clinic.id, systemName);
                setContent(res);
            } catch (error) {
                console.error('Error fetching data:', error);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, [systemName, clinic]);

    const SkeletonLoader = <>
        <div className="tcp">
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <Skeleton variant="text" height={50} />
                </Col>
            </Row>
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="50%" />
                </Col>
            </Row>
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <Skeleton variant="text" height={50} />
                </Col>
            </Row>
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <ol>
                        <li>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <br />
                        </li>
                        <li>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="50%" />
                        </li>
                    </ol>

                    <br />
                </Col>
            </Row>
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <Skeleton variant="text" height={50} />
                </Col>
            </Row>
            <Row className="pb-2">
                <Col md={12} className="d-flex flex-column">
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" />
                    <Skeleton variant="text" width="50%" />
                    <br />
                </Col>
                <Col md={12} className="d-flex flex-column">
                    <ol>
                        <li>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="50%" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <br />
                        </li>
                        <li>
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" />
                            <Skeleton variant="text" width="50%" />
                        </li>
                    </ol>
                    <br />
                </Col>
            </Row>
        </div>
    </>

    return (
        <div className="main-body refundPolicy">
            <div className="container">
                {!isLoading ?
                    <div className="tcp">
                        {typeString === "Link" && (
                            <>
                                {link ? (
                                    (() => {
                                        window.location.href = link;
                                        return null; 
                                    })()
                                ) : (
                                    <span>Not Added</span>
                                )}
                            </>
                        )}


                        {(typeString === "Description") && (
                            <>
                                {content.content ? (
                                    <>
                                        <Row className="pb-2">
                                            <Col md={12} className="d-flex flex-column">
                                                <span className="header-1">{content.displayName ? content.displayName : "Not Added"}</span>
                                            </Col>
                                        </Row>

                                        <Row className="pb-2">
                                            <div dangerouslySetInnerHTML={{ __html: content.content }} />
                                        </Row>
                                    </>
                                ) : (
                                    <div className="header-1">
                                        No {content.displayName} Available
                                    </div>
                                )}
                            </>
                        )}
                    </div>
                    :
                    SkeletonLoader
                }
            </div>
        </div>
    );

};

export default Policy;