import React, { useEffect, useState } from 'react';
import { MyApptIcon } from './Icons/MyApptIcon';
import CameraIcon from './Icons/CameraIcon';
import { InPersoneIcon } from './Icons/InPersoneIcon';
import { TreatmentsIcon } from './Icons/TreatmentsIcon';
import MyApptWidget from './MyApptWidget';
import { Paper, Skeleton } from '@mui/material';
import { useSelector } from 'react-redux';
import TreatmentWidget from './TreatmentWidget';
import VideoWidget from './VideoWidget';
import InPersoneWidget from './InPersoneWidget';
import { fatchHealthCenterDetails } from './Common/commonUtils';
import { GetPatientFamilyMembers } from '../../services/patientService';


const Widgets = () => {

  const state = {
    Video: "Video",
    InPerson: "In-person",
    Treatments: "Treatments",
    MyAppoinments: "My-Appoinments",
  }
  const [isActive, setIsActive] = useState("Video");

  const userObj = useSelector(state => state.user?.userObj);
  // stats
  const [isLoading, setIsLoading] = useState(false);
  const [healthCenterObj, setHealthCenterObj] = useState(null);
  const [patientData, setPatientData] = useState({
    patients: [userObj?.patientData],
    selected: userObj?.patientData,
  });

  const clinic = useSelector(state => state.clinic);
  const { clincObj } = clinic;

  useEffect(() => {
    fatchHealthCenterDetails(clincObj, setHealthCenterObj, setIsLoading)

    if (userObj?.token) {
      GetPatientFamilyMembers(userObj?.token)
        .then((res) => {
          const familyMembers = res.response.patientData;
          setPatientData((prevPatientData) => ({
            ...prevPatientData,
            patients: [userObj.patientData, ...familyMembers],
          }));
        })
    }
  }, [clincObj, userObj]);

  const isActiveHandler = (value) => isActive === value ? "active" : "";
  const activeHandler = (value) => {
    if (isActive === value) return;
    setIsActive(value);
  }


  const loadingSkeleton = <>
    <Paper className='widget'>
      <div className='widget_box'>
        <div className='widgetItem doc_info'>
          <div className='img'>
            <Skeleton variant="circular" width={48} height={48} />
          </div>
          <div className='widDetails'>
            <span className='sTitle'><Skeleton width={80} /></span>
            <div className='docName'>
              <span className='title'><Skeleton width={120} /></span>
              <span className='icon-arrow-line'></span>
            </div>
            <span><Skeleton width={100} /></span>
          </div>
        </div>
        <div className='widgetItem date'>
          <Skeleton variant="rectangular" height={50} width={40} />
          <div className='widDetails'>
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="60%" />
            <Skeleton height={20} width="80%" />
          </div>
        </div>
        <div className='widgetItem time' >
          <Skeleton variant="rectangular" height={50} width={40} />
          <div className='widDetails'>
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="60%" />
            <Skeleton height={20} width="80%" />
          </div>
        </div>
        <div className='widgetItem appt'>
          <Skeleton variant="circular" height={50} width={50} />
          <div className='widDetails'>
            <Skeleton height={20} width={80} />
            <Skeleton height={20} width={50} />
            <Skeleton height={20} width={50} />
          </div>
        </div>
      </div>
    </Paper>
  </>

  return (
    <section className='widget_main'>
      <div className='container'>
        <div className='widget_nav'>
          <ul>
            <li
              className={isActiveHandler(state.Video)}
              onClick={() => activeHandler(state.Video)}
            >
              <span className='d-md-flex d-none'>
                <CameraIcon />
                Video Call
              </span>
              <span className='d-flex d-md-none'>
                <CameraIcon />
                Video
              </span>
            </li>
            <li
              className={isActiveHandler(state.InPerson)}
              onClick={() => activeHandler(state.InPerson)}
            >
              <span>
                <InPersoneIcon />
                In-person
              </span>
            </li>
            <li
              className={isActiveHandler(state.Treatments)}
              onClick={() => activeHandler(state.Treatments)}>
              <span>
                <TreatmentsIcon />
                Treatments
              </span>
            </li>
            {
              userObj &&
              <li
                className={isActiveHandler(state.MyAppoinments)}
                onClick={() => activeHandler(state.MyAppoinments)}>
                <span className='Uptext  d-none d-md-flex'>
                  <MyApptIcon />
                  My Appoinments
                </span>
                <span className='Uptext d-md-none d-flex'>
                  <MyApptIcon />
                  Appoinments
                </span>
              </li>
            }
          </ul>
        </div>

        {(isActive === state.Video && !isLoading) &&
          <VideoWidget
            healthCenterObj={healthCenterObj}
            patientData={patientData}
            setPatientData={setPatientData}
          />
        }
        {(isActive === state.InPerson && !isLoading) &&
          <InPersoneWidget
            healthCenterObj={healthCenterObj}
            patientData={patientData}
            setPatientData={setPatientData}
          />
        }
        {(isActive === state.Treatments && !isLoading) &&
          <TreatmentWidget
            healthCenterObj={healthCenterObj}
            patientData={patientData}
            setPatientData={setPatientData}
          />
        }
        {(isActive === state.MyAppoinments && !isLoading && userObj) &&
          <MyApptWidget healthCenterObj={healthCenterObj} patientData={patientData} />
        }
        {isLoading && loadingSkeleton}
      </div>

    </section>
  );
};

export default Widgets;