import React from 'react';
import { useSelector } from 'react-redux';

const ConsultationFee = ({ initialFilter, doctor, processObj }) => {
    const currencySymbol = useSelector(
        (state) => state.clinic.clincObj.clinic.currencyDto.symbol
    );

    const getPrice = () => {
        if (initialFilter.appointmentType === "VideoCall") {
            if (doctor.videoCallConsultationFee !== null) {
                return doctor.videoCallConsultationFee;
            } else {
                return "---";
            }

        } else if (initialFilter.appointmentType === "InPerson") {
            if (doctor.inPersonConsultationFee !== null) {
                return doctor.inPersonConsultationFee;
            } else {
                return "---";
            }

        } else if (initialFilter.appointmentType === "Treatment") {
            if (doctor.treatmentConsultationFee !== null) {
                return doctor.treatmentConsultationFee;
            } else {
                return "---";
            }

        } else {
            if (doctor.inPersonConsultationFee !== null) {
                return doctor.inPersonConsultationFee;
            } else if (doctor.videoCallConsultationFee !== null) {
                return doctor.videoCallConsultationFee;
            } else if (doctor.treatmentConsultationFee !== null) {
                return doctor.treatmentConsultationFee;
            } else {
                return "---";
            }
        }
    }

    return (
        <>
            <div className="icon-text">
                <div>
                    <span>{
                        currencySymbol +
                        " " + getPrice()}</span>
                    {" "}
                    Consultation fee
                </div>
            </div>
        </>
    );
};

export default ConsultationFee;