import axios from "axios";

export const getTopics = (id, systemName) => {
    const promise = axios.post(`/${process.env.REACT_APP_API_VERSION_V1}/Topic/GetDetails`,
        {
            clinicId: id,
            systemName: systemName
        }
    );

    const dataPromise = promise.then((response) => response.data.response);

    return dataPromise;
} 