import { Accordion, AccordionDetails, AccordionSummary } from '@mui/material';
import React from 'react';
import ArrowFillIcon from '../Icons/ArrowFillIcon';
import { useSelector } from 'react-redux';
import dependent from "../../static/appt/dep.svg";
import ImageGallery from "react-image-gallery";
import exclm from "../../static/appt/exclm.svg";
import bmi from "../../static/userapp/bmi.svg";
import bp from "../../static/userapp/bp.svg";
import temp from "../../static/userapp/temp.svg";
import pulse from "../../static/userapp/pulse.svg";
import AddUserProfile from "../../components/appt/AddUserProfile";
import moment from 'moment';


const DetailsofPatient = ({ apptDetails,  setApptDetails}) => {
    
    const userDetails = useSelector((state) => state.user.userObj.patientData);

    return (
        <Accordion
            defaultExpanded={true}
            className="sidenav"
        >
            <AccordionSummary expandIcon={<ArrowFillIcon />}>
                <div className="header">Patients Details</div>

            </AccordionSummary>
            <AccordionDetails className="accordial_details">

                <div className="body">
                    <div className="user_info">
                        {apptDetails.appointmentForId ? (
                            <img className="img" src={dependent} alt="user" />
                        ) : (
                            <img
                                className="img"
                                src={userDetails.pictureUrl}
                                alt="user"
                            />
                        )}
                        <div>
                            <div className="name">{apptDetails.name}</div>
                            <div className="det">
                                {apptDetails.age} Years, <span>{apptDetails.gender}</span>
                            </div>
                        </div>

                    </div>
                    <div className="icon-text ">
                        <div className="icon">
                            <img className="star" src={bmi} alt="bmi" />
                        </div>

                        <div className="txt">
                            <span>Weight</span>
                            {apptDetails.healthRecord?.weight}
                            {apptDetails.healthRecord?.weightUnit}
                        </div>
                    </div>
                    <div className="icon-text">
                        <div className="icon">
                            <img className="star" src={bp} alt="bp" />
                        </div>
                        <div className="txt">
                            <span>Blood Pressure</span>
                            {apptDetails.healthRecord?.bloodPressureSystolic}
                            {"/"}
                            {apptDetails.healthRecord?.bloodPressureDiastolic}
                        </div>
                    </div>
                    <div className="icon-text">
                        <div className="icon">
                            <img className="star" src={temp} alt="temp" />
                        </div>
                        <div className="txt">
                            <span>Body temperature</span>
                            {apptDetails.healthRecord?.temperature}
                            {"  "}
                            {apptDetails.healthRecord?.temperatureUnit === "fh"
                                ? " °F"
                                : ""}
                            {apptDetails.healthRecord?.temperatureUnit === "cl"
                                ? " °C"
                                : ""}
                        </div>
                    </div>
                    <div className="icon-text">
                        <div className="icon">
                            <img className="star" src={pulse} alt="pulse" />
                        </div>
                        <div className="txt">
                            <span>Pulse Rate</span>
                            {apptDetails.healthRecord?.pulseRate} bpm
                        </div>
                    </div>
                    {apptDetails.healthRecord && (
                        <div className="icon-text-dark">
                            <div className="icon">
                                <img className="star" src={exclm} alt="pulse" />
                            </div>
                            <div className="txt">
                                {apptDetails?.healthRecord && (
                                    <span>
                                        Last updated:{" "}
                                        {moment
                                            .utc(apptDetails.healthRecord?.updatedOnUtc)
                                            .local()
                                            .format("hh:mm A, DD MMMM, YY")}
                                        .
                                    </span>
                                )}
                                {!apptDetails.isPrevious && (
                                    <>
                                        {" "}
                                        Update this information just 2 hours prior to the
                                        appointment
                                    </>
                                )}
                            </div>
                        </div>
                    )}

                    {!apptDetails.isPrevious && (
                        <AddUserProfile
                            apptId={apptDetails.id}
                            setApptDetails={setApptDetails}
                            apptDetails={apptDetails}
                        />
                    )}
                </div>

            </AccordionDetails>
        </Accordion>
    );
};

export default DetailsofPatient;