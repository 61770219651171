import React from 'react';
import { selectDoctor } from './commonUtils';

const DocDrop = ({ dropActive, doctors, setDoctors }) => {
    const handleClick = (id) => {
        selectDoctor(id, doctors, setDoctors);
    };

    return (
        <div className={`drop_list ${dropActive ? "active" : ""}`}>
            {doctors?.list?.map((doctor, index) => (
                doctor.id !== doctors?.selectedDoctor.id &&
                (
                    <div className='single_doc' key={index} onClick={() => handleClick(doctor.id)}>
                        <div className="img">
                            <img src={doctor?.pictureUrl} alt="" />
                        </div>
                        <div>
                            <span className='name'>{doctor?.name}</span>
                            <span className='specialist'>{doctor?.title}</span>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default DocDrop;
