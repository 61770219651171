import {
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import React, { useEffect } from "react";
import { useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useTranslation } from "react-i18next";
import swal from "sweetalert";

import { SavePatientFamilyMember } from "../../services/patientService";
import CountryCodeSelector from "../microcomponenets/CountryCodeSelector";

function AddDependent(props) {
  const {
    isDrawerOpen,
    setIsDrawerOpen,
    token,
    patientId,
    triggerUpdate,
    isSaving,
    setIsSaving,
    updateDepandant,
  } = props;
  const { t } = useTranslation();

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: process.env.REACT_APP_DEFAULT_COUNTRYCODE,
    number: "",
  });

  //form values
  const [formValues, setFormValues] = useState({
    name: "",
    relation: "",
    email: "",
    age: "",
    gender: "none",
    phone: "",
    countryCode: "",
  });

  useEffect(() => {
    if (updateDepandant) {
      setFormValues({
        ...updateDepandant,
      });
      setPhoneNumber({
        countryCode: updateDepandant?.countryCode ? updateDepandant.countryCode : process.env.REACT_APP_DEFAULT_COUNTRYCODE,
        number: updateDepandant.phone,
      });
    }
  }, [updateDepandant]);

  const handle12Change = (field) => (e) => {
    setFormValues({ ...formValues, [field]: e.target.value });
  };

  // useEffect(() => {
  //   validation()
  // }, [formValues])

  //form variables

  let tempErrors = {
    name: "",
    gender: "",
    relation: "",
    age: "",
    // email: "",
    phone: "",
  };
  const [errors, setErrors] = useState(tempErrors);
  const validation = () => {
    let tempErrors = {
      name: formValues.name ? "" : "Add Patient Name",
      gender: formValues.gender !== "none" ? "" : "Select a Gender",
      relation: formValues.relation !== "" ? "" : "Mention Realtionship",
      age: formValues.age !== "" ? "" : "Mention Age",
      // email: formValues.email !== "" ? "" : "Enter Email Address",
      phone: formValues.phone !== "" ? "" : "Enter Phone Number",
    };

    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x == "");
  };

  const validationNormalizes = () => {
    setFormValues({
      name: "",
      relation: "",
      // email: "",
      age: "",
      gender: "none",
      phone: "",
    });
    let tempErrors = {
      name: "",
      gender: "",
      relation: "",
      age: "",
      //email: "",
      phone: "",
    };

    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x == "");
  };
  //on form submit
  const onSubmit = (e) => {
    e.preventDefault();

    if (validation()) {
      setIsSaving(true);
      // console.log(formValues);

      let tempDependentObj = {
        ...formValues,
        patientId: patientId,
        age: parseInt(formValues.age),
      };

      SavePatientFamilyMember(token, tempDependentObj)
        .then((res) => {
          triggerUpdate(res.response.patientData);
          setIsSaving(false);

          validationNormalizes();
        })
        .catch((err) => {
          console.log(err);
          setIsSaving(false);
        });
    } else {
      swal({
        icon: "error",
        title: t("Fill in All the details"),
      });
    }
  };

  const checkNumber = (e) => {
    let tempNumber = e.target.value;
    let isnum = /^\d+$/.test(tempNumber);

    if (isnum || tempNumber === "") {
      handle12Change("phone")(e);
      setPhoneNumber({ ...phoneNumber, number: e.target.value });
    }
  };

  React.useEffect(() => {
    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        countryCode: phoneNumber.countryCode,
      };
    });
  }, [phoneNumber.countryCode]);

  return (
    <Drawer
      className={`addDependant `}
      anchor="right"
      open={isDrawerOpen}
    // onClose={() => {
    //   //setIsDrawerOpen(false);
    //   validationNormalizes()
    // }}
    >
      <form className="startup-form" onSubmit={onSubmit}>
        <Row>
          <Col xs={12} className="form-ips head">
            Add Member
          </Col>
          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.name !== "" && {
                error: true,
                helperText: errors.name,
              })}
              label="Patient Name"
              value={formValues.name}
              type="text"
              onChange={handle12Change("name")}
              placeholder="Patient Name"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.relation !== "" && {
                error: true,
                helperText: errors.relation,
              })}
              label="Relationship"
              value={formValues.relation}
              type="text"
              onChange={handle12Change("relation")}
              placeholder="Relationship"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.age !== "" && {
                error: true,
                helperText: errors.age,
              })}
              label="Age"
              value={formValues.age}
              type="text"
              onChange={handle12Change("age")}
              placeholder="Age"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.number !== "" && {
                error: true,
                helperText: errors.number,
              })}
              label="Phone Number"
              placeholder="987632XX"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <CountryCodeSelector
                    defaultValue={phoneNumber?.countryCode}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                ),
              }}
              value={phoneNumber.number}
              type="text"
              onChange={(e) => checkNumber(e)}
            />
          </Col>

          <Col xs={12} className="form-ips">
            <TextField
              // {...(errors.email !== "" && {
              //   error: true,
              //   helperText: errors.email,
              // })}
              label="Email Address"
              value={formValues.email}
              type="text"
              onChange={handle12Change("email")}
              placeholder="yourname@mail.com"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>



          <Col xs={12} className="form-ips">
            <FormControl className="selectfmc" error={errors.gender !== ""}>
              <InputLabel id="demo-simple-select-autowidth-label" shrink={true}>
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                className={formValues.gender === "none" ? "selectgray" : ""}
                value={formValues.gender}
                onChange={handle12Change("gender")}
                label="Gender"
              >
                <MenuItem className="d-none" value="none" disabled>
                  Select your gender
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
              {errors.gender !== "" && (
                <FormHelperText>{errors.gender}</FormHelperText>
              )}
            </FormControl>
          </Col>

          <Col xs={12} className=" text-center">
            <button
              disabled={isSaving}
              className="btnr btnr-secondary me-3"
              type="button"
              onClick={() => {
                setIsDrawerOpen(false);
                validationNormalizes();
              }}
            >
              Cancel
            </button>

            <button
              className="btnr btnr-primary"
              disabled={isSaving}
              type="submit"
            >
              {isSaving && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Save
            </button>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
}

export default AddDependent;
