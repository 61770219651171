import React from 'react';

const CameraIcon = () => {
    return (
        <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M5.25 3.75C4.25544 3.75 3.30161 4.14509 2.59835 4.84835C1.89509 5.55161 1.5 6.50544 1.5 7.5V16.5C1.5 17.4946 1.89509 18.4484 2.59835 19.1517C3.30161 19.8549 4.25544 20.25 5.25 20.25H12.75C13.7446 20.25 14.6984 19.8549 15.4016 19.1517C16.1049 18.4484 16.5 17.4946 16.5 16.5V7.5C16.5 6.50544 16.1049 5.55161 15.4016 4.84835C14.6984 4.14509 13.7446 3.75 12.75 3.75H5.25ZM21.276 6.165L18 8.8395V15.162L21.276 17.832C21.3861 17.9218 21.5194 17.9785 21.6605 17.9954C21.8015 18.0124 21.9445 17.9889 22.0727 17.9278C22.201 17.8666 22.3092 17.7703 22.3849 17.6501C22.4605 17.5298 22.5004 17.3906 22.5 17.2485V6.7485C22.4999 6.60669 22.4595 6.46782 22.3837 6.34801C22.3078 6.22819 22.1995 6.13234 22.0714 6.07157C21.9433 6.01081 21.8005 5.98761 21.6597 6.00468C21.519 6.02174 21.3859 6.07837 21.276 6.168V6.165Z" fill="#B7BFCC" />
        </svg>
    );
};

export default CameraIcon;