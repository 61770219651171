import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

function TreatmentTrack({ treatmentsList }) {
  ////currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const [sliceCount, setSliceCount] = useState(8);

  const [isSeeMoreActive, setIsSeeMoreActive] = useState(false);


  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      if (isSeeMoreActive) {
        setSliceCount(treatmentsList.length);
      } else {
        setSliceCount(screenWidth < 576 ? 4 : 8);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);


  return (
    <>
      <Row className={`treatment-track `}>
        {
          (isSeeMoreActive ? treatmentsList : treatmentsList.slice(0, sliceCount)).map((item, index) => (
            <Col
              sm={3}
              xs={6}
              className={`treatmentItem`}
              key={item.id}
            >
              <div className="item">
                <div className="image">
                  <img src={item.pictureUrl} alt={item.name} />
                  {item.price > 0 && (
                    <span>
                      {currencySymbol} {item.price}
                    </span>
                  )}
                </div>
                <div className="title">{item.name}</div>
                <div className="d-flex float_area">
                  <div className="text">{item.shortDescription}</div>
                  <div className="view_button">
                    <Link to={`/treatment/${item.slug}`} className="btnr btnr-primary btnr-hover">
                      View Details <span className="arr"></span>
                    </Link>
                  </div>
                </div>
              </div>
            </Col>
          ))
        }
        {/* {(isSeeMoreActive ? treatmentsList : treatmentsList.slice(0, sliceCount)).map((item, index) => {
          if (index >= 0) {
            return (
              <Col
                sm={3}
                xs={6}
                className={`treatmentItem`}
                key={item.id}
              >
                <div

                  className="item"
                >
                  <div className="image">
                    <img src={item.pictureUrl} alt={item.name} />
                    {item.price > 0 && (
                      <span>
                        {currencySymbol} {item.price}
                      </span>
                    )}
                  </div>
                  <div className="title">{item.name}</div>
                  <div className="d-flex float_area">

                    <div className="text">{item.shortDescription}</div>
                    <div className="view_button">
                      <Link to={`/treatment/${item.slug}`} className="btnr btnr-primary">View Details <span className="arr"></span></Link>
                    </div>
                  </div>
                </div>
              </Col>
            );
          }
        })} */}
      </Row>
      {treatmentsList?.length > 4 && (
        <div className="d-flex justify-content-center mt-5 d-flex d-sm-none">
          <span
            style={{ cursor: "pointer" }}
            className="btnr btnr btnr-primary-outline"
            onClick={() => setIsSeeMoreActive(!isSeeMoreActive)}
          >
            {isSeeMoreActive ? "View Less" : "See More"}
          </span>
        </div>
      )}
      {treatmentsList?.length > 8 && (
        <div className="see_more justify-content-center mt-5 d-none d-sm-flex">
          <span
            style={{ cursor: "pointer" }}
            className="btnr btnr-primary"
            onClick={() => setIsSeeMoreActive(!isSeeMoreActive)}
          >
            {isSeeMoreActive ? "View Less" : "See More"}
          </span>
        </div>
      )}
    </>
  );
}

export default TreatmentTrack;
