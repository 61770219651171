import React from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  CancelAppointment,
  GetAppointments,
  GetAppointmentsById,
  PatientCheckinInPerson,
  PatientCheckinTreatment,
} from "../services/apptservice";
import { useState } from "react";
import moment from "moment";
import dependent from "../static/appt/dep.svg";
import ImageGallery from "react-image-gallery";

import vc from "../static/appt/vc.svg";
import exclm from "../static/appt/exclm.svg";
import ip from "../static/appt/ip.svg";
import trt from "../static/appt/trt.svg";
import tick from "../static/userapp/tick.svg"

import star from "../static/appt/Star.svg";
import dlr from "../static/appt/dollar.svg";
import pdf from "../static/appt/pdf.svg";

import bmi from "../static/userapp/bmi.svg";
import bp from "../static/userapp/bp.svg";
import temp from "../static/userapp/temp.svg";
import pulse from "../static/userapp/pulse.svg";
import yellowWatch from "../static/userapp/yellowWatch.svg";
import user3 from "../static/userapp/user3.svg";
import D_check from "../static/userapp/D_check.svg";
import document from "../static/userapp/document.svg";

import ti from "../static/userapp/ti.svg";
import bs from "../static/userapp/bs.svg";

import clocksm from "../static/userapp/clocksm.svg";
import calsm from "../static/userapp/calsm.svg";

import lap from "../static/userapp/lap.svg";
import mic from "../static/userapp/mic.svg";
import cam from "../static/userapp/cam.svg";
import vid from "../static/userapp/vid.svg";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import VideoCallButton from "../components/VideoCall/VideoCallButton";
import { useRef } from "react";
import PDFView from "../components/microcomponenets/PDFView";
import AddReview from "../components/appt/AddReview";
import swal from "sweetalert";
import AddUserProfile from "../components/appt/AddUserProfile";
import PatientDocs from "../components/appt/PatientDocs";
import ArrowFillIcon from "../components/Icons/ArrowFillIcon";
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from "@mui/material";
import PressAndRecomen from "../components/appt/PressAndRecomen";
import { downloadInvoice } from "../utils/downloadInvoice";
const PAGE_SIZE = 2;
const APP_OBJ = {
  doctorId: 0,
  pageNo: 1,
  healthCenterId: 0,
  pageSize: PAGE_SIZE,
  imageSize: 0,
  patientId: 2,
  type: "",
  appointmentType: "",
};

function AppointmentDetails() {
  const userDetails = useSelector((state) => state.user.userObj.patientData);
  const userToken = useSelector((state) => state.user.userObj.token);
  const healthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );

  const navigate = useNavigate();
  const location = useLocation();

  // const apptId = location.state?.appt;
  // const apptType = location.state?.apptType;

  const { apptType, apptId } = useParams();

  const [apptDetails, setApptDetails] = useState(null);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (apptId) {
      GetAppointmentsById(apptId, userToken).then((res) => {
        setApptDetails(res);
        if (res.recommendationDto) {
          if (res.recommendationDto.attachmentDtos.length > 0) {
            let tempImageArr = res.recommendationDto.attachmentDtos
              .filter(
                (rec) =>
                  rec.attachmentUrl.split(/[#?]/)[0].split(".").pop().trim() !==
                  "pdf"
              )
              .map((rec, index) => {
                let tempObj = {
                  original: rec.attachmentUrl,
                  thumbnail: rec.attachmentUrl,
                };

                return tempObj;
              });

            setImages(tempImageArr);
          }
        }
      });
    } else {
      navigate("/appointments");
    }
  }, []);

  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  //image gallery ref
  const ImageGalleryRef = useRef(null);

  const [isGallVisible, setIsGallVisible] = useState(false);

  const openImageGallery = () => {
    ImageGalleryRef.current.toggleFullScreen();
    setIsGallVisible(true);
  };

  const onScreenChange = (isFullScreen) => {
    if (!isFullScreen) {
      // Full screen mode exited
      setIsGallVisible(false);
    }
  };

  //PDF VIEWER

  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const openPdfViewer = (pdfUrl) => {
    setPdfUrl(pdfUrl);
  };

  useEffect(() => {
    if (pdfUrl.length > 0) {
      setIsPdfViewerOpen(true);
    }
  }, [pdfUrl]);

  const navigateToBookAppt = (stateforNextPage) => {
    if (userDetails !== null) {
      navigate("/bookappt", { state: stateforNextPage });
    }
  };

  const cancelAppointment = () => {
    swal({
      title: "Are you sure?",
      text: "You want to Cancel the Appointment.",
      icon: "warning",
      buttons: ["Dont, cancel it!", "Yes, I am sure Cancel it!"],
      className: "my-swal-dialog-danger",
    }).then(function (isConfirm) {
      if (isConfirm) {
        let tempObj = {
          appointmentId: apptId,
          date: moment().format(),
          cancelledBy: "Patient",
          cancelledById: userDetails.id,
          timeDifferenceFromUtcInMinutes: moment().utcOffset(),
        };
        CancelAppointment(tempObj, userToken)
          .then((res) => {
            if (res.response === "Cancelled the appointment") {
              swal({
                title: "Cancelled",
                text: "The Appointment has been cancelled !",
                icon: "success",
                className: "my-swal-dialog",
              });
              navigate("/appointments");
            }
          })
          .catch((err) => {
            console.log(err);
          });
      } else {
      }
    });
  };

  const [isSaving, setIsSaving] = useState(false);
  const checkIn = (apptType) => {
    setIsSaving(true);
    if (apptDetails.appointmentType === "InPerson") {
      PatientCheckinInPerson(apptDetails.id, userToken)
        .then((res) => {
          setApptDetails({
            ...apptDetails,
            statesDto: res.response.states,
          });
          setIsSaving(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSaving(false);
        });
    } else if (apptDetails.appointmentType === "Treatment") {
      PatientCheckinTreatment(apptDetails.id, userToken)
        .then((res) => {
          setApptDetails({
            ...apptDetails,
            statesDto: res.response.states,
          });
          setIsSaving(false);
        })
        .catch((err) => {
          console.log(err);
          setIsSaving(false);
        });
    }
  };

  const isRescheduleAppt = () => {
    swal({
      title: "Are you sure, you want to reschedule?",
      text: "Rescheduling allows you to only change date and time, for once.",
      icon: "warning",
      buttons: ["Cancel", "Yes, I am sure Reschedule it!"],
      className: "my-swal-dialog-danger",
    }).then(function (isConfirm) {
      if (isConfirm) {
        navigateToBookAppt({
          doctor: apptDetails.doctorDto,
          apptType: apptDetails.appointmentType,
          processObj: apptDetails.treatment,
          processId: apptDetails.treatmentId,
          reschedule: true,
          apptId: apptDetails.id,
        });
      } else {
      }
    });
  };

  const getStartTimeMessage = () => {
    const currentTime = moment();
    const scheduledTime = moment.utc(apptDetails.scheduledAtUtc).local();
    const minutesLeft = scheduledTime.diff(currentTime, 'minutes');

    if (minutesLeft > 60) {
      const hoursLeft = Math.floor(minutesLeft / 60);
      const remainingMinutes = minutesLeft % 60;

      return (
        <span>
          Starts At <span>{hoursLeft}:{remainingMinutes < 10 ? `0${remainingMinutes}` : remainingMinutes}</span> Hours
        </span>
      );

    } else {
      return (
        <span>
          Starts At <span>{minutesLeft}</span> Min
        </span>
      );
    }
  };

  const getType = (type) => {
    if (type === "InPerson") {
      return "IP";
    }
    if (type === "VideoCall") {
      return "vc";
    }
    if (type === "Treatment") {
      return "tp";
    }
  }

  const isMobile = useMediaQuery('(max-width:540px)');

  return (
    apptDetails && (
      <div className="main-body">
        <div className="container">
          <div className="userapptDetails-main">
            <Row className="userapptDetails">
              <Col sm={3} xs={12} className="details_section">
                {apptDetails.isPrevious && (
                  <>
                    <div className="appt-doc-profile">
                      <div className="img">
                        <img src={apptDetails.doctorPictureUrl} alt="asd" />
                      </div>
                      <div className="body">
                        <div className="name">{apptDetails.doctorDto.name}</div>
                        <div className="designation">
                          {apptDetails.doctorDto.title}
                        </div>
                        <div className="qualification">
                          {apptDetails.doctorDto.qualification}
                        </div>
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={star} alt="star" />
                          </div>
                          <div className="txt">
                            <span>Rating</span>
                            {apptDetails.doctorDto.rating} out of 5
                          </div>
                        </div>
                        {apptDetails.appointmentType !== "" && (
                          <div className="icon-text">
                            <div className="icon">
                              <img className="dlr" src={dlr} alt="dlr" />
                            </div>
                            <div className="txt">
                              <span>Fees</span>
                              {apptDetails.appointmentType === "InPerson" &&
                                "Consultation " +
                                currencySymbol +
                                apptDetails.doctorDto.inPersonConsultationFee}
                              {apptDetails.appointmentType === "VideoCall" &&
                                "Consultation " +
                                currencySymbol +
                                apptDetails.doctorDto
                                  .videoCallConsultationFee}
                              {apptDetails.appointmentType === "Treatment" &&
                                "Treatment " +
                                currencySymbol +
                                apptDetails.paymentDetails.fee}
                            </div>
                          </div>
                        )}
                      </div>
                      {!apptDetails.isReschedule && (
                        <>
                          {apptDetails.statesDto &&
                            apptDetails.statesDto.canPatientCancel && (
                              <button
                                className="btnr btnr-primary"
                                onClick={() => isRescheduleAppt()}
                              >
                                RE-SCHEDULE <span></span>
                              </button>
                            )}
                        </>
                      )}




                    </div>

                    <Row className="paymentDetails">

                      <Col xs={12} sm={12} className="header mt-2 mb-2">
                        Payment Summary
                      </Col>
                      <Col xs={12} sm={12}>
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={bs} alt="bp" />
                          </div>
                          <div className="txt">
                            <span className="t1">Payment Method</span>
                            <span className="t2">
                              {apptDetails.paymentDetails.paymentType}
                            </span>
                          </div>
                        </div>
                      </Col>

                      <Col xs={12} sm={12}>
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={bs} alt="bp" />
                          </div>
                          <div className="txt">
                            <span className="t1">Bill Summary</span>
                            <span className="t2">
                              Total Amount
                              <span className="t3">
                                {currencySymbol}
                                {apptDetails.paymentDetails.fee +
                                  apptDetails.paymentDetails.serviceFee +
                                  apptDetails.paymentDetails.tax}
                              </span>{" "}
                            </span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={bs} alt="bp" />
                          </div>
                          <div className="txt">
                            <span className="t1">Transaction ID</span>
                            <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                        <div className="icon-text">
                          <div className="icon">
                            <img className="star" src={bs} alt="bp" />
                          </div>
                          <div className="txt">
                            <span className="t1">Transaction ID</span>
                            <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                          </div>
                        </div>
                      </Col>
                      <Col xs={12} sm={12} className="d-flex justify-content-center">
                        <button onClick={()=>downloadInvoice(apptDetails.slug)} className="btnr btnr-primary-outline">Download Invoice</button>
                      </Col>
                    </Row>
                    {/* {apptDetails.statesDto.canAddReview && ( */}
                    {apptDetails && apptDetails.statesDto?.canAddReview && (
                      <AddReview
                        userId={userDetails.id}
                        userToken={userToken}
                        docId={apptDetails.doctorDto.id}
                        apptId={apptDetails.id}
                        patientRatingDto={apptDetails.patientRatingDto}
                        apptDetails={apptDetails}
                        setApptDetails={setApptDetails}
                      />
                    )}

                  </>
                )}
                {!apptDetails.isPrevious && (
                  <>
                    <Accordion
                      defaultExpanded={!isMobile}
                      className={`appt_details ${apptDetails.appointmentType === "VideoCall" && "vc"}`}
                    >
                      <AccordionSummary expandIcon={<ArrowFillIcon />}>
                        <div className="header top_header">
                          Appointment Details
                        </div>
                      </AccordionSummary>
                      <AccordionDetails className="accordial_details">
                        <Row className="appt_info">
                          <div className="col-12">
                            <Row>
                              <Col md={8} className="txt">
                                Appointment ID
                                <span>{apptDetails.appointmentNumber}</span>
                              </Col>
                              {/* <Col md={4} className="complete">
                                <img src={tick} alt="" />
                              Complete
                              </Col> */}
                            </Row>
                          </div>
                          <Col xs={12} sm={12}>
                            <div className="row">
                              <div className="col-7">
                                <div className="txt">
                                  Booked by
                                  <span>{apptDetails.patientName}</span>
                                </div>
                              </div>
                              <div className="col-5">
                                <div className="txt myself">
                                  Booked for
                                  <span>
                                    {apptDetails?.appointmentForRelation !== null ? apptDetails?.appointmentForRelation : "Myself"}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12}>
                            <div className="txt">
                              Booking Date
                              <span>
                                {moment
                                  .utc(apptDetails?.scheduledAtUtc)
                                  .local()
                                  .format("DD,MMM YYYY")
                                }
                              </span>
                            </div>
                          </Col>
                          {apptDetails.statesDto &&
                            apptDetails.statesDto.canPatientCancel &&
                            <>
                              <Col xs={12} sm={12} className="col-12 buttons">
                                <button
                                  className="btnr w-100"
                                  onClick={() => cancelAppointment()}
                                >cancel</button>
                                {!apptDetails.isReschedule &&
                                  <button
                                    className="btnr w-100"
                                    onClick={() => isRescheduleAppt()}
                                  >Reschedule</button>
                                }
                              </Col>
                            </>
                          }
                          <Col xs={12} sm={12} className="header mt-2 mb-2">
                            Payment Summary
                          </Col>
                          <Col xs={12} sm={12}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                              </div>
                              <div className="txt">
                                <span className="t1">Payment Method</span>
                                <span className="t2">
                                  {apptDetails.paymentDetails.paymentType}
                                </span>
                              </div>
                            </div>
                          </Col>

                          <Col xs={12} sm={12}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                              </div>
                              <div className="txt">
                                <span className="t1">Bill Summary</span>
                                <span className="t2">
                                  Total Amount
                                  <span className="t3">
                                    {currencySymbol}
                                    {apptDetails.paymentDetails.fee +
                                      apptDetails.paymentDetails.serviceFee +
                                      apptDetails.paymentDetails.tax}
                                  </span>{" "}
                                </span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                              </div>
                              <div className="txt">
                                <span className="t1">Transaction ID</span>
                                <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                            <div className="icon-text">
                              <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                              </div>
                              <div className="txt">
                                <span className="t1">Transaction ID</span>
                                <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                              </div>
                            </div>
                          </Col>
                          <Col xs={12} sm={12} className="d-flex justify-content-center d-none">
                          <button onClick={()=>downloadInvoice(apptDetails.slug)} className="btnr btnr-primary-outline">Download Invoice</button>
                          </Col>
                        </Row>
                      </AccordionDetails>
                    </Accordion>

                    {apptDetails.statesDto.canAddReview && (
                      <AddReview
                        userId={userDetails.id}
                        userToken={userToken}
                        docId={apptDetails.doctorDto.id}
                        apptId={apptDetails.id}
                        patientRatingDto={apptDetails.patientRatingDto}
                        apptDetails={apptDetails}
                        setApptDetails={setApptDetails}
                      />
                    )}
                  </>
                )}
              </Col>
              <Col sm={6} xs={12} className="appt_section">
                <div className="appt-parent">
                  <div
                    className={`appt-state ${getType(apptDetails.appointmentType)} ${apptDetails.isFollowUp ? "FollowUp" : ""}`}
                  >
                    <div className="header">
                      {apptDetails.appointmentType === "Treatment" && (
                        <>
                          <img
                            // src={apptDetails.treatment.pictureUrl}
                            src={trt}
                            className="treatment-img"
                            alt="ti"
                          />
                          Treatment
                        </>
                      )}
                      {apptDetails.appointmentType === "InPerson" && (
                        <>
                          <img src={ip} className="ipimg" alt="ti" />
                          Clinic Visit
                        </>
                      )}
                      {apptDetails.appointmentType === "VideoCall" && (
                        <>
                          <img src={vc} className="ipimg" alt="ti" />
                          Video Call
                        </>
                      )}
                    </div>
                    <Row>
                      <Col md={7}>
                        <div className={`doc ${apptDetails.appointmentType === "Treatment" && "treat"}`}>

                          {apptDetails.appointmentType === "Treatment" ?

                            <div className="img"
                              style={{ backgroundImage: `url(${apptDetails.treatment.pictureUrl})` }}
                            ></div>
                            :
                            <div className="img"
                              style={{ backgroundImage: `url(${apptDetails.doctorPictureUrl})` }}
                            ></div>

                          }

                          <div className="doc_info">
                            {apptDetails.doctorDto.name}
                            <span>
                              {apptDetails.appointmentType === "Treatment" ?
                                <>
                                  {apptDetails.durationMin} min treatment,
                                  <span>
                                    {" "}
                                    Clinic Visit
                                  </span>
                                </>
                                :
                                <>
                                  {apptDetails.doctorDto.title}
                                </>
                              }
                            </span>
                          </div>
                        </div>
                        <div md={12} className="body">
                          <div className="img"></div>
                          <div className="body_info">

                            {apptDetails.isFollowUp ?
                              <div className="det-txt">
                                Follow Up Request
                                <div className="det-txt">
                                  <img src={calsm} alt="" />
                                  {moment
                                    .utc(apptDetails.scheduledAtUtc)
                                    .local()
                                    .format("DD, MMMM YY")}
                                </div>
                              </div>
                              :
                              <>
                                <div className="det-txt">
                                  <img src={calsm} alt="" />
                                  {moment
                                    .utc(apptDetails.scheduledAtUtc)
                                    .local()
                                    .format("DD, MMMM YY")}
                                </div>
                                <div className="det-txt">
                                  <img src={clocksm} alt="" />
                                  {`${moment
                                    .utc(apptDetails.scheduledAtUtc)
                                    .local()
                                    .format("hh:mm a")} - ${moment
                                      .utc(apptDetails.scheduledAtUtc)
                                      .add(apptDetails.durationMin, "minutes")
                                      .local()
                                      .format("hh:mm a")}`}
                                </div>
                              </>
                            }

                          </div>
                        </div>
                      </Col>
                      <Col md={5} className="appt_time">

                        <div className="subSec">
                          <div>
                            {apptDetails.appointmentType === "Treatment" ?

                              <div className={`doc`}>
                                <div className="img"
                                  style={{ backgroundImage: `url(${apptDetails.doctorPictureUrl})` }}
                                ></div>
                                <div className={`doc_info `}>
                                  {apptDetails.doctorDto.name}
                                </div>
                              </div>
                              :
                              <img src={user3} alt="" />
                            }
                          </div>

                          {!apptDetails.isPrevious &&
                            <div className="start_time d-none d-md-flex">
                              <img src={yellowWatch} alt="" />
                              {getStartTimeMessage()}
                            </div>
                          }

                        </div>
                        <div className="text-center mt-3">

                          <div className="subSec d-md-none">
                            {!apptDetails.isPrevious &&
                              <div className="start_time d-none d-md-flex">
                                <img src={yellowWatch} alt="" />
                                {getStartTimeMessage()}
                              </div>
                            }

                          </div>

                          {apptDetails.statesDto &&
                            apptDetails.statesDto.canPatientJoin && (
                              <>
                                {apptDetails.appointmentType === "VideoCall" && (
                                  <>
                                    {apptDetails.appointmentType ===
                                      "VideoCall" &&
                                      true && (
                                        <VideoCallButton
                                          userToken={userToken}
                                          apptId={apptId}
                                          appointmentCallState={
                                            apptDetails.statesDto
                                          }
                                          apptDetails={apptDetails}
                                          setUpdateApptDetailsState={(obj) =>
                                            setApptDetails({
                                              ...apptDetails,
                                              statesDto: { ...obj },
                                            })
                                          }
                                        />
                                      )}
                                  </>
                                )}
                                {apptDetails.appointmentType === "InPerson" && (
                                  <button
                                    className="btnr btnr-primary w-100"
                                    disabled={isSaving}
                                    onClick={() =>
                                      checkIn(apptDetails.appointmentType)
                                    }
                                  >
                                    {isSaving && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <img className="mr-2" src={D_check} alt="" />
                                    Check IN
                                  </button>
                                )}
                                {apptDetails.appointmentType === "Treatment" && (
                                  <button
                                    className="btnr btnr-primary w-100"
                                    disabled={isSaving}
                                    onClick={() =>
                                      checkIn(apptDetails.appointmentType)
                                    }
                                  >
                                    {isSaving && (
                                      <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                      />
                                    )}
                                    <img className="mr-2" src={D_check} alt="" />
                                    Check IN
                                  </button>
                                )}
                              </>
                            )}
                          {
                            apptDetails?.isFollowUp &&
                            <button
                              className="btnr btnr-primary btnr-hover"
                              onClick={() =>
                                navigateToBookAppt({
                                  doctor: apptDetails.doctorDto,
                                  apptType: apptDetails.appointmentType,
                                  processObj: apptDetails.treatment,
                                  processId: apptDetails.treatmentId,
                                  followup: true,
                                  apptId: apptDetails.id,
                                  isFreeFollowUp: apptDetails.isFreeFollowUp,
                                })
                              }
                            >
                              Book Now <span className="arr"></span>
                            </button>
                          }
                        </div>
                      </Col>
                    </Row>
                  </div>

                  <div className="upload_report">
                    <PatientDocs
                      patientInformationDto={apptDetails.patientInformationDto}
                      appointmentId={apptDetails.id}
                    />
                  </div>


                  <Accordion
                    defaultExpanded={!isMobile}
                    className={`Clinic_dettails ${apptDetails.appointmentType === "VideoCall" && "d-none"}`}
                  >
                    <AccordionSummary expandIcon={<ArrowFillIcon />}>
                      <div className="header">
                        Clinic Details
                      </div>
                    </AccordionSummary>
                    <AccordionDetails className="accordial_details">
                      <div className="body">
                        {apptDetails.isPrevious &&
                          <Row className="test">
                            <Col xs={12} sm={8}>
                              {apptDetails.isPrevious && (
                                <>
                                  <Row className="mt-2">
                                    <Col xs={6} sm={6}>
                                      <div className="txt">
                                        Consultation Date
                                        <span>
                                          {moment
                                            .utc(apptDetails.scheduledAtUtc)
                                            .local()
                                            .format("DD, MMMM YY")}
                                        </span>
                                      </div>
                                    </Col>
                                    <Col xs={6} sm={6}>
                                      <div className="txt">
                                        Consultation Duration
                                        <span>
                                          {apptDetails.durationHr * 60 +
                                            apptDetails.durationMin}{" "}
                                          mins
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                  <Row className="mt-2">
                                    <Col xs={6} sm={6}>
                                      <div className="txt">
                                        Consultation Time
                                        <span>
                                          {`${moment
                                            .utc(apptDetails.scheduledAtUtc)
                                            .local()
                                            .format("hh:mm a")} - ${moment
                                              .utc(apptDetails.scheduledAtUtc)
                                              .add(apptDetails.durationMin, "minutes")
                                              .local()
                                              .format("hh:mm a")}`}
                                        </span>
                                      </div>
                                    </Col>
                                  </Row>
                                </>
                              )}
                            </Col>

                            {apptDetails.isPrevious && (
                              <Col xs={12} sm={4}>
                                <div
                                  className={`cardaptytpe ${apptDetails.appointmentType === "VideoCall"
                                    ? "green"
                                    : ""
                                    }`}
                                >
                                  {apptDetails.appointmentType === "Treatment" && (
                                    <>
                                      <img
                                        src={apptDetails.treatment.pictureUrl}
                                        className="treatment-img"
                                        alt="ti"
                                      />
                                      {apptDetails.treatment.name}
                                    </>
                                  )}
                                  {apptDetails.appointmentType === "InPerson" && (
                                    <>
                                      <img src={ip} className="ipimg" alt="ti" />
                                      In Person
                                    </>
                                  )}
                                  {apptDetails.appointmentType === "VideoCall" && (
                                    <>
                                      <img src={vc} className="ipimg" alt="ti" />
                                      Video Call
                                    </>
                                  )}
                                </div>
                              </Col>
                            )}
                            <Col xs={12}>
                              {apptDetails.isReschedule && (
                                <div className="rs-app">
                                  <svg
                                    className="me-2"
                                    xmlns="http://www.w3.org/2000/svg"
                                    width={24}
                                    height={24}
                                    fill="none"
                                  >
                                    <path
                                      fill="#FFB201"
                                      d="M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2Zm0 15c-.55 0-1-.45-1-1v-4c0-.55.45-1 1-1s1 .45 1 1v4c0 .55-.45 1-1 1Zm1-8h-2V7h2v2Z"
                                    />
                                  </svg>
                                  This is a rescheduled appointment
                                </div>
                              )}
                            </Col>
                          </Row>
                        }
                        {apptDetails.appointmentType !== "VideoCall" && (
                          <Row>
                            <Col xs={12} sm={12}>
                              <div className="txt txt2">
                                Clinic name
                                <span>{healthCenter.healthCenterName}</span>
                              </div>
                            </Col>
                            <Col xs={12} sm={12}>
                              <div className="txt">
                                Address
                                <span>{healthCenter.address}</span>
                              </div>
                            </Col>
                            <Col xs={12} sm={12}>

                            </Col>
                          </Row>
                        )}



                        {/* {apptDetails.isPrevious && (
                          <>
                            {apptDetails.recommendationDto && (
                              <>
                                {apptDetails.recommendationDto.attachmentDtos.filter(
                                  (rec) =>
                                    rec.attachmentUrl
                                      .split(/[#?]/)[0]
                                      .split(".")
                                      .pop()
                                      .trim() !== "pdf"
                                ).length > 0 && (
                                    <Row>
                                      <Col xs={12} sm={12}>
                                        <div className="head">
                                          Shared prescription
                                        </div>
                                      </Col>
                                      <Col
                                        xs={12}
                                        sm={12}
                                        className="recomdation-img-list"
                                      >
                                        {apptDetails.recommendationDto.attachmentDtos
                                          .filter(
                                            (rec) =>
                                              rec.attachmentUrl
                                                .split(/[#?]/)[0]
                                                .split(".")
                                                .pop()
                                                .trim() !== "pdf"
                                          )
                                          .map((rec, index) => (
                                            <div
                                              key={index}
                                              onClick={() => openImageGallery()}
                                            >
                                              <img
                                                src={rec.attachmentUrl}
                                                alt="as"
                                              />
                                            </div>
                                          ))}
                                      </Col>
                                      <Col
                                        xs={12}
                                        className={`${!isGallVisible ? "img-gal" : ""
                                          }`}
                                      >
                                        <ImageGallery
                                          ref={ImageGalleryRef}
                                          items={images}
                                          onScreenChange={onScreenChange}
                                        />
                                      </Col>
                                    </Row>
                                  )}

                                {apptDetails.recommendationDto.attachmentDtos.filter(
                                  (rec) =>
                                    rec.attachmentUrl
                                      .split(/[#?]/)[0]
                                      .split(".")
                                      .pop()
                                      .trim() === "pdf"
                                ).length > 0 && (
                                    <Row>
                                      <Col xs={12} sm={12}>
                                        <div className="head">
                                          Shared prescription PDF
                                        </div>
                                      </Col>
                                      <Col
                                        xs={12}
                                        sm={12}
                                        className="recomdation-img-list"
                                      >
                                        {apptDetails.recommendationDto.attachmentDtos
                                          .filter(
                                            (rec) =>
                                              rec.attachmentUrl
                                                .split(/[#?]/)[0]
                                                .split(".")
                                                .pop()
                                                .trim() === "pdf"
                                          )
                                          .map((rec, index) => (
                                            <a
                                              key={index}
                                              // onClick={() =>
                                              //   openPdfViewer(rec.attachmentUrl)
                                              // }

                                              href={rec.attachmentUrl}
                                            >
                                              <img src={pdf} alt="as" />
                                            </a>
                                          ))}
                                      </Col>
                                      {isPdfViewerOpen && (
                                        <div
                                          className={`pdfViewer ${isPdfViewerOpen ? "open" : ""
                                            }`}
                                        >
                                          <PDFView pdfURL={pdfUrl} />
                                        </div>
                                      )}
                                    </Row>
                                  )}

                                {
                                  apptDetails.recommendationDto
                                    .messageBody !== "<p><br></p>" &&
                                  (
                                    <Row>
                                      <Col xs={12} sm={12}>
                                        <div className="head">Recommendation</div>
                                      </Col>
                                      <Col xs={12} sm={12}>
                                        <div
                                          className="rec-text"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              apptDetails.recommendationDto
                                                .messageBody,
                                          }}
                                        ></div>
                                      </Col>
                                    </Row>
                                  )
                                }

                              </>
                            )}
                          </>
                        )} */}

                      </div>
                    </AccordionDetails>
                  </Accordion>

                  <PressAndRecomen apptDetails={apptDetails} images={images} setImages={setImages} />

                  {!apptDetails.isPrevious && (
                    <>
                      {apptDetails.appointmentType === "VideoCall" && (
                        <div className="gen-inst">
                          <Accordion defaultExpanded={!isMobile}>
                            <AccordionSummary expandIcon={<ArrowFillIcon />}>
                              <div className="header">Instructions</div>
                            </AccordionSummary>
                            <AccordionDetails className="accordial_details">
                              <div className="body">
                                <Row>
                                  <Col xs={12}>
                                    <div className="icon-text">
                                      <div className="icon">
                                        <img className="star" src={lap} alt="bmi" />
                                      </div>
                                      <div className="txt">
                                        <span className="t1">
                                          Stable internet connection
                                        </span>
                                        <span className="t2">
                                          Ensure that you have a stable internet
                                          connection and a device (such as a
                                          smartphone, tablet, or computer) with a
                                          working camera and microphone. Test your
                                          audio and video settings in advance.
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <div className="icon-text">
                                      <div className="icon">
                                        <img className="star" src={mic} alt="bmi" />
                                      </div>
                                      <div className="txt">
                                        <span className="t1">Check Your Audio</span>
                                        <span className="t2">
                                          At the scheduled appointment time, log in
                                          to your patient account on our clinic
                                          website. Navigate to the appointment
                                          details page and click on the "Join Video
                                          Call" button. This will launch the video
                                          call interface.
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <div className="icon-text">
                                      <div className="icon">
                                        <img className="star" src={cam} alt="bmi" />
                                      </div>
                                      <div className="txt">
                                        <span className="t1">
                                          Check Your Web Camera
                                        </span>
                                        <span className="t2">
                                          At the scheduled appointment time, log in
                                          to your patient account on our clinic
                                          website. Navigate to the appointment
                                          details page and click on the "Join Video
                                          Call" button. This will launch the video
                                          call interface.
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <Row>
                                  <Col xs={12}>
                                    <div className="icon-text">
                                      <div className="icon">
                                        <img className="star" src={vid} alt="bmi" />
                                      </div>
                                      <div className="txt">
                                        <span className="t1">
                                          Join the video call
                                        </span>
                                        <span className="t2">
                                          At the scheduled appointment time, log in
                                          to your patient account on our clinic
                                          website. Navigate to the appointment
                                          details page and click on the "Join Video
                                          Call" button. This will launch the video
                                          call interface.
                                        </span>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                              </div>
                            </AccordionDetails>
                          </Accordion>

                          {/* <div className="header">Instructions</div>
                          <div className="body">
                            <Row>
                              <Col xs={12}>
                                <div className="icon-text">
                                  <div className="icon">
                                    <img className="star" src={lap} alt="bmi" />
                                  </div>
                                  <div className="txt">
                                    <span className="t1">
                                      Stable internet connection
                                    </span>
                                    <span className="t2">
                                      Ensure that you have a stable internet
                                      connection and a device (such as a
                                      smartphone, tablet, or computer) with a
                                      working camera and microphone. Test your
                                      audio and video settings in advance.
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="icon-text">
                                  <div className="icon">
                                    <img className="star" src={mic} alt="bmi" />
                                  </div>
                                  <div className="txt">
                                    <span className="t1">Check Your Audio</span>
                                    <span className="t2">
                                      At the scheduled appointment time, log in
                                      to your patient account on our clinic
                                      website. Navigate to the appointment
                                      details page and click on the "Join Video
                                      Call" button. This will launch the video
                                      call interface.
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="icon-text">
                                  <div className="icon">
                                    <img className="star" src={cam} alt="bmi" />
                                  </div>
                                  <div className="txt">
                                    <span className="t1">
                                      Check Your Web Camera
                                    </span>
                                    <span className="t2">
                                      At the scheduled appointment time, log in
                                      to your patient account on our clinic
                                      website. Navigate to the appointment
                                      details page and click on the "Join Video
                                      Call" button. This will launch the video
                                      call interface.
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                            <Row>
                              <Col xs={12}>
                                <div className="icon-text">
                                  <div className="icon">
                                    <img className="star" src={vid} alt="bmi" />
                                  </div>
                                  <div className="txt">
                                    <span className="t1">
                                      Join the video call
                                    </span>
                                    <span className="t2">
                                      At the scheduled appointment time, log in
                                      to your patient account on our clinic
                                      website. Navigate to the appointment
                                      details page and click on the "Join Video
                                      Call" button. This will launch the video
                                      call interface.
                                    </span>
                                  </div>
                                </div>
                              </Col>
                            </Row>
                          </div> */}
                        </div>
                      )}
                    </>
                  )}
                </div>
              </Col>
              <Col sm={3} xs={12} className="parient_section">
                <Accordion
                  defaultExpanded={true}
                  className="sidenav"
                >
                  <AccordionSummary expandIcon={<ArrowFillIcon />}>
                    <div className="header">Patients Details</div>

                  </AccordionSummary>
                  <AccordionDetails className="accordial_details">

                    <div className="body">
                      <div className="user_info">
                        {apptDetails.appointmentForId ? (
                          <img className="img" src={dependent} alt="user" />
                        ) : (
                          <img
                            className="img"
                            src={userDetails.pictureUrl}
                            alt="user"
                          />
                        )}
                        <div>
                          <div className="name">{apptDetails.name}</div>
                          <div className="det">
                            {apptDetails.age} Years, <span>{apptDetails.gender}</span>
                          </div>
                        </div>

                      </div>
                      <div className="icon-text ">
                        <div className="icon">
                          <img className="star" src={bmi} alt="bmi" />
                        </div>

                        <div className="txt">
                          <span>Weight</span>
                          {apptDetails.healthRecord?.weight}
                          {apptDetails.healthRecord?.weightUnit}
                        </div>
                      </div>
                      <div className="icon-text">
                        <div className="icon">
                          <img className="star" src={bp} alt="bp" />
                        </div>
                        <div className="txt">
                          <span>Blood Pressure</span>
                          {apptDetails.healthRecord?.bloodPressureSystolic}
                          {"/"}
                          {apptDetails.healthRecord?.bloodPressureDiastolic}
                        </div>
                      </div>
                      <div className="icon-text">
                        <div className="icon">
                          <img className="star" src={temp} alt="temp" />
                        </div>
                        <div className="txt">
                          <span>Body temperature</span>
                          {apptDetails.healthRecord?.temperature}
                          {"  "}
                          {apptDetails.healthRecord?.temperatureUnit === "fh"
                            ? " °F"
                            : ""}
                          {apptDetails.healthRecord?.temperatureUnit === "cl"
                            ? " °C"
                            : ""}
                        </div>
                      </div>
                      <div className="icon-text">
                        <div className="icon">
                          <img className="star" src={pulse} alt="pulse" />
                        </div>
                        <div className="txt">
                          <span>Pulse Rate</span>
                          {apptDetails.healthRecord?.pulseRate} bpm
                        </div>
                      </div>
                      {apptDetails.healthRecord && (
                        <div className="icon-text-dark">
                          <div className="icon">
                            <img className="star" src={exclm} alt="pulse" />
                          </div>
                          <div className="txt">
                            {apptDetails?.healthRecord && (
                              <span>
                                Last updated:{" "}
                                {moment
                                  .utc(apptDetails.healthRecord?.updatedOnUtc)
                                  .local()
                                  .format("hh:mm A, DD MMMM, YY")}
                                .
                              </span>
                            )}
                            {!apptDetails.isPrevious && (
                              <>
                                {" "}
                                Update this information just 2 hours prior to the
                                appointment
                              </>
                            )}
                          </div>
                        </div>
                      )}

                      {!apptDetails.isPrevious && (
                        <AddUserProfile
                          apptId={apptDetails.id}
                          setApptDetails={setApptDetails}
                          apptDetails={apptDetails}
                        />
                      )}
                    </div>

                  </AccordionDetails>
                </Accordion>

                {/* <div className="sidenav">
                  <div className="header">Patients Details</div>
                  {!apptDetails.isPrevious && (
                    <AddUserProfile
                      apptId={apptDetails.id}
                      setApptDetails={setApptDetails}
                      apptDetails={apptDetails}
                    />
                  )}

                  <div className="body">
                    <div className="user_info">
                      {apptDetails.appointmentForId ? (
                        <img className="img" src={dependent} alt="user" />
                      ) : (
                        <img
                          className="img"
                          src={userDetails.pictureUrl}
                          alt="user"
                        />
                      )}
                      {console.log(apptDetails)}
                      <div>
                        <div className="name">{apptDetails.name}</div>
                        <div className="det">
                          {apptDetails.age} Years, <span>{apptDetails.gender}</span>
                        </div>
                      </div>
                    </div>
                    <div className="icon-text ">
                      <div className="icon">
                        <img className="star" src={bmi} alt="bmi" />
                      </div>

                      <div className="txt">
                        <span>Weight</span>
                        {apptDetails.healthRecord?.weight}
                        {apptDetails.healthRecord?.weightUnit}
                      </div>
                    </div>
                    <div className="icon-text">
                      <div className="icon">
                        <img className="star" src={bp} alt="bp" />
                      </div>
                      <div className="txt">
                        <span>Blood Pressure</span>
                        {apptDetails.healthRecord?.bloodPressureSystolic}
                        {"/"}
                        {apptDetails.healthRecord?.bloodPressureDiastolic}
                      </div>
                    </div>
                    <div className="icon-text">
                      <div className="icon">
                        <img className="star" src={temp} alt="temp" />
                      </div>
                      <div className="txt">
                        <span>Body temperature</span>
                        {apptDetails.healthRecord?.temperature}
                        {"  "}
                        {apptDetails.healthRecord?.temperatureUnit === "fh"
                          ? " °F"
                          : ""}
                        {apptDetails.healthRecord?.temperatureUnit === "cl"
                          ? " °C"
                          : ""}
                      </div>
                    </div>
                    <div className="icon-text">
                      <div className="icon">
                        <img className="star" src={pulse} alt="pulse" />
                      </div>
                      <div className="txt">
                        <span>Pulse Rate</span>
                        {apptDetails.healthRecord?.pulseRate} bpm
                      </div>
                    </div>
                    {apptDetails.healthRecord && (
                      <div className="icon-text-dark">
                        <div className="icon">
                          <img className="star" src={exclm} alt="pulse" />
                        </div>
                        <div className="txt">
                          {apptDetails.healthRecord && (
                            <span>
                              Last updated:{" "}
                              {moment
                                .utc(apptDetails.healthRecord?.createdOnUtc)
                                .local()
                                .format("hh:mm A, DD MMMM, YY")}
                              .
                            </span>
                          )}
                          {!apptDetails.isPrevious && (
                            <>
                              {" "}
                              Update this information just 2 hours prior to the
                              appointment
                            </>
                          )}
                        </div>
                      </div>
                    )}
                  </div>
                </div> */}
              </Col>
            </Row>
          </div>
        </div>
      </div >
    )
  );
}

export default AppointmentDetails;
