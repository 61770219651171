// import React from 'react';
// import { Col, Row } from 'react-bootstrap';

import { Box, Skeleton } from "@mui/material";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux"
import { Link } from "react-router-dom";

const HomeSpeciality = () => {

  const specialities = useSelector((state) => state.homepage.specialities)


  return (!specialities.loading ? (
    // specialities.isSuccess && 
    (
      <>
        {specialities.list.specialties.length > 0 && (
          <section className="homeSpeciality">
            <div className="container">
              <div className="headerSec">
                <span className="section-pill section-pill-invert">Our Specialities</span>
                <span className="section-title section-title-primary">
                  Center of Excellence
                </span>
              </div>
              <Row>
                <div className="homeSpeciality-card-parent">
                  <Row className="homeSpecialityRow">
                    {specialities.list.specialties.map((item, index) => {
                      if (index < 4) {
                        return (
                          <Col md={3} xs={6} key={index}>
                            <div
                              className="swc-parent"
                              data-aos-delay="300"
                              data-aos="zoom-out"
                              data-aos-once="true"
                            >
                              <Link className="swc-card" to={`/speciality/${item.slug}`}>
                                <div className="card-img">
                                  <img src={item.iconUrl} alt={item.name} />
                                </div>

                                <div className="card-title">{item.name}</div>
                              </Link>
                            </div>
                          </Col>
                        );
                      } else {
                        return "";
                      }
                    })}
                  </Row>
                </div>
              </Row>
            </div>
          </section>
        )}
      </>
    )
  ) : (
    <section className="skeleton-section">
      <div className="container">
        <Row>
          {[...Array(4)].map((_, index) => (
            <Col md={3} xs={6} className="homeSpeciality-card-parent" key={index}>
              <Box
                className="swc-parent"
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  width: 200,
                  p: 2,
                }}
              >
                <Skeleton
                  variant="circular"
                  width={56}
                  height={56}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="80%"
                  height={30}
                  animation="wave"
                />
                <Skeleton
                  variant="text"
                  width="60%"
                  height={20}
                  animation="wave"
                />
              </Box>
            </Col>
          ))}
        </Row>
      </div>
    </section>
  ));
};

export default HomeSpeciality;