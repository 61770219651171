import React, { useEffect } from "react";
import {
  getTOkenForAgora,
  getTokenForVideoSDK,
} from "../../services/videoCallservice";
import { useState } from "react";
import VideoCallScreen from "./VideoCallScreen";
import {
  PatientEndedCall,
  PatientJoinedVideoCall,
  PatientReJoinedVideoCall,
} from "../../services/apptservice";
import { AnimatePresence, motion } from "framer-motion";
import swal from "sweetalert";

function VideoCallButton(props) {

  const [isDisabled, setIsDisabled] = useState(false)

  const {
    userToken,
    apptId,
    appointmentCallState,
    setUpdateApptDetailsState,
    apptDetails,
  } = props;

  //video call object
  const [tokenResponse, setTokenResponse] = useState({
    apiKey: "",
    id: "",
    roomId: "",
    token: "",
    isLoading: false,
    isSuccess: false,
  });

  //generate TOKEN for AGORA
  const generateToken = () => {
    setIsDisabled(true);
    setTokenResponse({
      ...tokenResponse,
      isLoading: true,
    });
    document.body.style.overflow = "hidden";
    let vcObj = {
      channel: `AppointmentId${apptId}`,
      uid: parseInt(Math.floor(Math.random() * 90000) + 10000),
      expiredTs: 0,
      role: 0,
    };

    getTokenForVideoSDK(apptId, userToken)
      .then((res) => {
        if (res.status === "Success") {
          let tempObj = {
            ...res.response,
            isLoading: false,
            isSuccess: true,
          };

          if (
            appointmentCallState.patientStateStr === "Joined" ||
            appointmentCallState.patientStateStr === "Rejoined"
          ) {
            setTokenResponse(tempObj);
          } else if (appointmentCallState.patientStateStr === "NotInCall") {
            PatientJoinedVideoCall(apptId, userToken)
              .then((res) => {
                setUpdateApptDetailsState(res.response.states);

                setTokenResponse(tempObj);
              })
              .catch((err) => {
                console.log(err);
                swal({
                  icon: "error",
                  title: err,
                });
                document.body.style.overflow = "unset";
                setTokenResponse({
                  apiKey: "",
                  id: "",
                  roomId: "",
                  token: "",
                  isLoading: false,
                  isSuccess: false,
                });
              });
          } else if (appointmentCallState.patientStateStr === "Ended") {
            PatientReJoinedVideoCall(apptId, userToken)
              .then((res) => {
                setTokenResponse(tempObj);
                setUpdateApptDetailsState(res.response.states);
              })
              .catch((err) => {
                console.log(err);
                swal({
                  icon: "error",
                  title: err,
                });
                document.body.style.overflow = "unset";
                setTokenResponse({
                  apiKey: "",
                  id: "",
                  roomId: "",
                  token: "",
                  isLoading: false,
                  isSuccess: false,
                });
              });
          }
        }
      })
      .catch((err) => {
        console.log(err);
        document.body.style.overflow = "unset";
        setTokenResponse({
          apiKey: "",
          id: "",
          roomId: "",
          token: "",
          isLoading: false,
          isSuccess: false,
          isLoading: false,
          isSuccess: false,
        });
      })
      .finally(() => {
        setIsDisabled(false); 
      });
  };

  const onTotalStateChange = () => {
    PatientEndedCall(apptId, userToken)
      .then((res) => {
        if (res.status === "Success") {
          setTokenResponse({
            channel: "",
            uid: "",
            appID: "",
            token: "",
            isLoading: false,
            isSuccess: false,
          });

          setUpdateApptDetailsState(res.response.states);

          document.body.style.overflow = "unset";
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const containerVariats = {
    hidden: {
      opacity: 0,
      y: "100vh",
      width: "0vw",
      x: "150%",
    },
    visible: {
      opacity: 1,
      y: 0,
      x: "0%",
      width: "100vw",
      transition: {
        delay: 0.2,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
    exit: {
      opacity: 0,
      width: "0vw",
      x: "100%",
      y: "100vh",
      transition: {
        delay: 0,
        type: "tween",
        // damping: 15,
        // mass: 0.5,
      },
    },
  };

  return (
    <>
      <button
        disabled={isDisabled}
        className="btnr btnr-primary" onClick={() => generateToken()}>
        Join Videocall
      </button>
      {tokenResponse.isLoading && (
        <div className="vc-loading">
          <div className="loadingio-spinner-ripple-s3tajgoiuyn">
            <div className="ldio-4x6ufyfvti6">
              <div></div>
              <div></div>
            </div>
          </div>
        </div>
      )}
      <AnimatePresence>
        {tokenResponse.isSuccess && (
          <motion.div
            className="vc-screen"
            variants={containerVariats}
            initial="hidden"
            animate="visible"
            exit="exit"
          >
            <VideoCallScreen
              tokenResponse={tokenResponse}
              changeState={() => onTotalStateChange()}
              apptDetails={apptDetails}
            />
          </motion.div>
        )}
      </AnimatePresence>
    </>
  );
}

export default VideoCallButton;
