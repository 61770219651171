import React, { useRef } from "react";
import { Col, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";
import {
  GetAppointmentsById,
} from "../services/apptservice";
import { useState } from "react";
import moment from "moment";

import vc from "../static/appt/vc.svg";
import ip from "../static/appt/ip.svg";
import cal from "../static/userapp/cal.svg";

import { useNavigate, useParams } from "react-router-dom";
import AddReview from "../components/appt/AddReview";
import DetailsAppt from "../components/appt/DetailsAppt";
import DetailsofPatient from "../components/appt/DetailsofPatient";
import file from "../static/upload/file.svg";
import PressAndRecomen from "../components/appt/PressAndRecomen";

const FollowUpDetails = () => {

  const userDetails = useSelector((state) => state.user.userObj.patientData);
  const userToken = useSelector((state) => state.user.userObj.token);

  const navigate = useNavigate();


  const [pdfUrl, setPdfUrl] = useState("");
  const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
  const openPdfViewer = (pdfUrl) => {
    setPdfUrl(pdfUrl);
  };

  useEffect(() => {
    if (pdfUrl.length > 0) {
      setIsPdfViewerOpen(true);
    }
  }, [pdfUrl]);

  const ImageGalleryRef = useRef(null);

  const [isGallVisible, setIsGallVisible] = useState(false);

  const openImageGallery = () => {
    ImageGalleryRef.current.toggleFullScreen();
    setIsGallVisible(true);
  };


  const { apptId } = useParams();

  const [apptDetails, setApptDetails] = useState(null);

  const [images, setImages] = useState([]);

  useEffect(() => {
    if (apptId) {
      GetAppointmentsById(apptId, userToken).then((res) => {
        if (res.isFollowUp) {
          setApptDetails(res);
        } else {
          return navigate("/appointments");
        }
        if (res.recommendationDto) {
          if (res.recommendationDto.attachmentDtos.length > 0) {
            let tempImageArr = res.recommendationDto.attachmentDtos
              .filter(
                (rec) =>
                  rec.attachmentUrl.split(/[#?]/)[0].split(".").pop().trim() !==
                  "pdf"
              )
              .map((rec, index) => {
                let tempObj = {
                  original: rec.attachmentUrl,
                  thumbnail: rec.attachmentUrl,
                };

                return tempObj;
              });

            setImages(tempImageArr);
          }
        }
      });

    } else {
      navigate("/appointments");
    }
  }, []);


  const navigateToUploadReports = (stateforNextPage) => {

    if (stateforNextPage) {
      let tempFilesArray = stateforNextPage?.attachments.map((attach) => {
        let tempAttachment = {
          ...attach,
          isUploading: false,
          isUploaded: true,
        };

        return tempAttachment;
      });

      let modifiedState = {
        ...stateforNextPage,
        attachments: [...tempFilesArray],
      };

      navigate("/uploadreports", { state: modifiedState });
    } else {

      let newState = { id: 0, appointmentId: apptDetails?.id, reasonForVisit: "", attachments: [] };
      
      navigate("/uploadreports", {
        state: newState,
      });
    }

    //check whether user is Logged in ??
  };


  const renderAppointmentContent = () => {
    switch (apptDetails.appointmentType) {
      case "InPerson":
        return (
          <>
            <img src={ip} alt="ip" />
            <span>Clinic Visit</span>
          </>
        );
      case "VideoCall":
        return (
          <>
            <img src={vc} alt="vc" />
            <span>Video Call</span>
          </>
        );
      case "Treatment":
        return (
          <>
            <img
              src={apptDetails.treatment.pictureUrl}
              alt="tpurl"
              className="tpurl"
            />
            <span>Treatment</span>
          </>
        );
      default:
        return null; // Add a default case or handle it based on your requirements
    }
  };

  const navigateToBookAppt = (stateforNextPage) => {
    navigate("/bookappt", { state: stateforNextPage });
  };

  const getType = (type) => {
    if (type === "InPerson") {
      return "IP";
    }
    if (type === "VideoCall") {
      return "vc";
    }
    if (type === "Treatment") {
      return "tp";
    }
  }

  const patientInformationDto = apptDetails?.patientInformationDto

  return (
    apptDetails && (
      <div className="main-body">
        <div className="container">
          <div className="FollowUpDetails-main">
            <Row className="userapptDetails">
              <Col md={3} sm={12} className="apptDetails">

                <DetailsAppt apptDetails={apptDetails} />

              </Col>
              <Col md={6} sm={12} className="apptState">
                <div className="appt-parent">
                  <div className={`appt-state ${getType(apptDetails.appointmentType)}`}>
                    <div className="doc">
                      <div className="doc_info">
                        <div className="img"
                          style={{ backgroundImage: `url(${apptDetails.doctorPictureUrl})` }}
                        ></div>
                        <div className={`doc_name`}>
                          {apptDetails.doctorDto.name}
                          <span>{apptDetails.doctorTitle}</span>
                        </div>

                      </div>
                      <div className="appt_type">
                        {renderAppointmentContent()}
                      </div>
                    </div>
                    <div className="follow_info">
                      <div className="followDate">
                        <img src={cal} alt="" />
                        <div className="follow_text">
                          <span className="title">Follow Up Request</span>
                          <span className="date">
                            {moment
                              .utc(apptDetails.scheduledAtUtc)
                              .local()
                              .format("Do MMM 'YY")}
                          </span>
                        </div>
                        {
                          apptDetails.isFreeFollowUp &&
                          <span className="free">Free</span>
                        }
                      </div>
                      <div className="buttonSec">
                        <button
                          className="btnr btnr-primary"
                          onClick={() =>
                            navigateToBookAppt({
                              doctor: apptDetails.doctorDto,
                              apptType: apptDetails.appointmentType,
                              processObj: apptDetails.treatment,
                              processId: apptDetails.treatmentId,
                              followup: true,
                              apptId: apptDetails.id,
                              isFreeFollowUp: apptDetails.isFreeFollowUp,
                            })
                          }
                        >
                          Book Now
                        </button>
                      </div>
                    </div>
                  </div>

                  <PressAndRecomen apptDetails={apptDetails} images={images} setImages={setImages} />


                  {apptDetails && apptDetails.statesDto?.canAddReview && (
                    <AddReview
                      userId={userDetails.id}
                      userToken={userToken}
                      docId={apptDetails?.doctorDto?.id}
                      apptId={apptDetails?.id}
                      patientRatingDto={apptDetails?.patientRatingDto}
                      apptDetails={apptDetails}
                      setApptDetails={setApptDetails}
                    />
                  )}
                </div>
              </Col>
              <Col md={3} sm={12} className="patientDetails">
                <DetailsofPatient apptDetails={apptDetails} setApptDetails={setApptDetails} />

                {
                  apptDetails?.patientInformationDto?.attachments.length > 0 &&
                  <div className="rov-parent">
                    <div className="rov">Reason of visit</div>
                    <div className="para">
                      {apptDetails?.patientInformationDto?.reasonForVisit}
                    </div>
                    <div className="files">
                      <div className="file_text">
                        <img src={file} alt="file" />
                        <span className="counter">
                          {apptDetails?.patientInformationDto?.attachments.length}
                        </span>
                        <span className="sub">
                          File{apptDetails?.patientInformationDto?.attachments.length > 1 ? "s" : ""}
                        </span>
                      </div>
                      <div className="d-flex justify-content-center">
                        <button
                          className="btnr btnr-primary-outline btnr-hover btnr-sm"
                          onClick={() =>
                            navigateToUploadReports({
                              ...patientInformationDto,
                            })
                          }
                        >
                          View All <span className="arr"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                }

              </Col>
            </Row>
          </div>
        </div>
      </div >
    )
  );
};

export default FollowUpDetails;