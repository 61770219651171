import React from "react";
import { Col, Row } from "react-bootstrap";

import star from "../static/doctor/star.svg";
import nop from "../static/doctor/nop.svg";
import exp from "../static/doctor/exp.svg";
import aoe from "../static/doctor/aoe.svg";
import aoeicn from "../static/doctor/aoeicn.svg";
import lang from "../static/doctor/lang.svg";
import certh from "../static/doctor/certh.svg";
import cert from "../static/doctor/cert.svg";
import loc from "../static/doctor/location.svg";
import rev from "../static/doctor/rev.svg";
import gal from "../static/doctor/gal.svg";
import da from "../static/doctor/da.svg";
import { useState, useEffect } from "react";
import ImageGallery from "react-image-gallery";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import { useLocation, useNavigate, useParams } from "react-router-dom";
import { GetDoctorsDetails } from "../services/doctorservice";
import ApptType from "../components/appt/ApptType";
import { useDispatch, useSelector } from "react-redux";
import {
  updateDeepLinking,
  updateLoginDrawerState,
} from "../redux/webAppSlice";
import { Accordion, AccordionDetails, AccordionSummary, Skeleton } from "@mui/material";
import moment from "moment";
import { useRef } from "react";
import Gallery from "../components/microcomponenets/Gallery";
import emptyExp from "../static/doctorProfile/exp.png";
import emptyImg from "../static/doctorProfile/Doc.png";
import ExpandMoreIcon from "../components/Icons/ExpandMoreIcon";

function DoctorProfile() {
  const location = useLocation();
  const navigate = useNavigate();

  const { id } = useParams();
  //const docdata = location.state?.data;

  const [doctorObj, setDoctorObj] = useState(null);

  // console.log(doctorObj)

  useEffect(() => {
    if (id) {
      GetDoctorsDetails(id)
        .then((res) => {
          setDoctorObj(res.doctorDetails);
          // console.log(res.doctorDetails);

          if (res.doctorDetails.inPersonConsultationFee) {
            setApptType("InPerson");
          } else if (res.doctorDetails.videoCallConsultationFee) {
            setApptType("VideoCall");
          }

          let tempImages = res.doctorDetails.doctorGalleryImages.map(
            (imge, index) => {
              return {
                original: imge.pictureUrl,
                thumbnail: imge.pictureUrl,
              };
            }
          );

          setImages(tempImages);
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      navigate("/");
    }
  }, [id]);

  const ratingGenerator = (rating) => {
    let content = [];
    for (let i = 0; i < rating; i++) {
      content.push(<img src={star} alt="start" key={i} />);
    }
    return content;
  };

  const [slotListType, setSlotListType] = useState("vc");

  const [apptType, setApptType] = useState("");

  //appt type change
  const onApptTypeChange = (selectedType) => {
    setApptType(selectedType);
  };

  //currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const userDetails = useSelector((state) => state.user.userObj);

  const navigateToBookAppt = (stateforNextPage) => {
    if (userDetails !== null) {
      navigate("/bookappt", { state: stateforNextPage });
    } else if (userDetails === null) {
      let tempObj = {
        toLink: "/bookappt",
        state: {
          doctor: stateforNextPage.doctor,
          apptType: stateforNextPage.apptType,
        },
      };
      openLoginDrawer(tempObj);
    }

    //check whether user is Logged in ??
  };

  //openLogin drwaer
  const dispatch = useDispatch();
  const openLoginDrawer = (tempObj) => {
    dispatch(updateLoginDrawerState(true));

    dispatch(updateDeepLinking(tempObj));
  };

  const getColor = (index) => {
    let color = "";
    if (index % 4 === 0) {
      color = "blue";
    } else if (index % 4 === 1) {
      color = "green";
    } else if (index % 4 === 2) {
      color = "yellow";
    } else if (index % 4 === 3) {
      color = "red";
    }
    return color;
  };

  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);

  //image gallery ref
  const ImageGalleryRef = useRef(null);

  const [isGallVisible, setIsGallVisible] = useState(false);

  const openImageGallery = () => {
    ImageGalleryRef.current.toggleFullScreen();
    setIsGallVisible(true);
  };

  const [images, setImages] = useState([]);

  const onScreenChange = (isFullScreen) => {
    if (!isFullScreen) {
      // Full screen mode exited
      setIsGallVisible(false);
    }
  };

  const [showFullText, setShowFullText] = useState(false);

  const toggleText = () => {
    setShowFullText(!showFullText);
  };

  const about = doctorObj?.about?.split(" ");

  const getPlan = () => {
    let plan = "Basic";
    if (clinicDetails?.subscriptionPackage) {
      plan = clinicDetails?.subscriptionPackage
    } else {
      return plan = undefined;
    }
    return plan;
  }


  return doctorObj ? (
    <div className="main-body">
      <div className="container">
        <div className="doc-pro-grid">
          <div className="dp-left">
            <div className="img">
              <img src={doctorObj?.pictureUrl ? doctorObj?.pictureUrl : emptyImg} className="pimg" alt="as" />
              <div>
                <Row>
                  <Col xs={12} className="name">
                    {doctorObj?.name}
                  </Col>
                  <Col xs={12} className="spec">
                    {doctorObj?.title}
                  </Col>
                  {about && (
                    <>
                      <Col xs={12} className="name d-none">
                        About
                      </Col>
                      <Col xs={12} className="desc">
                        <div className="about_doctor">
                          {showFullText
                            ? about.join(" ")
                            : about.length > 35
                              ? about.slice(0, 35).join(" ")
                              : about.join(" ")}
                          {about.length > 35 && (
                            <span onClick={toggleText}>
                              {showFullText
                                ? "...see less"
                                : "...Continue reading"}
                            </span>
                          )}
                        </div>
                      </Col>
                    </>
                  )}

                  <Col xs={12}>
                    <Row className="w-100">
                      {
                        (doctorObj?.isReviewActive && doctorObj?.rating) > 0 &&
                        <Col
                          sm={6}
                          xs={12}
                          className="d-flex align-items-center doc-stats"
                        >
                          <div className="doc-nav-img">
                            <img src={star} alt="ico" />
                          </div>

                          <div className="flex-grow-1 ms-3">
                            <span className="doc-stats-tit">Rating</span>
                            <span className="doc-stats-txt">
                              {doctorObj?.rating} out of 5
                            </span>
                          </div>
                        </Col>
                      }

                      <Col
                        sm={6}
                        xs={12}
                        className="d-flex align-items-center doc-stats"
                      >
                        <div className="doc-nav-img">
                          <img src={exp} alt="ico" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <span className="doc-stats-tit">Experience</span>
                          <span className="doc-stats-txt">
                            {doctorObj?.experience}+ years
                          </span>
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        xs={12}
                        className="d-flex align-items-center doc-stats"
                      >
                        <div className="doc-nav-img">
                          <img src={nop} alt="ico" />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <span className="doc-stats-tit">Patient</span>
                          <span className="doc-stats-txt">
                            {doctorObj?.patientsServed}+
                          </span>
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  {doctorObj?.languageDtos && (
                    <>
                      {
                        <Col xs={12}>
                          <div className="language dp-card">
                            <div className="gen-title">
                              <div className="doc-nav-img">
                                <img src={lang} alt="ico" />
                              </div>
                              <span>Language spoken</span>
                            </div>
                            <div className="langs">
                              {doctorObj?.languageDtos.map((lang, index) => (
                                <div key={index}>
                                  <img
                                    src={
                                      process.env.REACT_APP_API + lang.flagUrl
                                    }
                                    alt={lang.name}
                                  />
                                  {lang.name}
                                </div>
                              ))}
                            </div>
                          </div>
                        </Col>
                      }
                    </>
                  )}
                </Row>
              </div>
            </div>



            {
              getPlan() !== "Basic" && (
                (clinicDetails.hasVideoCallAppointment || clinicDetails.hasInPersonAppointment) && (
                  <div className="appttype dp-card">
                    <ApptType
                      apptType={apptType}
                      outPutFn={(selectedType) => {
                        onApptTypeChange(selectedType);
                      }}
                      inPersone={doctorObj?.hasInPersonAppointmentEnabled}
                      videoCall={doctorObj?.hasVideoCallAppointmentEnabled}
                    />
                    {
                      (doctorObj?.hasInPersonAppointmentEnabled || doctorObj?.hasVideoCallAppointmentEnabled) &&


                      <button
                        onClick={() =>
                          navigateToBookAppt({
                            doctor: doctorObj,
                            apptType: apptType,
                          })
                        }
                        className="btnr btnr-primary btnr-hover"
                      >
                        Book consultation{" "}
                        {apptType === "InPerson" && (
                          <>
                            {" "}
                            at{" "}
                            <span className="yellow">
                              {" "}
                              {currencySymbol}
                              {doctorObj?.inPersonConsultationFee}{" "}
                            </span>
                          </>
                        )}{" "}
                        {apptType === "VideoCall" && (
                          <>
                            {" "}
                            at{" "}
                            <span className="yellow">
                              {" "}
                              {currencySymbol}
                              {doctorObj?.videoCallConsultationFee}{" "}
                            </span>
                          </>
                        )}{" "}
                        <span className="arr"></span>{" "}
                      </button>
                    }
                  </div>
                )
              )
            }

          </div>
          <div className="dp-right">
            <div className="descript dp-card">
              <Row>
                <Col xs={12} className="name">
                  About
                </Col>
                <Col xs={12} className="desc">
                  {doctorObj?.about}
                </Col>
              </Row>
            </div>

            {doctorObj?.areasOfExpertiseDtos && (
              <>
                {doctorObj?.areasOfExpertiseDtos.length > 0 && (
                  <div className="aoe dp-card">
                    <div className="gen-title ">
                      <div className="doc-nav-img">
                        <img src={aoe} alt="ico" />
                      </div>
                      <span>Areas of Expertise</span>
                    </div>
                    <div className="d-tags">
                      {doctorObj?.areasOfExpertiseDtos.map((tag, index) => (
                        <div
                          key={index}
                          className={`tag tag-${getColor(index)}`}
                        >
                          <img src={aoeicn} alt="asd" />
                          {tag.value}
                        </div>
                      ))}
                    </div>
                    {/* <Accordion className="acodian d-sm-none">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div className="gen-title">
                          <div className="doc-nav-img">
                            <img src={aoe} alt="ico" />
                          </div>
                          <span>Areas of Expertise</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="d-tags">
                          {doctorObj?.areasOfExpertiseDtos.map((tag, index) => (
                            <div
                              key={index}
                              className={`tag tag-${getColor(index)}`}
                            >
                              <img src={aoeicn} alt="asd" />
                              {tag.value}
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion> */}
                  </div>
                )}
              </>
            )}

            {doctorObj?.certification && (
              <>
                {doctorObj?.certification.length > 0 && (
                  <div className="certs dp-card">
                    <div className="gen-title d-none d-sm-flex">
                      <div className="doc-nav-img">
                        <img src={certh} alt="ico" />
                      </div>
                      <span>Certifications</span>
                    </div>
                    <div className="list d-none d-sm-block">
                      {doctorObj?.certification.map((certificate, index) => (
                        <div key={index}>
                          <img src={cert} alt={certificate} />
                          {certificate}
                        </div>
                      ))}
                    </div>
                    <Accordion className="acodian d-sm-none">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div className="gen-title">
                          <div className="doc-nav-img">
                            <img src={certh} alt="ico" />
                          </div>
                          <span>Certifications</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="list">
                          {doctorObj?.certification.map((certificate, index) => (
                            <div key={index}>
                              <img src={cert} alt={certificate} />
                              {certificate}
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
              </>
            )}
            {doctorObj?.experiences && (
              <>
                {doctorObj?.experiences.length > 0 && (
                  <>
                    <div className="expe dp-card">
                      <div className="gen-title d-none d-sm-flex">
                        <div className="doc-nav-img">
                          <img src={exp} alt="ico" />
                        </div>
                        <span>Experience</span>
                      </div>
                      <div className="list d-none d-sm-block">
                        {doctorObj?.experiences.map((exp, index) => (
                          <Row key={exp.id}>
                            <Col xs={12}>
                              <span className="exp-timestamp">
                                {exp.experienceStart} - {exp.experienceEnd}
                              </span>
                              <span className="exp-location">
                                {exp.location && (
                                  <>
                                    <img src={loc} alt="loc" />
                                    {exp.location}
                                  </>
                                )}
                              </span>
                            </Col>

                            <Col xs={12}>
                              <Row>
                                <Col sm={2} xs={3} className="exp-img">
                                  <img src={exp.logoUrl ? exp.logoUrl : emptyExp} alt="img" />
                                </Col>
                                <Col sm={10} xs={9} className="exp-desc">
                                  {exp.title}
                                  <span>{exp.organization}</span>
                                </Col>
                              </Row>
                            </Col>
                          </Row>
                        ))}
                      </div>

                      <Accordion className="acodian d-sm-none">
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1-content"
                          id="panel1-header"
                        >
                          <div className="gen-title">
                            <div className="doc-nav-img">
                              <img src={exp} alt="ico" />
                            </div>
                            <span>Experience</span>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className="list">
                            {doctorObj?.experiences.map((exp, index) => (
                              <Row key={exp.id}>
                                <Col xs={12}>
                                  <span className="exp-timestamp">
                                    {exp.experienceStart} - {exp.experienceEnd}
                                  </span>
                                  <span className="exp-location">
                                    {exp.location && (
                                      <>
                                        <img src={loc} alt="loc" />
                                        {exp.location}
                                      </>
                                    )}
                                  </span>
                                </Col>

                                <Col xs={12}>
                                  <Row>
                                    <Col sm={2} xs={3} className="exp-img">
                                      <img src={exp.logoUrl ? exp.logoUrl : emptyExp} alt="img" />
                                    </Col>
                                    <Col sm={10} xs={9} className="exp-desc">
                                      {exp.title}
                                      <span>{exp.organization}</span>
                                    </Col>
                                  </Row>
                                </Col>
                              </Row>
                            ))}
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    </div>

                  </>

                )}
              </>
            )}
            {doctorObj?.educations && (
              <>
                {doctorObj?.educations.length > 0 && (
                  <div className="educatio dp-card">
                    <div className="gen-title d-none d-sm-flex">
                      <div className="doc-nav-img">
                        <img src={exp} alt="ico" />
                      </div>
                      <span>Education</span>
                    </div>
                    <div className="list d-none d-sm-block">
                      {doctorObj?.educations.map((edu, index) => (
                        <Row key={exp.id}>
                          <Col
                            sm={2}
                            xs={3}
                            className="d-flex align-items-center year"
                          >
                            {index ===
                              doctorObj?.educations.length - 1 ? null : index %
                                2 ===
                                0 ? (
                              <span className="line left"></span>
                            ) : (
                              <span className="line right"></span>
                            )}

                            <span className="edu-img">{edu.yearCompleted}</span>
                          </Col>
                          <Col sm={10} xs={9} className="exp-desc">
                            {edu.institution}
                            <span>{edu.course}</span>
                          </Col>
                        </Row>
                      ))}
                    </div>

                    <Accordion className="acodian d-block d-sm-none">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div className="gen-title">
                          <div className="doc-nav-img">
                            <img src={exp} alt="ico" />
                          </div>
                          <span>Education</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="list">
                          {doctorObj?.educations.map((edu, index) => (
                            <Row key={exp.id}>
                              <Col
                                sm={2}
                                xs={3}
                                className="d-flex align-items-center year"
                              >
                                {index ===
                                  doctorObj?.educations.length - 1 ? null : index %
                                    2 ===
                                    0 ? (
                                  <span className="line left"></span>
                                ) : (
                                  <span className="line right"></span>
                                )}

                                <span className="edu-img">{edu.yearCompleted}</span>
                              </Col>
                              <Col sm={10} xs={9} className="exp-desc">
                                {edu.institution}
                                <span>{edu.course}</span>
                              </Col>
                            </Row>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>
                  </div>
                )}
              </>
            )}
            {(getPlan() !== "Basic" && doctorObj?.patientRatingDtos) && (
              <>
                {doctorObj?.patientRatingDtos.length > 0 && (
                  <div className="patientRevw dp-card">
                    <div className="gen-title d-none d-sm-flex">
                      <div className="doc-nav-img">
                        <img src={rev} alt="ico" />
                      </div>
                      <span>Patients Review</span>
                    </div>
                    <div className="list scroll-h d-none d-sm-block">
                      {doctorObj?.patientRatingDtos.map((review, index) => (
                        <div className="d-flex  rv-parent" key={review.id}>
                          <div className=" r-img">
                            <img src={review.patientPictureUrl} alt="img" />
                          </div>
                          <div className="d-flex flex-column">
                            <span className="r-name">{review.patientName}</span>
                            <div className="rating">
                              {ratingGenerator(review.rating)}
                            </div>
                            <span className="r-rev">{review.review}</span>
                            <span></span>
                          </div>

                          <div className="rv-time">
                            {" "}
                            {moment(review.createdOnUtc)
                              .local()
                              .format("DD MM YYYY, hh:mm, A")}
                          </div>
                        </div>
                      ))}
                    </div>

                    <Accordion className="acodian d-sm-none">
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1-content"
                        id="panel1-header"
                      >
                        <div className="gen-title">
                          <div className="doc-nav-img">
                            <img src={rev} alt="ico" />
                          </div>
                          <span>Patients Review</span>
                        </div>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className="list scroll-h">
                          {doctorObj?.patientRatingDtos.map((review, index) => (
                            <div className="d-flex  rv-parent" key={review.id}>
                              <div className=" r-img">
                                <img src={review.patientPictureUrl} alt="img" />
                              </div>
                              <div className="d-flex flex-column">
                                <span className="r-name">{review.patientName}</span>
                                <div className="rating">
                                  {ratingGenerator(review.rating)}
                                </div>
                                <span className="r-rev">{review.review}</span>
                                <span></span>
                              </div>

                              <div className="rv-time">
                                {" "}
                                {moment(review.createdOnUtc)
                                  .local()
                                  .format("DD MM YYYY, hh:mm, A")}
                              </div>
                            </div>
                          ))}
                        </div>
                      </AccordionDetails>
                    </Accordion>

                  </div>
                )}
              </>
            )}

            {doctorObj?.doctorGalleryImages && (
              <>
                {" "}
                {doctorObj?.doctorGalleryImages.length > 0 && (
                  <div className="gall dp-card">
                    <div className="gen-title">
                      <div className="doc-nav-img">
                        <img src={gal} alt="ico" />
                      </div>
                      <span>Gallery</span>
                    </div>
                    <div className="listf ">
                      <Gallery mainIMages={doctorObj?.doctorGalleryImages} />
                    </div>
                  </div>
                )}
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <div className="main-body">
      <div className="container">
        <div className="doc-pro-grid">
          <div className="dp-left">
            <div className="img">
              <Skeleton variant="rect" height={200} />
              <div>
                <Row>
                  <Col xs={12} className="name">
                    <Skeleton variant="text" height={50} />
                  </Col>
                  <Col xs={12} className="spec">
                    <Skeleton variant="text" />
                  </Col>
                  <Col xs={12} className="name">
                    <Skeleton variant="text" width={100} height={35} />
                  </Col>
                  <Col xs={12} className="desc">
                    <Skeleton variant="text" />
                  </Col>
                  <Col xs={12}>
                    <Row className="w-100">
                      <Col
                        sm={6}
                        xs={12}
                        className="d-flex align-items-center doc-stats"
                      >
                        <div className="doc-nav-img">
                          <Skeleton variant="rect" width={40} height={40} />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <Skeleton variant="text" width={100} />

                          <Skeleton variant="text" />
                        </div>
                      </Col>
                      <Col
                        sm={6}
                        xs={12}
                        className="d-flex align-items-center doc-stats"
                      >
                        <div className="doc-nav-img">
                          <Skeleton variant="rect" width={40} height={40} />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <Skeleton variant="text" width={100} />

                          <Skeleton variant="text" />
                        </div>
                      </Col>
                      <Col
                        sm={12}
                        xs={12}
                        className="d-flex align-items-center doc-stats"
                      >
                        <div className="doc-nav-img">
                          <Skeleton variant="rect" width={40} height={40} />
                        </div>

                        <div className="flex-grow-1 ms-3">
                          <span className="doc-stats-tit">
                            <Skeleton variant="text" width={100} />
                          </span>

                          <Skeleton variant="text" />
                        </div>
                      </Col>
                    </Row>
                  </Col>
                  <Col xs={12}>
                    <div className="language dp-card">
                      <div className="gen-title">
                        <div className="doc-nav-img">
                          <Skeleton variant="rect" width={40} height={40} />
                        </div>
                        <Skeleton variant="text" width={150} />
                      </div>
                      <div className="">
                        <Skeleton variant="text" width="100%" />
                        <Skeleton variant="text" width="100%" />
                      </div>
                    </div>
                  </Col>
                </Row>
              </div>
            </div>
            <div className="appttype dp-card">
              <button className="btnr btnr-primary skeleton-text"></button>
            </div>
          </div>
          <div className="dp-right">
            <div className="descript dp-card">
              <Row>
                <Col xs={12} className="name">
                  <Skeleton variant="text" width={150} height={60} />
                </Col>
                <Col xs={12} className="">
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                  <Skeleton variant="text" width="100%" />
                </Col>
              </Row>

              <Skeleton variant="text" width={200} height={30} />
            </div>
            <div className="aoe dp-card">
              <div className="gen-title">
                <div className="doc-nav-img">
                  <Skeleton variant="rect" width={40} height={40} />
                </div>
                <Skeleton variant="text" width={200} height={40} />
              </div>

              <div className="d-tags">
                <Skeleton variant="text" width={200} height={30} />
                <Skeleton variant="text" width={200} height={30} />
                <Skeleton variant="text" width={200} height={30} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DoctorProfile;
