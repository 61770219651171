import React, { useRef } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import { useSelector } from "react-redux";
import { useEffect } from "react";

import { useState } from "react";
import moment from "moment";

import vc from "../../static/appt/vc.svg";
import ip from "../../static/appt/ip.svg";

import cal from "../../static/userapp/cal.svg";

import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import PDFView from "../../components/microcomponenets/PDFView";
import AddReview from "../../components/appt/AddReview";

import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from "@mui/material";
import DetailsAppt from "../../components/appt/DetailsAppt";
import DetailsofPatient from "../../components/appt/DetailsofPatient";
import press from "../../static/userapp/press.svg";
import reco from "../../static/userapp/recomandation.svg";
import ImageGallery from "react-image-gallery";
import pdf from "../../static/appt/pdf.svg";
import { Document, Page } from 'react-pdf';
import file from "../../static/upload/file.svg";

const PAGE_SIZE = 2;
const APP_OBJ = {
    doctorId: 0,
    pageNo: 1,
    healthCenterId: 0,
    pageSize: PAGE_SIZE,
    imageSize: 0,
    patientId: 2,
    type: "",
    appointmentType: "",
};

const PressAndRecomen = ({ apptDetails, images, setImages }) => {

    const [pdfUrl, setPdfUrl] = useState("");
    const [isPdfViewerOpen, setIsPdfViewerOpen] = useState(false);
    const openPdfViewer = (pdfUrl) => {
        setPdfUrl(pdfUrl);
    };

    useEffect(() => {
        if (pdfUrl.length > 0) {
            setIsPdfViewerOpen(true);
        }
    }, [pdfUrl]);

    const ImageGalleryRef = useRef(null);

    const [isGallVisible, setIsGallVisible] = useState(false);

    const openImageGallery = () => {
        ImageGalleryRef.current.toggleFullScreen();
        setIsGallVisible(true);
    };

    const onScreenChange = (isFullScreen) => {
        if (!isFullScreen) {
            // Full screen mode exited
            setIsGallVisible(false);
        }
    };

    return (

        <>
            {apptDetails.isPrevious && (
                <>
                    {apptDetails.recommendationDto && (
                        <>
                            {apptDetails.recommendationDto.attachmentDtos.filter(
                                (rec) =>
                                    rec.attachmentUrl
                                        .split(/[#?]/)[0]
                                        .split(".")
                                        .pop()
                                        .trim() !== "pdf"
                            ).length > 0 && (
                                    <Row className="prescription">

                                        <div className="header">
                                            <img src={press} alt="" />
                                            Shared prescription
                                        </div>

                                        <Row
                                            className="images"
                                        >
                                            {apptDetails.recommendationDto.attachmentDtos
                                                .filter(
                                                    (rec) =>
                                                        rec.attachmentUrl
                                                            .split(/[#?]/)[0]
                                                            .split(".")
                                                            .pop()
                                                            .trim() !== "pdf"
                                                )
                                                .map((rec, index) => (
                                                    <Col md={4} sm={2}
                                                        key={index}
                                                        onClick={() => openImageGallery()}

                                                    >
                                                        <div className="img">
                                                            <img
                                                                src={rec.attachmentUrl}
                                                                alt="as"
                                                            />
                                                        </div>
                                                    </Col>
                                                ))}
                                        </Row>
                                        <Col
                                            xs={12}
                                            className={`${!isGallVisible ? "img-gal" : ""
                                                }`}
                                        >
                                            <ImageGallery
                                                ref={ImageGalleryRef}
                                                items={images}
                                                onScreenChange={onScreenChange}
                                            />
                                        </Col>
                                    </Row>
                                )}

                            {apptDetails.recommendationDto.attachmentDtos.filter(
                                (rec) =>
                                    rec.attachmentUrl
                                        .split(/[#?]/)[0]
                                        .split(".")
                                        .pop()
                                        .trim() === "pdf"
                            ).length > 0 && (
                                    <Row className="prescription">
                                        <div className="head header">
                                            <img src={press} alt="" />
                                            Shared prescription PDF
                                        </div>
                                        <Row
                                            className="images"
                                        >
                                            {apptDetails.recommendationDto.attachmentDtos
                                                .filter(
                                                    (rec) =>
                                                        rec.attachmentUrl
                                                            .split(/[#?]/)[0]
                                                            .split(".")
                                                            .pop()
                                                            .trim() === "pdf"
                                                )
                                                .map((rec, index) => (
                                                    <Col sm={6} md={4}>
                                                        <a
                                                            key={index}
                                                            // onClick={() => openPdfViewer(rec.attachmentUrl)}
                                                            className="img"
                                                            href={rec.attachmentUrl}
                                                            target="_blank"
                                                            rel="noreferrer"
                                                        >
                                                            <img src={pdf} alt="as" />
                                                        </a>
                                                    </Col>
                                                ))}
                                        </Row>
                                        {isPdfViewerOpen && (
                                            <div
                                                className={`pdfViewer ${isPdfViewerOpen ? "open" : ""
                                                    }`}
                                            >
                                                <PDFView pdfURL={pdfUrl} />
                                            </div>
                                        )}
                                    </Row>
                                )}

                            {
                                apptDetails.recommendationDto
                                    .messageBody !== "<p><br></p>" &&
                                (
                                    <div className="recomandation">
                                        <div xs={12} sm={12} className="header">
                                            <img src={reco} alt="" />
                                            <div className="head">Given Recommendation</div>
                                        </div>
                                        <div
                                            className="rec_text"
                                            dangerouslySetInnerHTML={{
                                                __html:
                                                    apptDetails.recommendationDto
                                                        .messageBody,
                                            }}
                                        ></div>
                                    </div>
                                )
                            }

                        </>
                    )}
                </>
            )}
        </>
    );
};

export default PressAndRecomen;