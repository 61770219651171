import React from 'react';
import { selectDoctor, selectPatient } from './commonUtils';
import user from "../../../static/profile/familymember/user.svg";

const ApptDrop = ({ dropActive, patientData, setPatientData }) => {

    const { patients, selected } = patientData

    const handleClick = (id) => {
        selectPatient(id, patients, setPatientData)
    };

    return (
        <div className={`drop_list ${dropActive ? "active" : ""}`}>
            {patients?.map((patients, index) => (
                patients?.id !== selected?.id &&
                (
                    <div className='single_doc' key={index} onClick={() => handleClick(patients.id)}>
                        <div className="img">
                            <img src={patients?.pictureUrl ? patients?.pictureUrl : user} alt="" />
                        </div>
                        <div>
                            <span className='name'>{patients?.name}</span>
                            <span className='specialist'>{patients?.title}</span>
                            <span>
                                {patients?.relation ? patients?.relation : "Myself"}
                            </span>
                        </div>
                    </div>
                )
            ))}
        </div>
    );
};

export default ApptDrop;
