import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { GetBanners } from "../services/bannerservice";
import { GetAllTreatments } from "../services/treatmentservice";
import { GetDoctors } from "../services/doctorservice";
import { GetService } from "../services/clinicservice";
import { GetTestimonialsApi } from "../services/promotionservice";
import { GetPatientNotificationWithPaginationAPI } from "../services/notiffservice";
import { GetAllSpecialties } from "../services/specialityservice";

export const getBannersRedux = createAsyncThunk(
  "clinic/getBannersRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetBanners();

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getTreatmentsRedux = createAsyncThunk(
  "clinic/getTreatmentsRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetAllTreatments("", 1, 20);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getSpecialitiesRedux = createAsyncThunk(
  "clinic/getSpecialitiesRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetAllSpecialties();

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);



export const getDoctorsRedux = createAsyncThunk(
  "clinic/getDoctorsRedux",
  async (payload, thunkAPI) => {
    let tempObj = {
      pageIndex: 1,
      pageSize: 10,
      healthCenterId: 0,
      patientId: 0,
      imageSize: 0,
      type: "",
    };
    try {
      let response = await GetDoctors(...Object.values(tempObj));

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);
export const getServiceRedux = createAsyncThunk(
  "clinic/getServiceRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetService();

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getTestimonialsRedux = createAsyncThunk(
  "clinic/getTestimonialsRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetTestimonialsApi(10, 80);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

export const getNotificationsRedux = createAsyncThunk(
  "clinic/getNotificationsRedux",
  async (payload, thunkAPI) => {
    try {
      let response = await GetPatientNotificationWithPaginationAPI(payload);

      return response;
    } catch (err) {
      if (!err.response) {
        throw err;
      }
      return thunkAPI.rejectWithValue(err.response);
    }
  }
);

const initialState = {
  bannersHome: {
    bannersList: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  specialtiesHome: {
    list: ["hola"],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  ourDoctorsHome: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  healthIssuesHome: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  treatments: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  specialities: {
    list: {
      specialties: [],
      count: 0,
    },
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  services: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  testimonials: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  notifications: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  latestAppointments: {
    list: [],
    isSuccess: false,
    wasInit: false,
    loading: false,
    message: "",
  },
  isHomeLoaded: false,
};

export const homePageSlice = createSlice({
  name: "homepage",
  initialState,
  reducers: {},

  extraReducers: (builder) => {
    builder

      //redux for banners
      .addCase(getBannersRedux.pending, (state, action) => {
        state.bannersHome.loading = true;
      })
      .addCase(getBannersRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.bannersHome.bannersList = payload.data;
        state.bannersHome.loading = false;
        state.bannersHome.isSuccess = true;
      })
      .addCase(getBannersRedux.rejected, (state, action) => {
        const { payload } = action;
        state.bannersHome.message = payload;

        state.bannersHome.loading = false;
        state.bannersHome.isSuccess = false;
      })

      //redux for treatments
      .addCase(getTreatmentsRedux.pending, (state, action) => {
        state.treatments.loading = true;
      })
      .addCase(getTreatmentsRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.treatments.list = payload;
        state.treatments.loading = false;
        state.treatments.isSuccess = true;
      })
      .addCase(getTreatmentsRedux.rejected, (state, action) => {
        const { payload } = action;
        state.treatments.message = payload;

        state.treatments.loading = false;
        state.treatments.isSuccess = false;
      })

      //redux for specialities
      .addCase(getSpecialitiesRedux.pending, (state, action) => {
        state.specialities.loading = true;
      })
      .addCase(getSpecialitiesRedux.fulfilled, (state, action) => {
        const { payload } = action;
        // console.log(payload)
        state.specialities.list = payload;
        state.specialities.loading = false;
        state.specialities.isSuccess = true;
      })
      .addCase(getSpecialitiesRedux.rejected, (state, action) => {
        const { payload } = action;
        state.specialities.message = payload;

        state.specialities.loading = false;
        state.specialities.isSuccess = false;
      })

      //redux for doctors
      .addCase(getDoctorsRedux.pending, (state, action) => {
        state.ourDoctorsHome.loading = true;
      })
      .addCase(getDoctorsRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.ourDoctorsHome.list = payload.doctors;
        state.ourDoctorsHome.loading = false;
        state.ourDoctorsHome.isSuccess = true;
      })
      .addCase(getDoctorsRedux.rejected, (state, action) => {
        const { payload } = action;
        state.ourDoctorsHome.message = payload;
        state.ourDoctorsHome.loading = false;
        state.ourDoctorsHome.isSuccess = false;
      })

      //redux for services
      .addCase(getServiceRedux.pending, (state, action) => {
        state.services.loading = true;
      })
      .addCase(getServiceRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.services.list = payload;
        state.services.loading = false;
        state.services.isSuccess = true;
      })
      .addCase(getServiceRedux.rejected, (state, action) => {
        const { payload } = action;
        state.services.message = payload;
        state.services.loading = false;
        state.services.isSuccess = false;
      })

      //redux for testimonials
      .addCase(getTestimonialsRedux.pending, (state, action) => {
        state.testimonials.loading = true;
      })
      .addCase(getTestimonialsRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.testimonials.list = payload.testimonials;
        state.testimonials.loading = false;
        state.testimonials.isSuccess = true;
      })
      .addCase(getTestimonialsRedux.rejected, (state, action) => {
        const { payload } = action;
        state.testimonials.message = payload;
        state.testimonials.loading = false;
        state.testimonials.isSuccess = false;
      })

      //redux for notifications
      .addCase(getNotificationsRedux.pending, (state, action) => {
        state.notifications.loading = true;
      })
      .addCase(getNotificationsRedux.fulfilled, (state, action) => {
        const { payload } = action;
        state.notifications.list = payload.data;
        state.notifications.loading = false;
        state.notifications.isSuccess = true;
      })
      .addCase(getNotificationsRedux.rejected, (state, action) => {
        const { payload } = action;
        state.notifications.message = payload;
        state.notifications.loading = false;
        state.notifications.isSuccess = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { } = homePageSlice.actions;

export default homePageSlice.reducer;
