import { Link } from "react-router-dom";
import logo from "../static/header/logo.svg";
import { useSelector } from "react-redux";
import loc from "../static/clinic/loc.svg";
import phone from "../static/clinic/phone.svg";
import mail from "../static/clinic/mail.svg";

function Footer() {
  //check for clinic Details
  const defaultHealthCenter = useSelector(
    (state) => state.clinic.clincObj.defaultHealthCenter
  );
  const contactINFO = useSelector((state) => state.clinic.clinincInfo);
  const topics = useSelector((state) => state.clinic.clincObj.termsAndCondition)

  function formatPhoneNumber(param) {
    const countryCode = param.countryCode;
    const phoneNumber = param.phoneNumber;
    const formattedNumber = `+${countryCode} ${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;
    return formattedNumber;
  }

  return (
    <footer>
      <div className="top-footer-parent">
        <div className="container">
          <div className="top-footer">
            <div className="f-c-1">
              <img className="logo" src={logo} alt="logo" />
              {contactINFO.isSuccess && (
                <ul className="social-linkss">
                  {contactINFO?.cObject?.instagram?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.instagram} target="_blank" rel="noreferrer">
                        <span className="icon-inst-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.facebook?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.facebook} target="_blank" rel="noreferrer">
                        <span className="icon-fb-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.twitter?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.twitter} target="_blank" rel="noreferrer">
                        <span className="icon-twitter-solid"></span>
                      </a>
                    </li>
                  )}
                  {contactINFO?.cObject?.youtube?.length > 0 && (
                    <li>
                      <a href={contactINFO?.cObject?.youtube} target="_blank" rel="noreferrer">
                        <span className="icon-youtube-solid"></span>
                      </a>
                    </li>
                  )}

                  <li className="d-none">
                    <span className="icon-linkedin-solid"></span>
                  </li>
                </ul>
              )}
            </div>
            <div className="f-c-2">
              <span className="header-f">Options</span>
              <ul>

                {
                  topics.map(item => (
                    item?.active && (
                      <li key={item.systemName}>
                        <Link to={`/Policy/${item.systemName}`}>{item.displayName ? item.displayName : "Not Added"}</Link>
                      </li>
                    )
                  ))
                }

                <li className="d-none">
                  <a href="/">Accessibilty statement</a>
                </li>
                <li className="">
                  <Link to="/contact-us">Contact us</Link>
                </li>
              </ul>
            </div>
            <div className="f-c-3">
              <span className="header-f">Contact</span>
              <ul>
                {defaultHealthCenter?.countryCode &&
                  defaultHealthCenter?.phoneNumber && (
                    <li>
                      <span className="fooicon">
                        <img src={phone} alt="" />
                      </span>
                      <span>
                        <a
                          href={`tel:${defaultHealthCenter?.countryCode}${defaultHealthCenter?.phoneNumber}`}
                        >
                          {formatPhoneNumber(defaultHealthCenter)}
                        </a>
                      </span>
                    </li>
                  )}
                {defaultHealthCenter?.email && (
                  <li>
                    <span className="fooicon">
                      <img src={mail} alt="" />
                    </span>
                    <span>
                      <a href={`mailto:${defaultHealthCenter?.email}`}>
                        {defaultHealthCenter?.email}
                      </a>
                    </span>
                  </li>
                )}
                {defaultHealthCenter?.address && (
                  <li>
                    <span className="fooicon">
                      <img src={loc} alt="Location" />
                    </span>
                    <span>{defaultHealthCenter?.address}</span>
                  </li>
                )}
              </ul>
            </div>
            <div className="f-c-4">
              <span className="header-f d-none">Locate on map</span>
            </div>
          </div>
        </div>
      </div>
      <div className="bottom-footer-parent">
        <div className="container">
          <div className="bottom-footer">
            Copyright © {new Date().getFullYear()}  - Developed with <span className="love">❤</span> by <a href="https://digitalrx.io/" target="_blank" rel="noreferrer">DigitalRX.io</a>
          </div>
          {/* <div className="bottom-footer">
            Copyright © {defaultHealthCenter.healthCenterName} | All rights reserved |
            Developed with <span className="love">❤</span> by <a href="https://digitalrx.io/" target="_blank" rel="noreferrer">DigitalRX.io</a>
          </div> */}
        </div>
      </div>
    </footer >
  );
}

export default Footer;
