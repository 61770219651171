
export const downloadInvoice = (slug) => {
    window.location.href = `${process.env.REACT_APP_API}/clinic/appointment/print/${slug}`;

    // try {
    //     const response = await fetch(`http://uat.digitalrx.io/clinic/appointment/print/${apptDetails.slug}`);
    //     const blob = await response.blob();
    //     const url = window.URL.createObjectURL(new Blob([blob]));
    //     const link = document.createElement('a');
    //     link.href = url;
    //     link.setAttribute('download', 'invoice.pdf'); 
    //     document.body.appendChild(link);
    //     link.click();
    //     link.parentNode.removeChild(link);
    //     window.URL.revokeObjectURL(url);
    // } catch (error) {
    //     console.error('Error downloading invoice:', error);
    // }

};