import axios from "axios";

export const RazorpayAPI = async (obj,token) => {
    const promise = axios.post(
      `/${process.env.REACT_APP_API_VERSION_V1}/RazorPay/CreateOrder`,
      {
        ...obj,
      },
      {
        headers: { Authorization: `Bearer  ${token}` },
      }
    );
  
    const dataPromise = promise.then((response) => response.data);
  
    return dataPromise;
};

function loadScript(src) {
    return new Promise((resolve) => {
        const script = document.createElement("script");
        script.src = src;
        script.onload = () => {
            resolve(true);
        };
        script.onerror = () => {
            resolve(false);
        };
        document.body.appendChild(script);
    });
}
  
export async function displayRazorpay(customOptions, callback, callOnError) {
    const res = await loadScript(
        "https://checkout.razorpay.com/v1/checkout.js"
    );
  
    if (!res) {
        alert("Failed to proceeed for payment. Please check your internet connection and try again.");
        return;
    }
  
    const options = {
        key: customOptions.key, // Enter the Key ID generated from the Dashboard
        amount: customOptions.amount,
        currency: customOptions.currency,
        name: customOptions.name,
        description: "Clinic Payment",
        //image: { logo },
        order_id: customOptions.id,
        handler: async function (response) {
            const data = {
                orderCreationId: customOptions.order_id,
                razorpayPaymentId: response.razorpay_payment_id,
                razorpayOrderId: response.razorpay_order_id,
                razorpaySignature: response.razorpay_signature,
            };
  
            await callback(data);
        },
        prefill: {
            name: customOptions.name,
            email: customOptions.email,
            contact: customOptions.contact,
        },
        theme: {
            color: "#61dafb",
        },
    };
  
    const paymentObject = new window.Razorpay(options);
  
    paymentObject.on('payment.failed', callOnError);
  
    paymentObject.open();
}
  