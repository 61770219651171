import React from 'react';

export const TreatmentsIcon = () => {
    return (
        <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_3865_6564)">
                <path d="M10.0159 5.14325C10.0159 6.2825 9.09117 7.20575 7.95092 7.20575C6.81192 7.20575 5.88867 6.28225 5.88867 5.14325C5.88867 4.00425 6.81192 3.08075 7.95092 3.08075C9.09117 3.08075 10.0159 4.00425 10.0159 5.14325Z" fill="#B7BFCC" />
                <path d="M7.95458 0.130981C7.00008 0.130981 5.66683 0.511481 4.70108 1.47723L5.84808 3.56698C6.09872 3.24802 6.4186 2.99022 6.78354 2.81308C7.14848 2.63593 7.54892 2.54409 7.95458 2.54448C8.81933 2.54448 9.58883 2.95298 10.0791 3.58823L11.2041 1.42923C10.3683 0.515981 8.86958 0.130981 7.95458 0.130981ZM8.80458 1.66548H8.27058V2.19898H7.62908V1.66548H7.09508V1.02423H7.62908V0.488481H8.27058V1.02423H8.80458V1.66548ZM5.24108 10.9127H5.76933L5.01483 13.4987H10.9101L10.1591 10.9127H10.6878L11.4436 13.4987H13.1783L11.9943 9.58073C11.8093 8.93398 10.9983 7.79523 9.59883 7.76073L6.38133 7.75798C4.95008 7.76498 4.12208 8.92598 3.93483 9.58048L2.72783 13.4985H4.48708L5.24108 10.9127ZM0.236328 14.3232H15.6688V15.7915H0.236328V14.3232Z" fill="#B7BFCC" />
            </g>
            <defs>
                <clipPath id="clip0_3865_6564">
                    <rect width="16" height="16" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};
