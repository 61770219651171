import React, { useEffect, useState } from 'react';
import ShearchIcon from '../components/spaciality/ShearchIcon';
import location from "../static/Spaciality/location.svg";
import { Col, Row } from 'react-bootstrap';
import Spaciality from '../components/spaciality/Spaciality';
import { GetAllSpecialties } from '../services/specialityservice';
import { useDispatch, useSelector } from 'react-redux';
import { getSpecialitiesRedux } from '../redux/homePageSlice';
import { Skeleton } from '@mui/material';

const SelectSpeciality = () => {
    const specialities = useSelector((state) => state.homepage.specialities);
    const [ipVal, setIpVal] = useState("");
    const [filteredSpecialities, setFilteredSpecialities] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    // debounching start
    useEffect(() => {
        setIsLoading(true);

        const delaySearch = setTimeout(() => {
            setIpVal(inputValue);
        }, 600);

        return () => clearTimeout(delaySearch);
    }, [inputValue]);

    const handleInput = (event) => {
        setInputValue(event.target.value);
    };
    // debounching end

    useEffect(() => {

        if (ipVal === "") {
            setFilteredSpecialities(specialities.list.specialties);
        } else {
            // dispatch(getSpecialitiesBySearchRedux(ipVal));
            setFilteredSpecialities(specialities.list.specialties.filter(spec => spec.name.toLowerCase().includes(ipVal.toLowerCase())));
        }
        setIsLoading(false);
    }, [ipVal, specialities.list.specialties]);

    const skeleton = <>
        <Col xs={12} md={6} lg={4} className='sp_item'>
            <div className='spaciality'>
                <div className="img">
                    <Skeleton variant='rectangle' width="100%" heigth="100%" />
                    {/* <img src={image} alt="" /> */}
                </div>

                <div className='card_info'>
                    <div className='info_top'>
                        <Skeleton variant='rect' width={60} height={60} />
                        <Skeleton variant='text' width={200} height={80} />
                    </div>
                    <div className='info_text'>

                        <Skeleton variant='text' width="90%" height={25} />
                        <Skeleton variant='text' width="90%" height={25} />
                        <Skeleton variant='text' width="90%" height={25} />

                    </div>
                </div>
                <Skeleton variant='text' width={150} height={50} className='view_btn' />

            </div>
        </Col>
    </>

    return (
        <div className='select_Spaciality_container container'>


            <div className='header'>
                <h2>Explore Our Specialities</h2>
                <div className='filter_area'>
                    <div className='search'>
                        <ShearchIcon />
                        {/* <input type="text" value={inputValue} onChange={(e) => setInputValue(e.target.value)} placeholder='Search' /> */}

                        <input type="text"
                            value={inputValue}
                            onChange={handleInput}
                            placeholder='Search'
                        />
                    </div>
                    <div className='location d-none'>
                        <img src={location} alt="" />
                        <select name="" id="">
                            <option value="">Select one</option>
                            <option value="">Select one</option>
                            <option value="">Select one</option>
                        </select>
                    </div>
                </div>
            </div>
            <Row className='Spaciality_container'>
                {(!specialities.loading && !isLoading) ?
                    (
                        filteredSpecialities?.length > 0 ? (
                            filteredSpecialities.map((spec) => (
                                <Spaciality key={spec.id} spec={spec} />
                            ))
                        ) : (
                            <div className='no_result text-center my-5 py-5'>
                                <h2>No results found</h2>
                            </div>
                        )
                    ) : (
                        <>
                            {skeleton}
                            {skeleton}
                            {skeleton}
                        </>
                    )}
            </Row>

        </div>
    );
};

export default SelectSpeciality;
