import React from "react";

import star from "../../static/appt/Star.svg";
import { Modal } from "react-bootstrap";
import { useState } from "react";
import { Rating } from "@mui/material";
import {
  AddorUpdatePatientRating,
  DeletePatientReview,
} from "../../services/patientRatingservice";

import starsm from "../../static/appt/starsm.svg";
import trash from "../../static/appt/trash.svg";
import edt from "../../static/appt/edt.svg";
import swal from "sweetalert";
import Swal from "sweetalert2";
import feadBack from "../../static/userapp/feadback.png";

function AddReview(props) {
  const {
    apptId,
    docId,
    userToken,
    userId,
    patientRatingDto,
    setApptDetails,
    apptDetails,
  } = props;

  const [show, setShow] = useState(false);

  const defState = {
    id: patientRatingDto ? patientRatingDto.id : 0,
    review: patientRatingDto ? patientRatingDto.review : "",
    rating: patientRatingDto ? patientRatingDto.rating : 0,
    createdOnUtc: patientRatingDto ? patientRatingDto.createdOnUtc : "",
    isFeatured: patientRatingDto ? patientRatingDto.isFeatured : true,
    patientId: patientRatingDto ? patientRatingDto.patientId : userId,
    doctorId: patientRatingDto ? patientRatingDto.doctorId : docId,
    appointmentId: patientRatingDto ? patientRatingDto.appointmentId : apptId,
  };

  const [formValues, setFormValues] = useState({
    review: defState.review,
    rating: defState.rating,
  });

  const handleClose = () => {
    setShow(false);
  };
  const handleShow = () => setShow(true);

  const handleSubmit = () => {
    let tempRatingObj = {
      ...defState,
      ...formValues,
    };
    if (formValues.rating > 0 && formValues.review !== "") {
      AddorUpdatePatientRating(tempRatingObj, userToken)
        .then((res) => {
          //    setRatingObj(res.response.req);
          setApptDetails({
            ...apptDetails,
            patientRatingDto: res.response.req,
          });
          handleClose();
          setFormValues({
            review: res.response.req.review,
            rating: res.response.req.rating,
          });
        })
        .catch((err) => {
          console.log(err);
        });
    } else if (formValues.rating === 0) {
      swal({
        icon: "error",
        title: "Add Rating",
      });
    } else if (formValues.review === "") {
      swal({
        icon: "error",
        title: "Add Review",
      });
    }
  };

  const renderStar = (rating) => {
    let stars = [];

    for (var i = 0; i < rating; i++) {
      stars.push(<img src={starsm} key={i} alt="star" />);
    }

    return stars;
  };

  const deleteReview = (reviewId) => {

    Swal.fire({
      icon: "info",
      text: 'Do you want to delete the review?',

      showCancelButton: true,
      confirmButtonText: 'Delete it',
      customClass: {
        confirmButton: 'btnr btnr-danger',
        cancelButton: 'btnr btnr-secondary ',
      }
    }).then((result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        DeletePatientReview(reviewId, userToken)
          .then((res) => {

            if (res.status === "Success") {


              setFormValues({
                review: "",
                rating: 0,
              });

              setApptDetails({
                ...apptDetails,
                patientRatingDto: null,
              });
            }
          })
          .catch((err) => {
            console.log(err);
          });
      }
    })

  };

  return (
    <>
      {patientRatingDto ? (
        <div className="rating-type mt-2">
          <div className="headr">My review</div>
          <div className="u-head">
            <div className="stars">{renderStar(patientRatingDto.rating)}</div>
            <span
              className="del-btn"
              onClick={() => deleteReview(patientRatingDto.id)}
            >
              <img src={trash} alt="trash" />
            </span>
          </div>
          <div className="txt">
            <p>{patientRatingDto.review}</p>
            <button
              className="btnr btnr-primary-outline btnr-sm"
              onClick={handleShow}
            >
              <span>
                <img alt="asd" src={edt} />
              </span>
              Edit Review
            </button>
          </div>
        </div>
      ) : (
        <div className="share_feadback">
          <div className="header">
            Share your valuable feedback
          </div>
          <button
            className="btnr btnr-primary-outline-white mt-2"
            onClick={handleShow}
          >
            ADD REVIEW{" "}
            <span className="ps-2">
              <img src={star} alt="sd" />
            </span>
          </button>
          <div className="img">
            <img src={feadBack} alt="" />
          </div>
        </div>
      )}

      <Modal className="rev-modal" show={show} onHide={handleClose}>
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="rev-body">
            <div className="main">Review doctor</div>
            <div className="sub">
              Kindly rate your experience with the doctor
            </div>
            <div className="star-list">
              <Rating
                size="large"
                name="simple-controlled"
                value={formValues.rating}
                onChange={(event, newValue) => {
                  setFormValues({
                    ...formValues,
                    rating: newValue ? newValue : 0,
                  });
                }}
              />
            </div>
            <div className="ta">
              <textarea
                placeholder="Write your feedback..."
                value={formValues.review}
                onChange={(e) => {
                  setFormValues({ ...formValues, review: e.target.value });
                }}
                rows={5}
              />
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <button className="btnr btnr-primary btnr-hover w-100" onClick={handleSubmit}>
            {patientRatingDto?.rating > 0 || patientRatingDto?.review.lenght > 0
              ? "Update review"
              : "submit review "}
            <span className="arr"></span>
          </button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default AddReview;
