import React, { useEffect } from "react";
import { Col, Row } from "react-bootstrap";
import useDocumentTitle from "../utils/useDocumentTitle";
import {
  CommentOnPromotion,
  DeleteCommentPromotion,
  GetAllClinicPromotions,
  LikeOnPromotion,
} from "../services/promotionservice";
import { useState } from "react";
import moment from "moment";
import InfiniteScroll from "react-infinite-scroll-component";
import { useDispatch, useSelector } from "react-redux";
import swal from "sweetalert";
import Skeleton from '@mui/material/Skeleton';
import dots from '../static/home/dots.svg'
import { useNavigate } from "react-router-dom";
import { updateLoginDrawerState } from "../redux/webAppSlice";
import SentIcon from "../components/Icons/SentIcon";
import { Accordion, AccordionDetails, AccordionSummary, Button, Input, Typography } from "@mui/material";

const PAGE_SIZE = 3;
const fixedFilter = {
  pageIndex: 1,
  pageSize: PAGE_SIZE,
  clinicId: 2,
  healthCenterId: 0,
  patientId: 0,
  lastUpdatedTimeTicks: 0,
  imageSize: 0,
  type: "string",
  processId: 0,
};

function Wall() {
  useDocumentTitle("Wall");

  const navigate = useNavigate();

  const userObj = useSelector((state) => state.user.userObj);
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);


  const [initialFilter, setInitialFilter] = useState({
    ...fixedFilter,
    patientId: userObj?.patientData?.id ? userObj.patientData?.id : 0,
  });

  const [promotionsObj, setPromotionsObj] = useState({
    list: [],
    isLoading: true,
    isSuccess: false,
    msg: "",
    hasMore: false,
  });

  useEffect(() => {
    fetchMoreData();
  }, []);

  const fetchMoreData = () => {
    setPromotionsObj({
      ...promotionsObj,
      isLoading: true,
    });
    GetAllClinicPromotions(initialFilter)
      .then((res) => {
        let hasMore = false;
        let pageIndex = initialFilter.pageIndex;
        if (res.count > PAGE_SIZE * pageIndex) {
          hasMore = true;
          pageIndex = pageIndex + 1;

          setInitialFilter({
            ...initialFilter,
            pageIndex,
          });
        }

        let tempArr = [...cmtInputArr];

        res.promotions.map((promotion, index) => {
          let tempInputObj = { id: promotion.id, value: "" };
          tempArr = [...tempArr, tempInputObj];
        });

        setCmtInputArr([...cmtInputArr, ...tempArr]);

        setPromotionsObj({
          ...promotionsObj,
          list: [...promotionsObj.list, ...res.promotions],
          isLoading: false,
          isSuccess: true,
          hasMore,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const toggleLikeComment = (boolValue, promotionId) => {
    if (userObj || userObj !== null) {
      let tempObj = {
        promotionId: promotionId,
        isLike: boolValue,
        userType: "Patient",
        likedBy: userObj.patientData.id,
        createdOn: moment().utc().toISOString(),
      };

      LikeOnPromotion(tempObj, userObj.token)
        .then((res) => {
          if (res.successMessage === "Updated Successfully") {
            let tempPromotionList = promotionsObj.list.map(
              (promotion, indx) => {
                let temppObj = {
                  ...promotion,
                };
                if (temppObj.id === promotionId) {
                  temppObj = {
                    ...temppObj,
                    isLiked: res.promotion.isLiked,
                    totalLikes: res.promotion.totalLike,
                  };
                }

                return temppObj;
              }
            );
            setPromotionsObj({
              ...promotionsObj,
              list: [...tempPromotionList],
            });
          }
        })
        .catch((err) => {
          console.log(err);
        });
    } else {
      swal({
        icon: "error",
        title: "Please Sign in First",
      }).then((result) => {
        if (result) {
          openLoginDrawer();
        }
      });
    }
  };
  const dispatch = useDispatch();
  const openLoginDrawer = () => {
    dispatch(updateLoginDrawerState(true));
  };

  const [cmtInputArr, setCmtInputArr] = useState([]);

  const onChangeCommentInput = (e, promotionId) => {
    let tempCmtList = cmtInputArr.map((cmtip, index) => {
      let tempObj = { ...cmtip };

      if (tempObj.id === promotionId) {
        tempObj = {
          ...tempObj,
          value: e.target.value,
        };
      }

      return tempObj;
    });
    setCmtInputArr([...tempCmtList]);
  };

  const [notificationMap, setNotificationMap] = useState({});

  const createAComment = (e, promotionId) => {
e.preventDefault();
    setNotificationMap((prevNotificationMap) => ({
      ...prevNotificationMap,
      [promotionId]: false,
    }));

    const inputValue = cmtInputArr.filter((cmt) => cmt.id === promotionId)[0].value;

    if (!inputValue) {
      setNotificationMap((prevNotificationMap) => ({
        ...prevNotificationMap,
        [promotionId]: true,
      }));
      return;
    }

    let tempCreateCmtObj = {
      id: 0,
      promotionId: promotionId,
      comment: cmtInputArr.filter((cmt) => cmt.id === promotionId)[0].value,
      commentUserType: "Patient",
      commentedBy: userObj?.patientData?.id,
      createdOn: moment().toISOString(),
      updatedOn: moment().toISOString(),
      createdByName: userObj?.patientData?.name,
      createdByPictureUrl: userObj?.patientData?.pictureUrl,
      lastActivity: "",
      isActive: true,
    };

    CommentOnPromotion(tempCreateCmtObj, userObj.token)
      .then((res) => {
        console.log(res);

        //update original list
        let tempUpdatedCommentList = promotionsObj.list.map(
          (promotion, index) => {
            let tempPromotion = {
              ...promotion,
            };

            if (tempPromotion.id === promotionId) {
              tempPromotion = {
                ...tempPromotion,
                promotionComments: [
                  ...tempPromotion.promotionComments,
                  res.comment,
                ],
                totalComments: parseInt(tempPromotion.totalComments) + 1,
              };
            }

            return tempPromotion;
          }
        );

        setPromotionsObj({
          ...promotionsObj,
          list: [...tempUpdatedCommentList],
        });

        //set Iput as Empty
        let tempCmtList = cmtInputArr.map((cmtip, index) => {
          let tempObj = { ...cmtip };

          if (tempObj.id === promotionId) {
            tempObj = {
              ...tempObj,
              value: "",
            };
          }

          return tempObj;
        });
        setCmtInputArr([...tempCmtList]);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const getValueOfInput = (promotionId) => {
    let tempPromotion = cmtInputArr.filter((prom) => prom.id === promotionId);

    return tempPromotion[0].value;
  };

  const [editingCommentId, setEditingCommentId] = useState(null);
  const [editedComment, setEditedComment] = useState("");

  const handleEditClick = (comment, commentId) => {
    setEditingCommentId(commentId);
    setEditedComment(comment);
  };

  const handleCommentEditDone = (promotionId) => {
    const editedCommentContent = editedComment;

    if (!editedCommentContent) {
      return;
    }

    const commentId = editingCommentId;

    const updatedCommentObj = {
      id: commentId,
      comment: editedCommentContent,
    };

    CommentOnPromotion(updatedCommentObj, userObj.token)
      .then((res) => {

        const updatedPromotionsList = promotionsObj.list.map((promotion) => {
          if (promotion.promotionComments) {
            promotion.promotionComments = promotion.promotionComments.map((comment) => {
              if (comment.id === commentId) {
                return { ...comment, comment: editedCommentContent };
              }
              return comment;
            });
          }
          return promotion;
        });

        setPromotionsObj({
          ...promotionsObj,
          list: updatedPromotionsList,
        });

        setEditingCommentId(null);
        setEditedComment('');
      })
      .catch((err) => {
        console.error('Error updating comment:', err);
      });
  };

  const handleDeleteComment = (commentId, commentUserType) => {
    const userConfirmed = window.confirm("Are you sure you want to permanently delete this comment?\nThis action cannot be undone.");

    if (commentUserType === 'Patient') {
      if (userConfirmed) {
        DeleteCommentPromotion(commentId, userObj.token)
          .then((res) => {
            const updatedPromotionsList = promotionsObj.list.map((promotion) => {
              if (promotion.promotionComments) {
                promotion.promotionComments = promotion.promotionComments.filter(
                  (comment) => comment.id !== commentId
                );
              }
              return promotion;
            });
            setPromotionsObj({
              ...promotionsObj,
              list: updatedPromotionsList,
            });
          })
          .catch((err) => {
            console.log(err);
          });
      }
    }

  };

  const [expandedStates, setExpandedStates] = useState(promotionsObj.list.map(() => false));

  const handleReadMoreClick = (index) => {
    const newExpandedStates = [...expandedStates];
    newExpandedStates[index] = !newExpandedStates[index];
    setExpandedStates(newExpandedStates);
  };

  const getPlan = () => {
    let plan = "Basic";
    if (clinicObj?.subscriptionPackage) {
      plan = clinicObj?.subscriptionPackage
    } else {
      return plan = undefined;
    }
    return plan;
  }

  const skeleton = (
    <div className="wall-parent">
      <div className="wp-top">
        <Row>
          <Col xs={10}>
            <div className="profile-wall">
              <div className="pw-g-i">
                <Skeleton variant="circular" width={50} height={50} />
              </div>
              <div className="pw-g-t">
                <Skeleton variant="text" width={220} />
                <Skeleton variant="text" width={150} />
              </div>
            </div>
          </Col>
          <Col xs={2}></Col>
        </Row>
      </div>
      <div className="wp-middle">
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="100%" height={20} />
        <Skeleton variant="text" width="40%" height={20} />
        <Skeleton variant="rectangular" height={300} />
        <div className="buttonsList">
          <Skeleton variant="text" width={100} />
          <Skeleton variant="text" width={100} />
        </div>
      </div>
      <div className="wp-bottom">
        <div className="ipcomment skeleton">
          <div className="user-photo">
            <Skeleton variant="circular" width={40} height={40} />
          </div>
          <div className="s_user_input">
            <Skeleton variant="rectangular" width="70%" height={40} />
            <Skeleton variant="rectangular" width="30%" height={40} />
          </div>
        </div>
        <div className="user-cmts">
          <div className="comment-parent">
            <div className="profile-wall">
              <div className="pw-g-i">
                <Skeleton variant="circular" width={40} height={40} />
              </div>
              <div className="pw-g-t">
                <Skeleton variant="text" width={150} />
                <Skeleton variant="text" width={60} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )

  const handleGoBack = () => {
    navigate(-1);

    if (window.history.length <= 1) {
      navigate('/');
    }
  };

  const [isInputFocused, setIsInputFocused] = useState(false);

  const onFocusInput = () => {
    setIsInputFocused(true);
  };

  const onBlurInput = (id) => {
    if (getValueOfInput(id) === "") {
      setIsInputFocused(false);
    }
  };

  const [isComtOpen, setIsCmtOpen] = useState([]);

  const handleToggleComment = (id) => {
    if (isComtOpen.includes(id)) {
      setIsCmtOpen(isComtOpen.filter((bookId) => bookId !== id));
    } else {
      setIsCmtOpen([...isComtOpen, id]);
    }
  };

  const isCmtActive = (id) => isComtOpen.includes(id);

  return (
    <div className="main-body">
      <div className="walllist top-link">
        <span className="icon-button-left-line" onClick={handleGoBack}></span>
        Doctors Wall
      </div>
      {promotionsObj.isSuccess ? (
        <InfiniteScroll
          className="walllist"
          dataLength={promotionsObj.list.length}
          next={fetchMoreData}
          hasMore={promotionsObj.hasMore}
          loader={skeleton}
        //   endMessage={
        //     <p style={{ textAlign: "center" }}>
        //       <b>Yay! You have seen it all</b>
        //     </p>
        //   }
        >
          {promotionsObj.list.map((promotion, index) => (
            <div
              className="wall-parent"
              data-aos-delay="100"
              data-aos="zoom-in-up"
              data-aos-once="true"
              key={index}
            >
              <div className="wp-top">
                <Row>
                  <Col xs={10}>
                    <div className="profile-wall">
                      <div className="pw-g-i">
                        <img
                          src={promotion.createdByPictureUrl}
                          alt="profile-img"
                        />
                      </div>
                      <div className="pw-g-t">
                        <span className="name">{promotion.createdByName}</span>
                        <span className="time">
                          <span className="icon-clock-line pe-2"></span>
                          {moment(promotion.createdOnUtc).local().format(
                            "DD/MM/YYYY hh:mm A"
                          )}
                        </span>
                      </div>
                    </div>
                  </Col>
                  <Col xs={2}></Col>
                </Row>
              </div>
              <div className="wp-middle">
                <p>
                  {expandedStates[index] || promotion.description.length <= 300 ?
                    <span dangerouslySetInnerHTML={{ __html: promotion.description }} /> :
                    <span dangerouslySetInnerHTML={{ __html: promotion.description.slice(0, 300) }} />
                  }
                  <span className="Read_more" onClick={() => handleReadMoreClick(index)}>
                    {promotion.description.length >= 300 &&
                      (!expandedStates[index] ? " Continue reading...." : " ...Show less")
                    }
                  </span>
                </p>


                {promotion.pictureUrl && (
                  <img src={promotion.pictureUrl} alt="image-1472" />
                )}

              </div>
              {
                getPlan() !== "Basic" &&
                <>
                  <Accordion className="accordian" expanded={isCmtActive(promotion.id)}>
                    <AccordionSummary className="summary">
                      <div className="buttonsList">
                        <span
                          className={`btns like-btn ${promotion.isLiked && "active"}`}
                          onClick={() =>
                            toggleLikeComment(!promotion.isLiked, promotion.id)
                          }
                        >
                          <span className="icon-like-line icon"></span>{" "}
                          {promotion.totalLikes}
                        </span>
                        <span className="btns comment-btn" onClick={() => handleToggleComment(promotion.id)}>
                          <span className="icon-msg-line icon"></span>{" "}
                          {promotion.totalComments}
                        </span>
                      </div>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className="wp-bottom">
                        {(userObj || userObj !== null) && (
                          <div className="ipcomment">
                            <div className="user-photo">
                              <img
                                src={userObj.patientData.pictureUrl}
                                alt="uesr-img"
                              />
                            </div>
                            <form
                              className={`user-input ${isInputFocused ? 'focused' : ''}`}
                              onSubmit={(e) => createAComment(e, promotion.id)}
                            >
                              <input
                                value={getValueOfInput(promotion.id)}
                                placeholder="Add a comment..."
                                onFocus={onFocusInput}
                                onBlur={() => onBlurInput(promotion.id)}
                                onChange={(e) => onChangeCommentInput(e, promotion.id)}
                                className={notificationMap[promotion.id] ? 'error' : ''}
                              />

                              <button
                                className="button"
                                type="submit"
                              >
                                <SentIcon />
                              </button>
                            </form>
                          </div>
                        )}
                        <div className="user-cmts">
                          {promotion.promotionComments.length > 0 && (
                            <>
                              {promotion.promotionComments.map((cmt, index) => (

                                <div className="comment-parent" key={index}>
                                  <div className="profile-wall">
                                    <div className="pw-g-i">
                                      <img
                                        src={cmt.createdByPictureUrl}
                                        alt="profile-img"
                                      />
                                    </div>
                                    <div className="pw-g-t">
                                      <div>

                                        <span className="name">
                                          {cmt.createdByName}
                                        </span>
                                        {
                                          editingCommentId === cmt.id ? (

                                            <input
                                              className="comment-input"
                                              type="text"
                                              value={editedComment}
                                              onChange={(e) => setEditedComment(e.target.value)}
                                            />
                                          ) : (
                                            <span className="cmt">{cmt.comment}</span>
                                          )
                                        }
                                      </div>

                                      {(userObj?.patientData.id === cmt.commentedBy && cmt.commentUserType === 'Patient') && (
                                        <div className="button-wall">
                                          <div className="dropdown">
                                            {editingCommentId !== cmt.id ? (
                                              <button className="">
                                                <img src={dots} alt="" />
                                              </button>
                                            ) :
                                              <button className="save_btn btnr-primary"
                                                onClick={() => handleCommentEditDone(cmt.id)}>
                                                Save
                                              </button>
                                            }
                                            <div className={`drop-menu ${editingCommentId === cmt.id && "dm_input"}`}>
                                              {editingCommentId !== cmt.id ? (
                                                <button className="drop-item" onClick={() => handleEditClick(cmt.comment, cmt.id)}>Edit</button>
                                              ) : null}
                                              {editingCommentId !== cmt.id ? (
                                                <button onClick={() => handleDeleteComment(cmt.id, cmt.commentUserType)} className="drop-item">Delete </button>
                                              ) : null}
                                            </div>
                                          </div>
                                        </div>
                                      )}

                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </div>
                      </div>
                    </AccordionDetails>
                  </Accordion>
                </>
              }
            </div>
          ))}
        </InfiniteScroll>
      ) : (
        <div className="walllist">
          {skeleton}
        </div>
      )
      }
    </div>
  );
}

export default Wall;
