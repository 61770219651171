import React from "react";
import { useEffect } from "react";
import { GetPatientFamilyMembers } from "../../services/patientService";
import { useState } from "react";
import { Col, Row } from "react-bootstrap";
import dependent from "../../static/appt/dep.svg";
import AddDependent from "./AddDependent";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

function Dependents(props) {
  const { user, outputDependantId } = props;

  const [dependantsList, setDependantsList] = useState([]);

  useEffect(() => {
    GetPatientFamilyMembers(user.userObj.token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  const [depIdState, setDepIdState] = useState(0);

  const setDependant = (depId) => {
    outputDependantId(depId);
    setDepIdState(depId);
  };

  //drawer related changes
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const triggerUpdate = (newId) => {
    GetPatientFamilyMembers(user.userObj.token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);
          setDependant(newId);
          setIsDrawerOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <>
      <div className="header">
        Add patient
        <span className="addmem" onClick={() => setIsDrawerOpen(true)}>
          + Add Member
        </span>
      </div>
      <div className="body">
        <Row>
          <Col xs={12} sm={4}></Col>
          <Col xs={12}>
            <Swiper
              slidesPerView={"auto"}
              centeredSlides={false}
              spaceBetween={20}
              pagination={{
                clickable: true,
              }}
              modules={[Pagination]}
              className="app-sch-card-parent mySwiper"
            >
              <SwiperSlide
                className={`dependant fo ${depIdState === 0 && "checked"}`}
                onClick={() => setDependant(0)}
              >
                <img src={user.userObj.patientData.pictureUrl} alt="dep" />
                <span title={user.userObj.patientData.name}>
                  {user.userObj.patientData.name}
                </span>
              </SwiperSlide>
              {dependantsList.map((dependant, index) => (
                <SwiperSlide
                  className={`dependant to ${
                    depIdState === dependant.id && "checked"
                  }`}
                  onClick={() => setDependant(dependant.id)}
                  key={index}
                >
                  <div className="headert">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={25}
                      height={24}
                      fill="none"
                    >
                      <path
                        fill="#A27FFF"
                        d="M20.638 18.697A10.493 10.493 0 1 0 2.076 12c0 2.45.864 4.82 2.438 6.697l-.015.013c.053.063.113.117.167.18.067.076.14.15.21.224.21.228.426.447.652.653.07.063.14.121.21.181.24.207.487.404.743.587.033.022.063.052.096.075v-.01a10.425 10.425 0 0 0 12 0v.01c.033-.023.062-.053.096-.075.255-.184.502-.38.742-.587.07-.06.141-.119.21-.181.227-.206.443-.425.653-.653.07-.075.142-.148.21-.225.053-.062.114-.116.166-.18l-.016-.012ZM12.576 6a3.375 3.375 0 1 1 0 6.75 3.375 3.375 0 0 1 0-6.75ZM6.581 18.697A3.747 3.747 0 0 1 10.326 15h4.5a3.747 3.747 0 0 1 3.745 3.697 8.955 8.955 0 0 1-11.99 0Z"
                      />
                    </svg>
                    <span className="relation">{dependant.relation}</span>
                  </div>
                  {dependant.name && dependant.gender && dependant.age && (
                    <div className="icotext">
                      <span className="t1">{dependant.name}</span>
                      <span className="t2">
                        {dependant.gender} {dependant.age},yrs old
                      </span>
                    </div>
                  )}

                  {/* {dependant.email && (
                <div className="icotext">
                  <div className="icn">
                    <img alt="" src="" />
                  </div>
                  <div className="txt">
                    <span className="t3">{dependant.email}</span>
                  </div>
                </div>
              )}

              {dependant.phone && (
                <div className="icotext">
                  <div className="icn">
                    <img alt="" src="" />
                  </div>
                  <div className="txt">
                    <span className="t4">{dependant.phone}</span>
                  </div>
                </div>
              )} */}
                </SwiperSlide>
              ))}
            </Swiper>
          </Col>
          {/* {dependantsList.map((dependant, index) => (
            <Col xs={12} sm={4} key={index}>
              <div
                className={`dependant ${
                  depIdState === dependant.id && "checked"
                }`}
                onClick={() => setDependant(dependant.id)}
              >
                <img src={dependent} alt="dep" />
                <span title={dependant.name}>{dependant.name}</span>
              </div>
            </Col>
          ))} */}
        </Row>
      </div>
      <AddDependent
        setIsSaving={setIsSaving}
        isSaving={isSaving}
        triggerUpdate={triggerUpdate}
        token={user.userObj.token}
        patientId={user.userObj.patientData.id}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
      />
    </>
  );
}

export default Dependents;
