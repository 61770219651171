import moment from "moment";
import React from "react";
import { useEffect } from "react";
import { useState } from "react";

function MobileCalendar({goToDate, selectedDate2}) {

    const generateNext7Days = () => {
        const startDate = moment();
        const dates = [];
    
        for (let i = 0; i < 7; i++) {
          dates.push(startDate.clone().add(i, "days"));
        }
    
        return dates;
      };

  const [next7Days, setNext7Days] = useState(generateNext7Days);
  const [selectedDate, setSelectedDate] = useState(selectedDate2);


  const selectADate = (date) => { 
    setSelectedDate(date);
    goToDate(date)
   }

  
   useEffect(() => {
     

   }, [selectedDate2])
  
   
  
  return (
    <div className="calendar-mobile  d-block d-sm-none">
      <div className="some-heading">
        Select Date
      </div>
      <div className="items">
         {next7Days.map((date, index) => (
        <div
          key={index}
          className={`dayitem ${
            moment(date).format("YYYY-MM-DD") === selectedDate
              ? "active"
              : ""
          }`}
          onClick={() => selectADate(moment(date).format("YYYY-MM-DD"))}
        >
       
          {index === 0 && (
            <>
              <span className="title">Today</span>
              <span className="subtitle">
                {moment(date).format("DD MMM, ddd")}
              </span>
            </>
          )}
          {index === 1 && (
            <>
              <span className="title">Tommorow</span>
              <span className="subtitle">
                {moment(date).format("DD MMM, ddd")}
              </span>
            </>
          )}
          {index !== 0 && index !== 1 && (
            <>
              <span className="title">{moment(date).format("DD MMM")}</span>
              <span className="subtitle">{moment(date).format("dddd")}</span>
            </>
          )}
          <span></span>
        </div>
      ))}
      </div>
     
    </div>
  );
}

export default MobileCalendar;
