import axios from "axios";

const clinicId = process.env.REACT_APP_CLINIC_ID;

export const CreateClinicContactUs = async (contactObj) => {
  try {
    const response = await axios.post(
      `/${process.env.REACT_APP_API_VERSION_V1}/CreateClinicContactUs`,
      {
        clinicId,
        ...contactObj,
      }
    );

    const responseData = response.data;

    return responseData; 
  } catch (error) {
    console.error('Error in post contact:', error);
    throw error; 
  }
};

