import React, { useState, useEffect } from 'react'
import { useSelector } from 'react-redux';
import { GetAvailablePaymentMethodsAsync } from '../../services/apptservice';

function PaymentMethods(props) {
  const [paymentMethodList, setPaymentMethodList] = useState([]);

  const user = useSelector((state) => state.user);
  const utoken = user.userObj.token;

  const loadedMethods = useSelector((state) => state.clinic.clincObj.avaiablePaymentMethods);

  const {paymentDetails, setPaymentDetails, apptType} = props;

  const [isActive, setIsActive] = useState(false);

  const validateisActive = (boolValue) => { 
    if(!isActive){
      setIsActive(boolValue)
    }
   }

  const checkIfPaymentMethodIsActive = (metaData, appointmentType) => {
    if (appointmentType == 'InPerson') {
      return metaData?.EnabledForClinicVisit == true;
    }
    else if(appointmentType == 'Treatment') {
      return metaData?.EnabledForTreatments == true;
    }
    else if (appointmentType == 'VideoCall') {
      return metaData?.EnabledForVideoCall == true;
    }
    else return true;
  };

  useEffect(() => {
  setPaymentMethodList(loadedMethods);
  GetAvailablePaymentMethodsAsync(utoken).then((data) => {
    setPaymentMethodList(data.response);
  }).catch((err) => {
    console.log('ERROR IN GETTING AVAILABLE PAYMENT METHODS', err);
  });
  }, []);

  return (paymentMethodList.length > 0) && (
    <div className={`payment-method-comp ${isActive ? '': 'd-none'}`}>
      <span className='title'>Payment Methods</span>
      <div className='pay-list'>
       { paymentMethodList.map((method)=> (
            <div key={method.systemName} onClick={()=> setPaymentDetails({...paymentDetails, paymentType: method.systemName})} className={`pay-item ${method.systemName === paymentDetails.paymentType ? 'selected' : ''} ${checkIfPaymentMethodIsActive(method.metaData, apptType) ? '' : 'd-none'}`}>
              {
                method.displayName
              }
              {validateisActive(method.isActive)}
            </div>
           ))}
      </div>
    </div>
  )
}

export default PaymentMethods