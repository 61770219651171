import React, { useEffect, useState } from "react";
import ScrollWrapper from "./ScrollWrapper";
import InjectInterceptors from "./InjectInterceptors";

import Home from "../pages/Home";
import Footer from "../components/Footer";
import Header from "../components/Header";

import { Routes, Route, useLocation } from "react-router-dom";

import Clinic from "../pages/Clinic";
import Wall from "../pages/Wall";
import Contact from "../pages/Contact";
import Login from "../components/Login";
import { useDispatch, useSelector } from "react-redux";
import {
  getClinicDetailsRedux,
  getContactInformationRedux,
} from "../redux/clinicSlice";
import Profile from "../pages/Profile";
import PrivateRoute from "./PrivateRoute";
import {
  getBannersRedux,
  getDoctorsRedux,
  getNotificationsRedux,
  getServiceRedux,
  getSpecialitiesRedux,
  getTestimonialsRedux,
  getTreatmentsRedux,
} from "../redux/homePageSlice";
import DoctorProfile from "../pages/DoctorProfile";
import SelectDoctor from "../pages/SelectDoctor";
import BookAppointment from "../pages/BookAppointment";
import UserAppointment from "../pages/UserAppointment";
import AppointmentDetails from "../pages/AppointmentDetails";
import ViewTreatment from "../pages/ViewTreatment";
import { updateLayout } from "../redux/webAppSlice";
import runOneSignal from "../utils/onesignal";
import UploadReports from "../pages/UploadReports";
import Notification from "../pages/Notification";
import Chat from "../components/chat/Chat";
import MsgPop from "../components/headercomps/MsgPop";
import SelectSpeciality from "../pages/SelectSpeciality";
import SpacialityDetails from "../components/spaciality/SpacialityDetails";
import FollowUpDetails from "../pages/FollowUpDetails";
import Policy from "../pages/Policy";
import ServiceExpired from "../components/Errors/ServiceExpired";

function Router() {
  const isHomeLoaded = true;
  const dispatch = useDispatch();

  //get url location
  const location = useLocation();

  //Start Changes for Body Theming

  useEffect(() => {
    if (location.pathname === "/wall" || location.pathname === "/profile") {
      document.body.classList.add("invert-body-colors");
    } else {
      if (document.body.classList.contains("invert-body-colors")) {
        document.body.classList.remove("invert-body-colors");
      }
    }
  }, [location]);

  //End   Changes for Body Theming

  //get clinic Details

  useEffect(() => {
    dispatch(getClinicDetailsRedux());

    dispatch(getContactInformationRedux());
    setDimension();
  }, []);

  //check for clinic Details
  const clinicDetails = useSelector((state) => state.clinic);
  //overall loader state
  const [isMainLoaderActive, setIsMainLoaderActive] = useState(true);

  useEffect(() => {
    if (!clinicDetails.loading && clinicDetails.isSuccess) {
      setIsMainLoaderActive(false);

      //load Banners
      dispatch(getBannersRedux());

      //load tratments
      dispatch(getTreatmentsRedux());


      //load specialities
      dispatch(getSpecialitiesRedux());

      //load doctors
      dispatch(getDoctorsRedux());

      //load services
      dispatch(getServiceRedux());

      //load testimonials
      dispatch(getTestimonialsRedux());
    }
  }, [clinicDetails.clincObj]);

  ///screen size

  const [screenSize, getDimension] = useState({
    dynamicWidth: window.innerWidth,
    dynamicHeight: window.innerHeight,
  });
  const setDimension = () => {
    let layout = "";
    if (window.innerWidth >= 1480) {
      layout = "extraLargeDesktop";
    } else if (window.innerWidth >= 1200) {
      layout = "largeDesktop";
    } else if (window.innerWidth >= 992) {
      layout = "smallDesktop";
    } else if (window.innerWidth <= 768 && window.innerWidth > 576) {
      layout = "handheld";
    } else if (window.innerWidth <= 576) {
      layout = "mobile";
    }

    dispatch(updateLayout(layout));
  };

  useEffect(() => {
    window.addEventListener("resize", setDimension);

    return () => {
      window.removeEventListener("resize", setDimension);
    };
  }, [screenSize]);

  //check for clinic Details
  const userDetails = useSelector((state) => state.user);

  useEffect(() => {
    if (userDetails.isSuccess) {
      let tempObj = {
        pageNo: 1,
        pageSize: 100,
        patientId: userDetails.userObj.patientData.id,
      };

      //call notifications
      dispatch(getNotificationsRedux(tempObj));
    }
  }, [userDetails]);

  const hasAboutClinicPage = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasAboutClinicPage
  );
  const hasDoctorWall = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasDoctorWall
  );

  const hasDoctorFullProfile = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasDoctorFullProfile
  );

  const [darkTheme, setDarkTheme] = useState(false);

  // React useEffect hook that will fire up
  // when "darkTheme" changes
  useEffect(() => {
    // Accessing scss variable "--background-color"
    // and "--text-color" using plain JavaScript
    // and changing the same according to the state of "darkTheme"
    const root = document.documentElement;
    root?.style.setProperty(
      "--background-color",
      darkTheme ? "#262833" : "#fff"
    );

  }, [darkTheme]);

  return (
    <>
      <ScrollWrapper>
        <InjectInterceptors />
        <div className="d-flex flex-column vh-100 main-clm">
          {isMainLoaderActive && (
            <div className="loadingio-spinner-eclipse-6dfybz626m3">
              <div></div>
            </div>
          )}
          <Login />

          {!isMainLoaderActive && (
            (!clinicDetails?.clincObj?.clinic?.isBillingExpired ?
              <>
                <Header />
                <div className="f-body">
                  {/* <button onClick={() => setDarkTheme(!darkTheme)}>
                        {darkTheme ? "????" : "????"}
                    </button> */}
                  <Routes>
                    <Route exact path="/" element={<Home />} />
                    <Route
                      exact
                      path="/Policy/:systemName"
                      element={<Policy />}
                    />
                    <Route exact path="/" element={<Home />} />

                    {hasDoctorWall && (
                      <Route exact path="/wall" element={<Wall />} />
                    )}
                    {hasAboutClinicPage && (
                      <Route exact path="/clinic" element={<Clinic />} />
                    )}

                    <Route exact path="/contact-us" element={<Contact />} />

                    {hasDoctorFullProfile && (
                      <Route
                        exact
                        path="/doctorprofile/:id"
                        element={<DoctorProfile />}
                      />
                    )}

                    <Route
                      exact
                      path="/selectdoctor"
                      element={<SelectDoctor />}
                    />
                    <Route
                      exact
                      path="/selectspeciality"
                      element={<SelectSpeciality />}
                    />
                    <Route
                      exact
                      path="/speciality/:id"
                      element={<SpacialityDetails />}
                    />
                    <Route
                      exact
                      path="/bookappt"
                      element={
                        <PrivateRoute>
                          <BookAppointment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/appointments"
                      element={
                        <PrivateRoute>
                          <UserAppointment />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/treatment/:id"
                      element={<ViewTreatment />}
                    />
                    <Route
                      exact
                      path="/appointmentsDetails/:apptId"
                      element={
                        <PrivateRoute>
                          <AppointmentDetails />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/followUpDetails/:apptId"
                      element={
                        <PrivateRoute>
                          <FollowUpDetails />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/profile"
                      element={
                        <PrivateRoute>
                          <Profile />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/uploadreports"
                      element={
                        <PrivateRoute>
                          <UploadReports />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/notification"
                      element={
                        <PrivateRoute>
                          <Notification />
                        </PrivateRoute>
                      }
                    />
                    <Route
                      exact
                      path="/chat"
                      element={
                        <PrivateRoute>
                          <Chat />
                        </PrivateRoute>
                      }
                    />
                  </Routes>

                  {window.location.pathname !== '/chat' && <MsgPop />}
                </div>

                <Footer />
              </>
              :
              <ServiceExpired />
            )

          )}
        </div>
      </ScrollWrapper>
    </>
  );
}

export default Router;
