import React from 'react';
import ChatRightSide from './ChatRightSide';
import ChatLeftSide from './ChatLeftSide';
import AgreementModal from './AgreementModal';

const Chat = () => {

    const [modalShow, setModalShow] = React.useState(false);
    const [isChatActive, setIsChatActive] = React.useState(false);
    const [isChatboxActive, setIsChatboxActive] = React.useState(false);

    const doctors = [
        {
            id: 1,
            name: 'Alex Linderson',
            lastMsg: 'How are you today?',
            isActive: true,
            isReaded: true,
        },
        {
            id: 2,
            name: 'Alex Linderson',
            lastMsg: 'How are you today?',
            isActive: true,
            isReaded: false,
        },
        {
            id: 3,
            name: 'Alex Linderson',
            lastMsg: 'How are you today?',
            isActive: false,
            isReaded: false,
        },
        {
            id: 4,
            name: 'Alex Linderson',
            lastMsg: 'How are you today?',
            isActive: false,
            isReaded: false,
        },
        {
            id: 5,
            name: 'Alex Linderson',
            lastMsg: 'How are you today?',
            isActive: false,
            isReaded: false,
        },
    ]

    const handleAgree = () => {
        setIsChatActive(true);
        setModalShow(false);
    }

    const handleChatbox = () => {
        setIsChatboxActive(!isChatboxActive);
    }

    return (
        <div className='container chatPage'>
            <div className='main_chat'>

                <ChatLeftSide
                    handleChatbox={handleChatbox}
                    doctors={doctors}
                />
                <ChatRightSide
                    show={modalShow}
                    setModalShow={setModalShow}
                    isChatActive={isChatActive}
                    isChatboxActive={isChatboxActive}
                />

            </div>

            <AgreementModal
                show={modalShow}
                onHide={() => setModalShow(false)}
                handleAgree={handleAgree}
                setIsChatActive={setIsChatActive}
            />
        </div>
    );
};

export default Chat;