import React from 'react';
import { Col } from 'react-bootstrap';
import { Link } from 'react-router-dom';


const Spaciality = ({ spec }) => {

    return (
        <Col xs={12} md={6} lg={4} className='sp_item'>
            <div className='spaciality'>
                <div className="img">
                    <img src={spec.pictureUrl} alt="" />
                    {/* <img src={image} alt="" /> */}
                </div>

                <div className='card_info'>
                    <div className='info_top'>
                        <img src={spec.iconUrl} alt="" />
                        <h3>{spec.name}</h3>
                    </div>
                    <div className='info_text'
                        dangerouslySetInnerHTML={{
                            __html:
                                spec.description,
                        }}
                    >
                    </div>
                </div>
                <Link
                    to={`/speciality/${spec.slug}`}
                    className='view_btn btnr btnr-primary-outline btnr-hover'>
                    View more
                    <span className='arr'></span>
                </Link>
            </div>
        </Col>
    );
};

export default Spaciality;