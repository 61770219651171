import moment from "moment";
import { CheckIfDoctorAvailable } from "../../../services/apptservice";
import { GetHealthCenterDetails } from "../../../services/clinicservice";
import { GetFilteredDoctors } from "../../../services/doctorservice";
import swal from "sweetalert";

const PAGE_SIZE = 20;
export const fixedFilter = {
    pageIndex: 1,
    pageSize: PAGE_SIZE,
    healthCenterId: 0,
    patientId: 0,
    lastUpdatedTimeTicks: 0,
    imageSize: 0,
    available: "",
    consultationFeeRange: {
        start: 0,
        end: 0
    },
    gender: ""
};


export const DocAvailObj = {
    doctorId: 0,
    ScheduleDateAtLocalTime: moment().format(),
    TimeDifferenceFromUtcInMinutes: moment().utcOffset(),
    type: "",
    processId: 0,
};

export const filterDoctors = (setDoctors, doctors, initialFilter) => {
    setDoctors({ ...doctors, loading: true });
    GetFilteredDoctors(initialFilter)
        .then((res) => {
            if (res.doctors.length > 0) {
                setDoctors({
                    list: res.doctors,
                    selectedDoctor: res.doctors[0],
                    loading: false
                });
            } else {
                setDoctors({
                    list: [],
                    selectedDoctor: {},
                    loading: false
                });
            }

        })
        .catch((err) => {
            console.log(err);
        });
}

export const callCheckIfDoctorAvailable = (tempDocAvailObj, setSlots, slots, setSelectedSlot, setPaymentDetails) => {
    setSlots({ ...slots, isLoading: true });
    CheckIfDoctorAvailable(tempDocAvailObj)
        .then((res) => {
            if (res.status === "Success") {
                setSlots({ slotlist: res.response.slots, isLoading: false });
                setSelectedSlot(res.response.slots[0]);
                setPaymentDetails({
                    ...res.response.paymentDetails,
                });
            } else {
                setSlots({ slotlist: [], isLoading: false });
            }
            // setPageState({ ...tempDocAvailObj });
        })
        .catch((err) => {
            if (err.response?.data.status === "Fail") {
                setSlots({
                    slotlist: [],
                    isLoading: false,
                    error:err.response?.data?.response?.message
                });
                setSelectedSlot(null)
                // setPageState({ ...tempDocAvailObj });
            }
        });
};

export const fatchHealthCenterDetails = (clincObj, setHealthCenterObj, setIsLoading) => {
    setIsLoading(true);
    GetHealthCenterDetails(clincObj?.defaultHealthCenter?.id)
        .then((res) => {
            setHealthCenterObj(res.response.healthCenterDetails);
            setIsLoading(false);
        })
        .catch((err) => {
            console.log(err);
            setIsLoading(false);
        });
}


export const getDates = (setDays, healthCenterObj, setDayInfo) => {
    const currentDate = moment();
    const next7DaysList = Array.from({ length: 7 }, (_, index) => {
        const date = moment(currentDate).add(index, 'days');
        return {
            day: date.format('dddd'),
            date: date.toISOString(),
        };
    });

    setDays(next7DaysList);

    if (healthCenterObj) {
        const selectedDays = Object.keys(healthCenterObj).filter((day) => healthCenterObj[day]);

        if (selectedDays.length > 0) {
            setDayInfo({ ...next7DaysList[selectedDays.indexOf(selectedDays[0])] });
        } else {
            setDayInfo({ ...next7DaysList[0] });
        }
    } else {
        setDayInfo({ ...next7DaysList[0] });
    }
};

export const selectDoctor = (id, doctors, setDoctors) => {
    if (id === doctors?.selectedDoctor?.id) return;
    const selectedDoc = doctors?.list?.find((doc) => doc.id === id);
    setDoctors(prevState => ({
        ...prevState,
        selectedDoctor: selectedDoc
    }));
};

export const selectPatient = (id, patients, setPatientData) => {
    const selectedPatient = patients.find(patient => patient.id === id);
    setPatientData(prevState => ({
        ...prevState,
        selected: selectedPatient,
    }));
};

export const handleDateSelection = (selectedDate, dayInfo, setDayInfo) => {
    if (selectedDate === dayInfo.date) return;
    const momentDate = moment(selectedDate, 'D MMM YYYY');
    setDayInfo({
        day: momentDate.format('dddd'),
        date: selectedDate,
    });
};

export const navigateToBookAppt = (stateforNextPage,setIsActive, selectedSlot,navigate) => {

    if (selectedSlot == null) {
        swal({
            title: "Please select a slot",
            text: "You didn't select any time slot for the appointment. You can select from the Book Appointment page.",
            icon: "warning",
            buttons: {
                confirm: "OK",
                cancel: "Select a slot",
            },
        }).then((value) => {
            if (value) {
                navigate("/bookappt", { state: stateforNextPage });
            } else {
                setIsActive((prevState) => ({
                    ...prevState,
                    TimeDrop: true,
                }));
            }
        });
    } else {
        navigate("/bookappt", { state: stateforNextPage });
    }
};