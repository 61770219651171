import React from "react";
import { Col, Row } from "react-bootstrap";
import { Link } from "react-router-dom";

function Megamenu(props) {
  const { type, list, comImg, comTitle, viewAll } = props;

  return (
    <div className="mega">
      <Row>
        <Col xs={4}>
          <div className="imgcmp">
            <img src={comImg} className="w-100" alt="as" />
            <div className="cnt">
              <span>{comTitle}</span>
              {
                viewAll && (
                  <Link to={viewAll} className="btnr btnr-white btnr-hover">
                        See all <span className="arr"></span>
                    </Link>
                )
              }
              
            </div>
          </div>
        </Col>
        <Col xs={8}>
          <Row className="subMega">
            <Col xs={12} className="simhead">
              {comTitle}
            </Col>
            {list.map((item, index) => (
              <Link
                to={item.link}
                className="col-4 mega-item"
                key={index}
                xs={4}
              >
                <div className="blob"></div>
                <div className="txtbox">
                  <div className="name">
                    {item.name} {item?.cost}
                  </div>
                  <div className="desc">{item.decs}</div>
                </div>
              </Link>
            ))}
          </Row>
        </Col>
      </Row>
    </div>
  );
}

export default Megamenu;
