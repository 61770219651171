import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import { Col, Row } from 'react-bootstrap';
import { getTimeDifference } from '../utils/timeHelper';
import { Link } from 'react-router-dom';
import { Skeleton } from '@mui/material';
import nonotiff from '../static/notif/notiff.svg';
import check from "../static/notif/check.svg";
import { MarkAllReadPatientNotificationAPI } from '../services/notiffservice';
import { getNotificationsRedux } from '../redux/homePageSlice';
import { getColor, getNotifIcon, getnotifStatus } from '../components/headercomps/CommonNotiff';

const Notification = () => {

    const notiffs = useSelector((state) => state.homepage.notifications);
    const dispatch = useDispatch();

    const userDetails = useSelector((state) => state.user);

    const markAllAsRead = () => {
        MarkAllReadPatientNotificationAPI(userDetails.userObj.patientData.id).then((res) => {
            if (res === "Updated") {
                let tempObj = {
                    pageNo: 1,
                    pageSize: 100,
                    patientId: userDetails.userObj.patientData.id,
                };

                //call notifications
                dispatch(getNotificationsRedux(tempObj));
            }
        }).catch((e) => {
            console.log(e)
        })
    };

    const notiffSkeleton = (
        <div className="notif-item">
            <Row className="align-items-center">
                <Col xs={2} className="text-center img">
                    <Skeleton variant="circular" width={45} height={45} />
                </Col>
                <Col xs={8}>
                    <div className="title">
                        <Skeleton />
                    </div>
                    <div className="subtitle">
                        <Skeleton />
                    </div>
                </Col>
                <Col xs={2}>
                    <span className="msg-time">
                        <Skeleton />
                    </span>
                </Col>
            </Row>
        </div>
    )

    return (
        // <div></div>
        <div className='main-body notification-page'>
            <div className='container'>
                <div className='notiff_box'>

                    <div className="header">
                        Notifications
                        {
                            !notiffs?.loading && (
                                notiffs?.list.length > 0 && (
                                    <span className="" onClick={() => markAllAsRead()}>Mark all as read <img src={check} className="ps-1" alt="sd" /> </span>
                                )
                            )
                        }

                    </div>

                    <div className="list">
                        {
                            !notiffs?.loading ? (
                                notiffs?.list.length > 0 ? notiffs.list.map((notif, indx) => (
                                    <Link to={`${notif.notificationGroup === "Appointment" ? `/appointmentsDetails/${notif.associatedId}` : ''}${notif.notificationGroup === "Promotions" ? `wall` : ''}`} className={`notif-item ${notif.isRead ? '' : 'notread'}`} key={indx}>

                                        <Row>
                                            <Col xs={2} className="text-center img">

                                                <img src={getNotifIcon(notif)} alt="" />

                                                {/* {notif.notificationGroup === "Appointment" && (<span className="icons-nav icon-plus-solid"></span>)}
                                                {notif.notificationGroup === "Promotions" && (<span className="icons-nav icon-active  icon-wall-solid"></span>)} */}
                                            </Col>
                                            <Col xs={8}>
                                                <div className="title">
                                                    {
                                                        notif.title
                                                    }
                                                    {" "}
                                                    {
                                                        notif.associatedDateTime && (
                                                            <>
                                                                {`${moment
                                                                    .utc(notif.associatedDateTime)
                                                                    .local()
                                                                    .format("hh:mm a")} `
                                                                }
                                                            </>
                                                        )
                                                    }

                                                </div>
                                                <div className={`notif_status ${getColor(notif)}`}>
                                                    {getnotifStatus(notif)}
                                                </div>
                                                <div className="subtitle">
                                                    {
                                                        notif.messegeBody
                                                    }
                                                </div>
                                            </Col>
                                            <Col xs={2}> <span className="msg-time">{getTimeDifference(notif.createdOnUtc)}</span></Col>
                                        </Row>
                                    </Link >
                                )) : (
                                    <div className="no-notiff">
                                        <div className="img">
                                            <img src={nonotiff} alt="" />
                                        </div>
                                        <div className="no-notiff-text">
                                            <h2>No notifications</h2>
                                            <span>You have no notifications yet. <br />
                                                Please come back later.</span>
                                        </div>
                                    </div>
                                )
                            ) : (
                                <>
                                    {notiffSkeleton}
                                    {notiffSkeleton}
                                    {notiffSkeleton}
                                    {notiffSkeleton}
                                    {notiffSkeleton}
                                </>
                            )
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Notification;