import React from 'react';
import { Col, Row } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Accordion, AccordionDetails, AccordionSummary, useMediaQuery } from '@mui/material';
import moment from "moment";
import tick from "../../static/userapp/tick.svg"
import bs from "../../static/userapp/bs.svg";
import ArrowFillIcon from '../Icons/ArrowFillIcon';
import { downloadInvoice } from '../../utils/downloadInvoice';

const DetailsAppt = ({ apptDetails }) => {

    const isMobile = useMediaQuery('(max-width:540px)');
    const currencySymbol = useSelector(
        (state) => state.clinic.clincObj.clinic.currencyDto.symbol
    );

    return (
        <Accordion
            defaultExpanded={!isMobile}
            className={`appt_details ${apptDetails.appointmentType === "VideoCall" && "vc"}`}
        >
            <AccordionSummary expandIcon={<ArrowFillIcon />}>
                <div className="header top_header">
                    Appoinment Details
                </div>
            </AccordionSummary>
            <AccordionDetails className="accordial_details">
                <Row className="appt_info">
                    <div className="appt_par">
                        <div className="txt">
                            Appointment ID
                            <span>{apptDetails.appointmentNumber}</span>
                        </div>
                        <div className="complete">
                            <img src={tick} alt="" />
                            Complete
                        </div>
                    </div>
                    <Col xs={12} sm={12}>
                        <div className="row">
                            <div className="col-7">
                                <div className="txt">
                                    Booked by
                                    <span>{apptDetails.patientName}</span>
                                </div>
                            </div>
                            <div className="col-5">
                                <div className="txt myself">
                                    Booked for
                                    <span>
                                        {apptDetails?.appointmentForRelation !== null ? apptDetails?.appointmentForRelation : "Myself"}
                                    </span>
                                </div>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12}>
                        <div className="txt">
                            Booking Date
                            <span>
                                {moment
                                    .utc(apptDetails?.scheduledAtUtc)
                                    .local()
                                    .format("DD,MMM YYYY")
                                }
                            </span>
                        </div>
                    </Col>

                    <Col xs={12} sm={12} className="header mt-2 mb-2">
                        Payment Summary
                    </Col>
                    <Col xs={12} sm={12}>
                        <div className="icon-text">
                            <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                            </div>
                            <div className="txt">
                                <span className="t1">Payment Method</span>
                                <span className="t2">
                                    {apptDetails.paymentDetails.paymentType}
                                </span>
                            </div>
                        </div>
                    </Col>

                    <Col xs={12} sm={12}>
                        <div className="icon-text">
                            <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                            </div>
                            <div className="txt">
                                <span className="t1">Bill Summary</span>
                                <span className="t2">
                                    Total Amount
                                    <span className="t3">
                                        {currencySymbol}
                                        {apptDetails.paymentDetails.fee +
                                            apptDetails.paymentDetails.serviceFee +
                                            apptDetails.paymentDetails.tax}
                                    </span>{" "}
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                        <div className="icon-text">
                            <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                            </div>
                            <div className="txt">
                                <span className="t1">Transaction ID</span>
                                <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} className={apptDetails.paymentDetails.transactionId == null ? "d-none" : ""}>
                        <div className="icon-text">
                            <div className="icon">
                                <img className="star" src={bs} alt="bp" />
                            </div>
                            <div className="txt">
                                <span className="t1">Transaction ID</span>
                                <span className="t2">{apptDetails.paymentDetails.transactionId}</span>
                            </div>
                        </div>
                    </Col>
                    <Col xs={12} sm={12} className="d-flex justify-content-center">
                    <button onClick={()=>downloadInvoice(apptDetails.slug)} className="btnr btnr-primary-outline">Download Invoice</button>
                    </Col>
                </Row>
            </AccordionDetails>
        </Accordion>
    );
};

export default DetailsAppt;
