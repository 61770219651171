import React from 'react';

const SentIcon = () => {
    return (
        <svg width="24" height="25" viewBox="0 0 28 29" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M3.79026 2.74489C2.82726 2.28489 1.76926 3.16489 2.04426 4.19589L4.06026 11.7289C4.11079 11.9182 4.21582 12.0885 4.36231 12.2187C4.50881 12.3488 4.6903 12.433 4.88426 12.4609L14.7683 13.8729C15.0543 13.9129 15.0543 14.3269 14.7683 14.3679L4.88526 15.7789C4.69131 15.8068 4.50981 15.891 4.36331 16.0211C4.21682 16.1513 4.11179 16.3216 4.06126 16.5109L2.04426 24.0479C1.76926 25.0779 2.82726 25.9579 3.79026 25.4989L25.2883 15.2499C26.2373 14.7979 26.2373 13.4459 25.2883 12.9929L3.79026 2.74489Z" fill="#B7BFCC" />
        </svg>

    );
};

export default SentIcon;