import React, { useEffect } from "react";
import useDocumentTitle from "../utils/useDocumentTitle";
import { useSelector } from "react-redux";
import phone_icon from "../static/contact/phone.webp"
import email_icon from "../static/contact/email.webp"
import location from "../static/contact/location.webp";
import mark from "../static/contact/7.png";
import { Col, Row } from "react-bootstrap";
import CountryCodeSelector from "../components/microcomponenets/CountryCodeSelector";
import { CreateClinicContactUs } from "../services/contectUsService";
import { useNavigate } from "react-router-dom";
import swal from "sweetalert";

function Contact() {
  useDocumentTitle("Contact-Us");
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = React.useState(false);
  const [isbuttonEnable, setIsbuttonEnable] = React.useState(true);
  const [countryCode, setCountryCode] = React.useState({
    countryCode: process.env.REACT_APP_DEFAULT_COUNTRYCODE
  });


  const [formDetails, setFormDetails] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subjectLine: "",
    message: "",
  });
  const [errors, setErrors] = React.useState({
    firstName: "",
    lastName: "",
    email: "",
    phoneNumber: "",
    subjectLine: "",
    message: "",
  });

  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);

  function formatPhoneNumber(param) {
    const countryCode = param.countryCode;
    const phoneNumber = param.phoneNumber;

    const formattedNumber = `+${countryCode} ${phoneNumber.slice(0, 3)}-${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6)}`;

    return formattedNumber;
  }

  const checkNumber = (e) => {
    let tempNumber = e.target.value;
    let isnum = /^\d+$/.test(tempNumber);
    if (isnum || tempNumber === "") {
      setFormDetails((prevFormDetails) => {
        return { ...prevFormDetails, phoneNumber: tempNumber, };
      });
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const { firstName, lastName, email, subjectLine, phoneNumber, message, } = formDetails;

    const data = {
      clinicId: clinicDetails.id,
      firstName: firstName,
      lastName: lastName,
      email: email,
      subject: subjectLine,
      countryCode: countryCode.countryCode,
      phone: phoneNumber,
      message: message,
    };

    // console.log("data", data);

    if (validateInputs()) {
      CreateClinicContactUs(data)
        .then((res) => {
          callSwal("Success!", "Form submitted successfully.", "success", "OK");
          setFormDetails((prevFormDetails) => ({
            ...prevFormDetails,
            firstName: "",
            lastName: "",
            email: "",
            phoneNumber: "",
            subjectLine: "",
            message: "",
          }));
          setIsLoading(false);
        })
        .catch((err) => {
          console.log("err", err);
          callSwal("Error!", "There was an error submitting the form. Please try again.", "error", "OK");
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  }

  const callSwal = (title, text, icon, button) => {
    swal({
      title: title,
      text: text,
      icon: icon,
      button: button,
    });
  }

  const validateInputs = () => {
    let isValid = true;
    const validationFunctions = {
      firstName: (value) => (value.trim() === "" ? "First Name is required" : ""),
      lastName: (value) => (value.trim() === "" ? "Last Name is required" : ""),
      email: (value) => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(value) ? "" : "Please enter a valid email address";
      },
      phoneNumber: (value) => (value.trim() === "" ? "Please enter phone number" : ""),
      subjectLine: (value) => (value.trim() === "" ? "Subject Line is required" : ""),
      message: (value) => (value.trim() === "" ? "Message is required" : ""),
    };

    const newErrors = {};
    Object.keys(validationFunctions).forEach((field) => {
      const errorMessage = validationFunctions[field](formDetails[field]);
      newErrors[field] = errorMessage;
      if (errorMessage) {
        isValid = false;
      }
    });
    setIsbuttonEnable(isValid);
    setErrors(newErrors);
    return isValid;
  };

  const validateInputs2 = () => {
    let isValid = true;
    const validationFunctions = {
      firstName: (value) => (value.trim() === "" ? "First Name is required" : ""),
      lastName: (value) => (value.trim() === "" ? "Last Name is required" : ""),
      email: (value) => {
        const emailRegex = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,3})+$/;
        return emailRegex.test(value) ? "" : "Please enter a valid email address";
      },
      phoneNumber: (value) => (value.trim() === "" ? "Please enter phone number" : ""),
      subjectLine: (value) => (value.trim() === "" ? "Subject Line is required" : ""),
      message: (value) => (value.trim() === "" ? "Message is required" : ""),
    };
  
    const newErrors = {};
    Object.keys(validationFunctions).forEach((field) => {
      const errorMessage = validationFunctions[field](formDetails[field]);
      newErrors[field] = errorMessage;
      if (errorMessage) {
        isValid = false;
      }
    });
    setIsbuttonEnable(isValid);
  };

  useEffect(() => { 
    validateInputs2();
  }, [formDetails]);

  const handleGoBack = () => {
    navigate(-1);
    if (window.history.length <= 1) {
      navigate('/');
    }
  };

  return (
    <div className="main-body contect-page">
      <div className="container">
        <div className="back_from">
          <span className="icon-button-left-line" onClick={handleGoBack}></span>
          Contact Us
        </div>
        <div className="contect_us">
          <div className="contect_title">
            <h2>Contact Us</h2>
            <span>Any Question or remarkes? Just Write us message!</span>
          </div>
          <div className="main_contect">
            <div className="contect_info">
              <div>
                <h3>Contact Information</h3>
                <span>Say something to start a live chat!</span>
              </div>
              <div className="info">
                <div className="con_item">
                  <img src={phone_icon} alt="" />
                  <span>{formatPhoneNumber(clinicDetails)}</span>
                </div>
                <div className="con_item">
                  <img src={email_icon} alt="" />
                  <span>{clinicDetails.email}</span>
                </div>
                <div className="con_item">
                  <img src={location} alt="" />
                  <span>
                    {
                      clinicDetails.address ? clinicDetails.address : "Not Available"
                    }
                  </span>
                </div>
              </div>
              <img className="b_mark" src={mark} alt="" />
            </div>
            <div className='contect'>
              <form onSubmit={handleSubmit} className="contect_form">
                <div className="input_div">
                  <div className='input'>
                    <label htmlFor="First_name">First Name</label>
                    <input
                      type="text"
                      placeholder="Enter Your First Name"
                      value={formDetails?.firstName}
                      onChange={(e) => setFormDetails((prevFormDetails) => ({
                        ...prevFormDetails,
                        firstName: e.target.value,
                      }))}
                    />
                    {errors.firstName && <p className="error">{errors.firstName}</p>}
                  </div>
                  <div className="input">
                    <label htmlFor="Last_name">Last Name</label>
                    <input type="text"
                      placeholder="Enter Your Last Name"
                      value={formDetails?.lastName}
                      onChange={(e) => setFormDetails((prevFormDetails) => ({
                        ...prevFormDetails,
                        lastName: e.target.value,
                      }))}
                    />
                    {errors.lastName && <p className="error">{errors.lastName}</p>}
                  </div>
                </div>
                <div className="input_div">
                  <div className='input'>
                    <label htmlFor="Email">Email</label>
                    <input
                      type="text"
                      placeholder='Enter your email'
                      name='email'
                      value={formDetails?.email}
                      onChange={(e) => setFormDetails((prevFormDetails) => ({
                        ...prevFormDetails,
                        email: e.target.value,
                      }))}
                    />
                    {errors.email && <p className="error">{errors.email}</p>}
                  </div>
                  <Col md={12} className="input_div phone_div">
                    <label htmlFor="Phone_number">Phone Number</label>
                    <Row className="phone-no-grp">
                      <Col xs={3}>
                        <CountryCodeSelector
                          defaultValue={process.env.REACT_APP_DEFAULT_COUNTRYCODE}
                          phoneNumber={countryCode}
                          setPhoneNumber={setCountryCode}
                        />
                      </Col>
                      <Col xs={9} className="inputmix">
                        <span></span>
                        <input
                          placeholder="987632XX"
                          className="phoneNumber-login"
                          value={formDetails?.phoneNumber}
                          type="text"
                          onChange={(e) => checkNumber(e)}
                        />
                      </Col>
                    </Row>
                    {errors.phoneNumber && <p className="error">{errors.phoneNumber}</p>}
                  </Col>
                </div>

                <div className='input_b'>
                  <label htmlFor="discription">Subject Line</label>
                  <input
                    type="text"
                    className="textareaField"
                    placeholder='Enter your Subject Line'
                    value={formDetails?.subjectLine}
                    onChange={(e) => setFormDetails((prevFormDetails) => ({
                      ...prevFormDetails,
                      subjectLine: e.target.value,
                    }))}
                  />
                  {errors.subjectLine && <p className="error">{errors.subjectLine}</p>}
                </div>
                <div className='input_b'>
                  <label htmlFor="discription">Message</label>
                  <textarea
                    type="text"
                    className="textareaField"
                    placeholder='Write your Message'
                    value={formDetails?.message}
                    onChange={(e) => setFormDetails((prevFormDetails) => ({
                      ...prevFormDetails,
                      message: e.target.value,
                    }))}
                  />
                  {errors.message && <p className="error">{errors.message}</p>}
                </div>

                <div className="submit">
                  <button disabled={isLoading } className={`btnr btnr-primary btnr-hover  ${!isbuttonEnable? "disabled":""}`}>
                    Send Message
                    <span className="arr"></span>
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Contact;
