import React from "react";
import { useEffect } from "react";
import { GetPatientFamilyMembers } from "../../services/patientService";
import { useSelector } from "react-redux";
import { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";

import user from "../../static/profile/familymember/user.svg";
import add from "../../static/profile/familymember/add.svg";
import email from "../../static/profile/familymember/email.svg";
import ph from "../../static/profile/familymember/ph.svg";
import AddDependent from "../appt/AddDependent";
import family from "../../static/profile/family.webp";
import personePlus from "../../static/profile/personePlus.svg";

function FamilyMembers({ dependantsList, setDependantsList }) {
  //get user details fom redux
  const userDetails = useSelector((state) => state.user);
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  //drawer related changes
  const [isDrawerOpen, setIsDrawerOpen] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const triggerUpdate = (newId) => {
    GetPatientFamilyMembers(userDetails.userObj.token)
      .then((res) => {
        if (res.status === "Success") {
          setDependantsList(res.response.patientData);

          setIsDrawerOpen(false);
        }
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [updateDepandant, setUpdateDepandant] = useState(null);
  useEffect(() => {
    if (!isDrawerOpen) {
      setUpdateDepandant(null);
    }
  }, [isDrawerOpen]);
  useEffect(() => {
    if (updateDepandant) {
      setIsDrawerOpen(true);
    }
  }, [updateDepandant]);

  return (
    <div className="family-memebers">
      {
        dependantsList?.length > 0 ?
          <>
            <div className="header">
              <span>Family Members</span>
              {
                (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) &&
                <span className="add" onClick={() => setIsDrawerOpen(true)}>
                  {" "}
                  <img src={add} alt="" /> Add family
                </span>
              }
            </div>
            <div className="body">
              <Swiper
                slidesPerView={"auto"}
                centeredSlides={false}
                spaceBetween={20}
                pagination={{
                  clickable: true,
                }}
                modules={[Pagination]}
                className="app-sch-card-parent mySwiper"
              >
                {dependantsList.map((dependant, index) => (
                  <SwiperSlide className="dep-item" key={index}>
                    <div className="headert">
                      <span className="relation">{dependant.relation}</span>
                      <button
                        className="btnr btnr-primary-outline btnr-sm"
                        onClick={() => {
                          setUpdateDepandant(dependant);
                        }}
                      >
                        Edit
                      </button>
                    </div>
                    {dependant.name && dependant.gender && dependant.age && (
                      <div className="icotext">
                        <div className="icn">
                          <img alt="" src={user} />
                        </div>
                        <div className="txt">
                          <span className="t1">{dependant.name}</span>
                          <span className="t2">
                            {dependant.gender} {dependant.age},yrs old
                          </span>
                        </div>
                      </div>
                    )}

                    {dependant.email && (
                      <div className="icotext">
                        <div className="icn">
                          <img alt="" src={email} />
                        </div>
                        <div className="txt">
                          <span className="t3">{dependant.email}</span>
                        </div>
                      </div>
                    )}

                    {dependant.phone && (
                      <div className="icotext">
                        <div className="icn">
                          <img alt="" src={ph} />
                        </div>
                        <div className="txt">
                          <span className="t4">{dependant.phone}</span>
                        </div>
                      </div>
                    )}
                  </SwiperSlide>
                ))}

                <div>

                </div>
              </Swiper>
            </div>
          </>
          :
          <div className="nofamaly">
            <img src={family} alt="" />
            <span>There is no family member added</span>
            <button
              className="btnr btnr-primary"
              onClick={() => setIsDrawerOpen(true)}
            >
              <img src={personePlus} alt="" />
              Add Member
            </button>
          </div>
      }
      <AddDependent
        setIsSaving={setIsSaving}
        isSaving={isSaving}
        triggerUpdate={triggerUpdate}
        token={userDetails.userObj.token}
        patientId={userDetails.userObj.patientData.id}
        isDrawerOpen={isDrawerOpen}
        setIsDrawerOpen={setIsDrawerOpen}
        updateDepandant={updateDepandant}
      />
    </div>
  );
}

export default FamilyMembers;
