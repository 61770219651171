import React from "react";

import vc from "../../static/appt/vc.svg";
import ip from "../../static/appt/ip.svg";
import treatw from "../../static/appt/treatw.svg";
import check from "../../static/appt/check.svg";
import { useSelector } from "react-redux";
function ApptType(props) {

  const { apptType, outPutFn, processObj, reschedule, followUP, inPersone = true, videoCall = true } = props;
  ////currency SYmbol
  const currencySymbol = useSelector(
    (state) => state.clinic.clincObj.clinic.currencyDto.symbol
  );

  const clinicDetails = useSelector((state) => state.clinic.clincObj.clinic);


  return (
    <div
      className={`appttype-parent ${reschedule ? "disabled" : ""} ${followUP ? "disabled" : ""
        } `}
    >
      {apptType === "Treatment" ? (
        <div className="process-showcase">
          <span className="h1">Selected Treatment</span>
          <div className="treatmentdiv">
            <div className="img">
              <img src={treatw} alt="" />
            </div>
            <div className="txt">{processObj.name}</div>
            <div className="price">
              <img src={check} alt="as" />
            </div>
          </div>
        </div>
      ) : (
        <></>
      )}

      <div className={`${apptType === "Treatment" ? "hideit" : ""} ${(clinicDetails.hasInPersonAppointment || clinicDetails.hasVideoCallAppointment) ? "" : "d-none"} `}>
        <span className="h1">Appointment Type </span>
        <div className="apptTypes">
          {
            (clinicDetails.hasInPersonAppointment && inPersone) && (
              <div
                className={`type1 type ${apptType === "InPerson" && "checked"} `}
                onClick={() => outPutFn("InPerson")}
              >
                <img src={ip} alt="ip" />
                <span>Clinic Visit</span>
                <img className="checked" src={check} alt="check" />
              </div>
            )
          }
          {
            (clinicDetails.hasVideoCallAppointment && videoCall) && (
              <div
                className={`type2 type ${apptType === "VideoCall" && "checked"} `}
                onClick={() => outPutFn("VideoCall")}
              >
                <img src={vc} alt="vc" />
                <span>Video Consultation</span>
                <img className="checked" src={check} alt="check" />
              </div>
            )
          }

        </div>
      </div>
    </div>
  );
}

export default ApptType;
