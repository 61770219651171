import React from "react";
import vc from "../../static/appt/vc2.svg";
import ip from "../../static/appt/ip2.svg";
import trt from "../../static/appt/trt.svg";

import home from "../../static/appt/home.svg";
import loc from "../../static/appt/loc.svg";
import clock from "../../static/appt/clock2.svg";
import cal from "../../static/appt/cal2.svg";
import moment from "moment";

function AppSummary(props) {

  const {
    apptType,
    date,
    selectedSlot,
    endselectedSlot,
    processObj,
    clinicObj,
    defaultHealthCenter,
  } = props;

  return (
    <div className="apt-Summary">
      <div className="header">Appointment details</div>
      <div className="body">
        <div className="ribbion">
          <div>
            <div className="apt-type">
              {apptType === "InPerson" && (
                <>
                  <img src={ip} alt="a" />
                  Clinic Visit
                </>
              )}
              {apptType === "VideoCall" && (
                <>
                  <img src={vc} alt="a" />
                  Video Consultation
                </>
              )}
              {apptType === "Treatment" && (
                <>
                  <img src={trt} alt="a" />
                  Treatment
                </>
              )}
            </div>
            <div className="icon-text">
              <div className="icon">
                <img className="star" src={clock} alt="star" />
              </div>
              <div className="txt">
                <span>Appointment Time</span>
                {moment(selectedSlot).local().format("hh:mm")} to{" "}
                {moment(endselectedSlot).local().format("hh:mm")}
              </div>
            </div>
            <div className="icon-text">
              <div className="icon">
                <img className="star" src={cal} alt="star" />
              </div>
              <div className="txt">
                <span>Appointment date</span>
                {date}
              </div>
            </div>
          </div>
          <div>
            {apptType === "Treatment" && (
              <div className="treatmentimg">
                <img src={processObj.pictureUrl} alt="a" />
                {processObj.name}
              </div>
            )}
          </div>
        </div>

        {apptType === "InPerson" && (
          <>
            <div className="icon-text">
              <div className="icon">
                <img className="star" src={home} alt="star" />
              </div>
              <div className="txt">
                <span>Clinic name</span>
                {clinicObj.clinicName ? clinicObj.clinicName : "Not Available"}
              </div>
            </div>
            <div className="icon-text">
              <div className="icon">
                <img className="star" src={loc} alt="star" />
              </div>
              <div className="txt">
                <span>Location</span>
                {defaultHealthCenter.address ? defaultHealthCenter.address : "Not Available"}
              </div>
            </div>
          </>
        )}
        {apptType === "Treatment" && (
          <>
            {processObj.enableVideoCall ? (
              <div className="icon-text">
                <div className="icon">
                  <img className="star" src={vc} alt="star" />
                </div>
                <div className="txt">
                  <span>Video Call</span>
                </div>
              </div>
            ) : (
              <>
                <div className="icon-text">
                  <div className="icon">
                    <img className="star" src={home} alt="star" />
                  </div>
                  <div className="txt">
                    <span>Clinic name</span>
                    {clinicObj.clinicName
                      ? clinicObj.clinicName
                      : "Not Available"}
                  </div>
                </div>
                <div className="icon-text">
                  <div className="icon">
                    <img className="star" src={loc} alt="star" />
                  </div>
                  <div className="txt">
                    <span>Location</span>
                    {clinicObj.location
                      ? clinicObj.clinicName
                      : "Not Available"}
                  </div>
                </div>
              </>
            )}
          </>
        )}
      </div>
    </div>
  );
}

export default AppSummary;
