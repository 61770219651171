import React, { useEffect, useState } from "react";
import { Col, Row, Spinner } from "react-bootstrap";
import UploadReport from "../components/appt/UploadReport";

import arrow from "../static/upload/arrow.svg";
import FileUploadCTX from "../components/appt/FileUploadCTX";
import { useLocation, useNavigate } from "react-router";
import { PostPatientInformationAPI } from "../services/apptservice";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";

function UploadReports() {
  const location = useLocation();

  const userToken = useSelector((state) => state.user.userObj.token);

  const navigate = useNavigate();

  useEffect(() => {

    if (location.state) {
      setReportState({
        id: location.state?.id ? location.state?.id : 0,
        appointmentId: location.state?.appointmentId
          ? location.state?.appointmentId
          : 0,
        reasonForVisit: location.state?.reasonForVisit
          ? location.state?.reasonForVisit
          : "",
        attachments: [...location.state?.attachments],
      });

      setFiles([...location.state?.attachments]);
    } else {
      navigate("/");
    }
  }, []);

  const [reportState, setReportState] = useState({});
  const [files, setFiles] = useState([]);
  const [isDisabled, setIsDisabled] = useState(false);

  const [isSaving, setIsSaving] = useState(false);

  const saveReports = () => {
    
    let finalFiles = files.map((file) => {
      let tempFile = {
        fileName: file.fileName,
        attachmentUrl: file.attachmentUrl,
        fileSize: file.fileSize,
        attachmentId: file.attachmentId,
      };
      return tempFile;
    });
    let finalState = {
      ...reportState,
      attachments: finalFiles,
    };

    if(finalState.attachments.length > 0 || finalState.reasonForVisit.length > 0){
      setIsSaving(true)
      PostPatientInformationAPI(finalState, userToken)
      .then((res) => {
        setIsSaving(false)
        navigate(`/appointmentsDetails/${finalState.appointmentId}`);
      })
      .catch((err) => {
        console.log(err);
        setIsSaving(false)
      });
    }else{
      Swal.fire({
        icon:"info",
        text: 'Explain reason of visit and upload medical reports if required during consultation',
        
        customClass: {
          confirmButton: 'btnr btnr-primary'
        }
      })
    }

    
  };

  const updateta = (e) => {
    setReportState({
      ...reportState,
      reasonForVisit: e.target.value,
    });
  };

  return (
    <div className="main-body">
      <div className="container">
        <div className="reportsmain">
          <Row>
            <Col xs={1} className="text-center d-none d-sm-block">
              <img src={arrow} alt="d" onClick={()=>navigate(-1)}/>
            </Col>
            <Col xs={12} sm={11}>
              <div className="innnercontainer">
                <div className="title">Reason & upload file</div>
                <div className="main">
                  <textarea
                    type="text"
                    className="form-control"
                    maxLength="350"
                    value={reportState.reasonForVisit}
                    onChange={(e) => updateta(e)}
                  ></textarea>
                </div>
              </div>
              <div className="innnercontainer">
                <div className="title">Upload your medical reports</div>
                <div className="subtitle">
                  Upload documents or photos of diagnosis reports,
                  prescriptions, or any other related documents
                </div>
                <div className="main">
                  <FileUploadCTX files={files} setFiles={setFiles} />
                </div>
              </div>
              <div className="text-center">
                <button
                  className="btnr btnr-primary btnr-hover"
                  onClick={() => saveReports()}
                >
                  {isSaving && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Save <span className="arr"></span>
                </button>
              </div>
            </Col>
          </Row>
        </div>
      </div>
    </div>
  );
}

export default UploadReports;
