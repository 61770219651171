import React from "react";
//import { useSelector } from "react-redux";
import Carousel from "react-bootstrap/Carousel";
import { useSelector } from "react-redux";
import Skeleton from "@mui/material/Skeleton";
import { Link } from "react-router-dom";

function Banners() {
  //get banners list from redux
  const banners = useSelector(
    (state) => state.homepage.bannersHome.bannersList
  );
  const bannersObj = useSelector((state) => state.homepage.bannersHome);

  // const bannersL = [
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/L8JYbHcK/Group-1-3.png",
  //     type: 2
  //   },
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/L8JYbHcK/Group-1-3.png",
  //     type: 2
  //   },
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/L8JYbHcK/Group-1-3.png",
  //     type: 2
  //   },
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/9FSsybVP/Frame-1000008292.png",
  //     type: 1
  //   },
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/9FSsybVP/Frame-1000008292.png",
  //     type: 1
  //   },
  //   {
  //     title: "Get Back to an Active Life with Expert Orthopedic Care",
  //     subTitle:
  //       "Regain Your Strength, Stability, and Confidence with our Orthopedic Specialist",
  //     btnTxt: "Visit Clinic",
  //     link: "https://youtube.com/",
  //     img: "https://i.postimg.cc/9FSsybVP/Frame-1000008292.png",
  //     type: 1
  //   },
  // ];
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  const getLink = (banner) => {
    console.log(!clinicObj?.subscriptionPackage === "Basic");
    let link = "";
    if (banner.bannerTypeName === "HealthCenter") {
      link = "/clinic";
    } else if (banner.bannerTypeName === "Doctor") {
      if (clinicObj?.subscriptionPackage === "Basic") {
        link = "contact-us";
      } else {
        link = `/doctorprofile/${banner.slug}`;
      }
    } else if (banner.bannerTypeName === "Treatment") {
      if (clinicObj?.subscriptionPackage === "Basic") {
        link = "contact-us";
      } else {
        link = `/treatment/${banner.slug}`;
      }
    }
    return link;
  };

  return !bannersObj.loading ? (
    <>
      {banners.length > 0 && (
        <section className="banners-home">
          <div className="banner-container-web slick-container d-none d-md-block">
            {banners.length > 0 && (
              <Carousel controls={true} touch={true}>
                {banners
                  .filter(
                    (banner) => banner.platformTypeName === "WebApplication"
                  )
                  .map((banner, index) => (
                    <Carousel.Item
                      key={banner.id}
                      style={{ height: banner.videoUrl ? "510px" : "auto" }}
                    >
                      {banner.cta ? (
                        <span>
                          {banner.videoUrl ? (
                            <video
                              className="d-block w-100"
                              style={{
                                aspectRatio: "16 / 9",
                                maxWidth: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                              src={banner.videoUrl}
                              // src={demo}
                              autoPlay
                              muted
                              loop
                              playsInline
                            />
                          ) : (
                            <img
                              className="d-block w-100"
                              src={banner.pictureUrl}
                              alt="First slide"
                            />
                          )}
                          <Carousel.Caption className="container ">
                            {banner.showHeaders && (
                              <>
                                {banner.title && (
                                  <h3
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.5s",
                                      ...(banner.titleColor !== null && {
                                        color: banner.titleColor,
                                      }),
                                    }}
                                  >
                                    {banner.title}
                                  </h3>
                                )}
                                {banner.description && (
                                  <p
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.7s",
                                      ...(banner.descriptionColor !== null && {
                                        color: banner.descriptionColor,
                                      }),
                                    }}
                                  >
                                    {banner.description}
                                  </p>
                                )}
                              </>
                            )}
                            {banner.cta && (
                              <Link
                                className="btnr btnr-primary btnr-hover animated bounceInLeft"
                                style={{
                                  animationDelay: "0.9s",
                                  ...(banner.ctaColor !== null && {
                                    backgroundColor: banner.ctaColor,
                                  }),
                                }}
                                to={getLink(banner)}
                              >
                                {banner.cta}
                                <span className="arr"></span>
                              </Link>
                            )}
                          </Carousel.Caption>
                        </span>
                      ) : (
                        <Link to={getLink(banner)}>
                          {banner.videoUrl ? (
                            <video
                              className="d-block w-100"
                              src={banner.videoUrl}
                              autoPlay
                              muted
                              loop
                              playsInline
                              style={{
                                aspectRatio: "16 / 9",
                                maxWidth: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              className="d-block w-100"
                              src={banner.pictureUrl}
                              alt="First slide"
                            />
                          )}
                          <Carousel.Caption className="container ">
                            {banner.showHeaders && (
                              <>
                                {banner.title && (
                                  <h3
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.5s",
                                      ...(banner.titleColor !== null && {
                                        color: banner.titleColor,
                                      }),
                                    }}
                                  >
                                    {banner.title}
                                  </h3>
                                )}
                                {banner.description && (
                                  <p
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.7s",
                                      ...(banner.descriptionColor !== null && {
                                        color: banner.descriptionColor,
                                      }),
                                    }}
                                  >
                                    {banner.description}
                                  </p>
                                )}
                              </>
                            )}

                            {banner.cta && (
                              <span
                                className="btnr btnr-primary btnr-hover animated bounceInLeft"
                                // style={{ animationDelay: "0.9s" }}
                                style={{
                                  animationDelay: "0.9s",
                                  ...(banner.ctaColor !== null && {
                                    backgroundColor: banner.ctaColor,
                                  }),
                                }}
                              >
                                {banner.cta}
                                <span className="arr"></span>
                              </span>
                            )}
                          </Carousel.Caption>
                        </Link>
                      )}
                    </Carousel.Item>
                  ))}
              </Carousel>
            )}
          </div>

          <div className="container banner-container-mobile slick-container d-block d-md-none">
            {banners.length > 0 && (
              // interval={null}
              <Carousel controls={true} touch={true}>
                {banners
                  .filter(
                    (banner) => banner.platformTypeName === "MobileApplication"
                  )
                  .map((banner, index) => (
                    <Carousel.Item key={banner.id}>
                      {banner.cta ? (
                        <span>
                          {banner.videoUrl ? (
                            <video
                              className="d-block w-100"
                              src={banner.videoUrl}
                              autoPlay
                              muted
                              loop
                              playsInline
                              style={{
                                aspectRatio: "16 / 9",
                                maxWidth: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              className="d-block w-100"
                              src={banner.pictureUrl}
                              alt="First slide"
                            />
                          )}
                          <Carousel.Caption className="container ">
                            {banner.showHeaders && (
                              <>
                                {banner.title && (
                                  <h3
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.5s",
                                      ...(banner.titleColor !== null && {
                                        color: banner.titleColor,
                                      }),
                                    }}
                                  >
                                    {banner.title}
                                  </h3>
                                )}
                                {banner.description && (
                                  <p
                                    className="animated bounceInLeft"
                                    style={{
                                      animationDelay: "0.7s",
                                      ...(banner.descriptionColor !== null && {
                                        color: banner.descriptionColor,
                                      }),
                                    }}
                                  >
                                    {banner.description}
                                  </p>
                                )}
                              </>
                            )}

                            {banner.cta && (
                              <Link
                                className="btnr btnr-primary btnr-hover animated bounceInLeft"
                                // style={{ animationDelay: "0.9s" }}
                                style={{
                                  animationDelay: "0.9s",
                                  ...(banner.ctaColor !== null && {
                                    backgroundColor: banner.ctaColor,
                                  }),
                                }}
                                to={getLink(banner)}
                              >
                                {banner.cta}
                                <span className="arr"></span>
                              </Link>
                            )}
                          </Carousel.Caption>
                        </span>
                      ) : (
                        <Link to={getLink(banner)}>
                          {banner.videoUrl ? (
                            <video
                              className="d-block w-100"
                              src={banner.videoUrl}
                              autoPlay
                              muted
                              loop
                              playsInline
                              style={{
                                aspectRatio: "16 / 9",
                                maxWidth: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                          ) : (
                            <img
                              className="d-block w-100"
                              src={banner.pictureUrl}
                              alt="First slide"
                            />
                          )}
                          <Carousel.Caption className="container ">
                            {banner.showHeaders && (
                              <>
                                {banner.title && (
                                  <h3
                                    className="animated bounceInLeft"
                                    // style={{ animationDelay: "0.5s" }}
                                    style={{
                                      animationDelay: "0.5s",
                                      ...(banner.titleColor !== null && {
                                        color: banner.titleColor,
                                      }),
                                    }}
                                  >
                                    {banner.title}
                                  </h3>
                                )}
                                {banner.description && (
                                  <p
                                    className="animated bounceInLeft"
                                    // style={{ animationDelay: "0.7s" }}
                                    style={{
                                      animationDelay: "0.7s",
                                      ...(banner.descriptionColor !== null && {
                                        color: banner.descriptionColor,
                                      }),
                                    }}
                                  >
                                    {banner.description}
                                  </p>
                                )}
                              </>
                            )}

                            {banner.cta && (
                              <span
                                className="btnr btnr-primary btnr-hover animated bounceInLeft"
                                // style={{ animationDelay: "0.9s" }}
                                style={{
                                  animationDelay: "0.9s",
                                  ...(banner.ctaColor !== null && {
                                    backgroundColor: banner.ctaColor,
                                  }),
                                }}
                              >
                                {banner.cta}
                                <span className="arr"></span>
                              </span>
                            )}
                          </Carousel.Caption>
                        </Link>
                      )}
                    </Carousel.Item>
                  ))}
              </Carousel>
            )}
          </div>
        </section>
      )}
    </>
  ) : (
    <section className="banners-home">
      <div className="banner-container-web slick-container d-none d-md-block">
        <Skeleton height={500} />
      </div>
      <div className="container banner-container-mobile slick-container d-block d-md-none">
        <Skeleton height={250} />
      </div>
    </section>
  );
}

export default Banners;
