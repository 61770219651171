import React from 'react';
import ShearchIcon from './ShearchIcon';
import img from "../../static/Spaciality/Frame 2085663146.svg";
import dentalImg from "../../static/Spaciality/treatment.png";
import { Col, Row } from 'react-bootstrap';
import useDocumentTitle from '../../utils/useDocumentTitle';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import { Link, useNavigate, useParams } from 'react-router-dom';

import star from "../../static/doctor/star.svg";
import nop from "../../static/doctor/nop.svg";
import exp from "../../static/doctor/exp.svg";
import aoe from "../../static/doctor/aoeicn.svg";

import {
  updateDeepLinking,
  updateLoginDrawerState,
} from "../../redux/webAppSlice";
import { useEffect } from 'react';
import { GetSpecialtiesDetails } from '../../services/specialityservice';
import { useState } from 'react';
import TreatmentTrack from '../homepagecomps/treatmentTrack/TreatmentTrack';
import { Skeleton } from '@mui/material';
import { use } from 'i18next';

const SpacialityDetails = () => {
  const { id } = useParams();
  useDocumentTitle('Spaciality Details');

  const [speciality, setSpeciality] = useState({
    details: {},
    isLoading: true
  })


  useEffect(() => {
    setSpeciality({
      ...speciality,
      isLoading: true
    })
    GetSpecialtiesDetails(id).then((res) => {
      // console.log(res);
      setSpeciality({
        details: { ...res },
        isLoading: false
      })
    }).catch((err) => {
      console.log(err)
      setSpeciality({
        ...speciality,
        isLoading: false
      })
    })
  }, [id])

  const extractDetailsFromHTML = (htmlString) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    const firstTag = doc.body.children[0];
    const restHTML = [...doc.body.children].slice(1).map(child => child.outerHTML).join('');

    return {
      firstTag: firstTag ? firstTag.outerHTML : '',
      restHTML: restHTML,
    };
  };


  const doctorList = useSelector((state) => state.homepage.ourDoctorsHome.list);
  const clinicObj = useSelector((state) => state.clinic.clincObj.clinic);

  //get user detals redux
  const userDetails = useSelector((state) => state.user.userObj);
  //get user detals redux
  const doctorListLayout = useSelector(
    (state) => state.clinic.clincObj.clinic.doctorListLayout
  );

  const hasDoctorFullProfile = useSelector(
    (state) => state.clinic?.clincObj?.clinic?.hasDoctorFullProfile
  );

  const navigate = useNavigate();

  const dispatch = useDispatch();
  const openLoginDrawer = (tempObj) => {
    dispatch(updateLoginDrawerState(true));

    dispatch(updateDeepLinking(tempObj));
  };

  const navigateToBookAppt = (stateforNextPage) => {
    if (userDetails !== null) {
      navigate("/bookappt", { state: stateforNextPage });
    } else if (userDetails === null) {
      let tempObj = {
        toLink: "/bookappt",
        state: {
          doctor: stateforNextPage.doctor,
          apptType: stateforNextPage.apptType,
        },
      };
      openLoginDrawer(tempObj);
    }

    //check whether user is Logged in ??
  };

  const getColor = (index) => {
    let color = "";
    if (index % 4 === 0) {
      color = "blue";
    } else if (index % 4 === 1) {
      color = "green";
    } else if (index % 4 === 2) {
      color = "yellow";
    } else if (index % 4 === 3) {
      color = "red";
    }
    return color;
  };



  const isActive = (slug) => {
    let ative;
    if (slug === id) {
      ative = "active";
    }
    return ative;
  }



  const specialities = useSelector((state) => state.homepage.specialities);
  const [ipVal, setIpVal] = useState("");
  const [filteredSpecialities, setFilteredSpecialities] = useState([]);
  const [inputValue, setInputValue] = useState('');


  // debounching start
  useEffect(() => {
    const delaySearch = setTimeout(() => {
      setIpVal(inputValue);
    }, 600);

    return () => clearTimeout(delaySearch);
  }, [inputValue]);

  const handleInput = (event) => {
    setInputValue(event.target.value);
  };
  // debounching end

  useEffect(() => {

    if (ipVal === "") {
      setFilteredSpecialities(specialities.list.specialties);
    } else {
      // dispatch(getSpecialitiesBySearchRedux(ipVal));
      setFilteredSpecialities(specialities.list.specialties.filter(spec => spec.name.toLowerCase().includes(ipVal.toLowerCase())));
    }
  }, [ipVal, specialities.list.specialties]);

  const getPlan = () => {
    let plan = "Basic";
    if (clinicObj?.subscriptionPackage) {
      plan = clinicObj?.subscriptionPackage
    } else {
      return plan = undefined;
    }
    return plan;
  }


  const [displayCount, setDisplayCount] = useState(3);
  const [displayValue, setDisplayValue] = useState(3);
  const [isExpanded, setIsExpanded] = useState(false);

  const handleSeeMoreClick = () => {
    const screenWidth = window.innerWidth;
    if (isExpanded) {
      setDisplayCount(screenWidth < 576 ? 3 : 6);
    } else {
      setDisplayCount(filteredSpecialities.length);
    }
    setIsExpanded(!isExpanded);
  };

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;

      if (isExpanded) {
        setDisplayCount(filteredSpecialities.length);

      } else {
        setDisplayCount(screenWidth < 576 ? 3 : 6);
        setDisplayValue(screenWidth < 576 ? 3 : 6);
      }
    };
    handleResize();
    window.addEventListener('resize', handleResize);
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const testData = "Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum."

  const skeleton = <>
    <div className='container'>
      <div className='spaciality_details'>
        <div className='sp-left'>
          <div className='filter_area'>
            <Skeleton variant='text' width={200} height={50} />
            <Skeleton variant='text' width="100%" height={100} />

            <div className='spaciality'>
              <ul className='spaciality_list'>
                <Skeleton width="100%" height={50} />
                <Skeleton width="100%" height={50} />
                <Skeleton width="100%" height={50} />
              </ul>
            </div>
          </div>
        </div>
        <div className='sp-right'>
          <div className='sp_about'>
            <Skeleton variant='rect' width={250} height={40} />
            <Row className='up_sec'>
              <Col md={7} >
                <Skeleton variant='text' width="100%" height={30} />
                <Skeleton variant='text' width="100%" height={30} />
                <Skeleton variant='text' width="100%" height={30} />
                <Skeleton variant='text' width="100%" height={30} />
              </Col>
              <Col md={5} className='img'>
                <Skeleton variant='rect' width="100%" height={300} />
              </Col>

            </Row>
          </div>
        </div>
      </div>
    </div>
  </>

  return !speciality.isLoading ? (
    <div className='container'>
      <div className='spaciality_details'>
        <div className='sp-left'>
          <div className='filter_area'>
            <h3>Specialties</h3>
            <div className='search'>
              <ShearchIcon />
              <input value={inputValue} onChange={handleInput} type="text" placeholder='Search' />
            </div>
            <div className='spaciality'>
              <ul className='spaciality_list'>
                {
                  filteredSpecialities?.filter(spec => spec.name.toLowerCase().includes(ipVal.toLowerCase())).slice(0, displayCount).map((spec, index) => (
                    <li className={isActive(spec.slug)} key={index}>
                      <Link to={`/speciality/${spec.slug}`}>
                        <img src={spec.iconUrl} alt="" />
                        {spec.name}
                      </Link>

                    </li>
                  ))
                }
              </ul>
              {filteredSpecialities.length > displayValue && (
                <div className='d-flex justify-content-center view_more_btn'>
                  <button className='btnr btnr-primary-outline mt-2' onClick={handleSeeMoreClick}>
                    {isExpanded ? 'View Less' : 'View More'}
                  </button>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className='sp-right'>
          <div className='sp_about'>
            <h2>{speciality.details.name}</h2>
            {/* <Row className='up_sec'>
              <Col md={7} dangerouslySetInnerHTML={{
                __html:
                  speciality.details.description,
              }}>

              </Col>
              <Col md={5} className='img'>
                <img src={speciality.details.pictureUrl} alt="" />
              </Col>

            </Row> */}
            <Row className='up_sec justify-content-between'>
              <Col md={7} dangerouslySetInnerHTML={{
                __html: extractDetailsFromHTML(speciality.details.description).firstTag,
              }}>
              </Col>
              <Col md={4} className='img'>
                <img src={speciality.details.pictureUrl} alt="" />
              </Col>
              <Col md={12} className='' dangerouslySetInnerHTML={{
                __html: extractDetailsFromHTML(speciality.details.description).restHTML,
              }}>
              </Col>
            </Row>

          </div>
          {
            speciality.details.doctors?.length > 0 && (
              <div className='specialists_con'>
                <div className="title">
                  <h3>Our Doctors</h3>
                </div>

                <Swiper
                  slidesPerView={"auto"}
                  centeredSlides={speciality.details.doctors.length > 1 ? false : false}
                  spaceBetween={0}
                  pagination={{
                    clickable: true,
                  }}
                  modules={[Pagination]}
                  className="mySwiper layoutm-track"
                >
                  {speciality.details.doctors.map((doc, index) => (
                    <SwiperSlide className={`doctor-layout-card ${getPlan()}`} key={index}>
                      <div className="prfl-img">
                        <img src={doc.pictureUrl} alt="img" />
                      </div>
                      <div className="doc-info">
                        <div className="d-Name">{doc.name}</div>
                        <div className="d-Spec">{doc.title}</div>
                        <div className="d-Study">{doc.study}</div>
                        {doc.areasOfExpertiseDtos && (
                          <div className="d-tags">
                            {doc.areasOfExpertiseDtos.map((tag, index) => (
                              <div
                                key={index}
                                className={`tag tag-${getColor(index)}`}
                              >
                                <img src={aoe} alt="asd" />
                                {/* {tag.value} */}
                                {tag.value.length > 25 ? `${tag.value.slice(0, 25)}...` : tag.value}
                              </div>
                            ))}
                          </div>
                        )}

                        <div className='ratting'>
                          <Row>
                            {(getPlan() !== "Basic" && doc.rating > 0) && (
                              <Col
                                xs={12}
                                className="d-flex align-items-center doc-stats"
                              >
                                <div className="doc-nav-img">
                                  <img src={star} alt="ico" />
                                </div>

                                <div className="flex-grow-1 ms-1">
                                  <span className="doc-stats-tit">Rating</span>
                                  <span className="doc-stats-txt">
                                    {doc.rating} out of 5
                                  </span>
                                </div>
                              </Col>
                            )}

                            {doc.experience > 0 && (
                              <Col
                                xs={6}
                                className="d-flex align-items-center doc-stats"
                              >
                                <div className="doc-nav-img">
                                  <img src={exp} alt="ico" />
                                </div>

                                <div className="flex-grow-1 ms-1">
                                  <span className="doc-stats-tit">Experience</span>
                                  <span className="doc-stats-txt">
                                    {doc.experience}+ years
                                  </span>
                                </div>
                              </Col>
                            )}

                            {(getPlan() !== "Basic" && doc.patientsServed > 0) && (
                              <Col
                                xs={6}
                                className="d-flex align-items-center doc-stats"
                              >
                                <div className="doc-nav-img">
                                  <img src={nop} alt="ico" />
                                </div>

                                <div className="flex-grow-1 ms-1">
                                  <span className="doc-stats-tit">Patient</span>
                                  <span className="doc-stats-txt">
                                    {doc.patientsServed}+
                                  </span>
                                </div>
                              </Col>
                            )}


                          </Row>
                        </div>
                        <div className="d-btn">

                          {
                            hasDoctorFullProfile && (
                              <Link
                                to={`/doctorprofile/${doc.slug}`}
                                state={{ data: doc }}
                                className="btnr btnr-primary-outline mb-3 w-100 btnr-hover"
                              >
                                View Profile
                                <span className="arr"></span>
                              </Link>)
                          }
                          {
                            (clinicObj?.hasInPersonAppointment || clinicObj?.hasVideoCallAppointment) && (getPlan() !== "Basic") &&
                            <button
                              onClick={() => navigateToBookAppt({ doctor: doc })}
                              className={`btnr btnr-primary btnr-hover w-100`}
                            >
                              Book consultation <span className="arr"></span>
                            </button>
                          }
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            )
          }

          <div className='explore_treatments'>
            {
              speciality?.details?.treatments?.length > 0 && (
                <>
                  <div className="title_b">
                    <h3>Treatments</h3>
                  </div>
                  <div className="tlist ">
                    <TreatmentTrack treatmentsList={speciality.details.treatments} />
                  </div>
                </>
              )
            }
          </div>
        </div>
      </div>
    </div>
  ) : skeleton;
};

export default SpacialityDetails;