import React, { useEffect, useRef, useState } from "react";
import { Col, Modal, Row, Spinner } from "react-bootstrap";
import {
  Drawer,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";

import { AdapterMoment } from "@mui/x-date-pickers/AdapterMoment";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Cropper from "react-cropper";
import "cropperjs/dist/cropper.css";
import camera from "../../static/login/camera.svg";
import swal from "sweetalert";
import { useTranslation } from "react-i18next";
import "../../styles/layout/cropper.css";
import { useDispatch, useSelector } from "react-redux";
import {
  UpdatePatientProfile,
  mediaUpload,
} from "../../services/patientService";
import { updateCurrentUser } from "firebase/auth";
import { updatetoNewUserData } from "../../redux/userSlice";
import moment from "moment";
import CountryCodeSelector from "../microcomponenets/CountryCodeSelector";

function GenProfileUpdate(props) {
  const { isGenDrawerOpen, setIsGenDrawerOpen, setIspageOneActive, user } =
    props;

  const { t } = useTranslation();
  //Form signup

  const [phoneNumber, setPhoneNumber] = useState({
    countryCode: `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
    number: "",
  });

  useEffect(() => {
    validation();
  }, []);

  //get user details fom redux
  const userDetails = user.userObj.patientData;
  const token = user.userObj.token;

  const [formValues, setFormValues] = useState({
    name: userDetails.name ? userDetails.name : "",
    email: userDetails.email ? userDetails.email : "",
    sex: userDetails.sex ? userDetails.sex : "",
    dob: userDetails.dob ? userDetails.dob : "",
    pictureId: userDetails.pictureId ? userDetails.pictureId : 0,
    pictureUrl: userDetails.pictureUrl ? userDetails.pictureUrl : "",
    appointmentPhone: userDetails.appointmentPhone? userDetails.appointmentPhone : "",
    appointmentCountryCode: userDetails.appointmentCountryCode? userDetails.appointmentCountryCode : `${process.env.REACT_APP_DEFAULT_COUNTRYCODE}`,
  });

  // console.log("formValue", formValues)
  // console.log("userDetails", userDetails)

  const handle12Change = (field) => (e) => {
    setFormValues({ ...formValues, [field]: e.target.value });
  };

  const onKeyDown = (e) => {
    e.preventDefault();
  };

  const [isSaving, setIsSaving] = useState(false);

  const dispatch = useDispatch();



  const onSubmit = (e) => {
    e.preventDefault();

    // console.log(formValues)

    let tempErrors = {
      name: formValues.name ? "" : "Add a Full Name",
      sex: formValues.sex === "" ? "Select a Gender" : "",
      dob: formValues.dob === "" ? "Add your Date of Birth" : "",
      appointmentPhone: formValues.appointmentPhone === "" ? "Add your Appointment Phone Number" : "",
    };

    setErrors(tempErrors);

    if (Object.values(tempErrors).every((x) => x == "")) {
      setIsSaving(true);

      UpdatePatientProfile(formValues, token)
        .then((response) => {
          setIsSaving(false);
          dispatch(updatetoNewUserData(response.response.patientData));
          setIsGenDrawerOpen(false);
          setIspageOneActive(false);
          swal("Profile Updated", {
            icon: "success",
          })
        })
        .catch(() => {
          setIsSaving(false);
        });
    } else {
      swal({
        icon: "error",
        title: getErrorMessage(tempErrors),
      });
    }
  };


  function getErrorMessage(tempErrors) {

    const errorKeys = Object.values(tempErrors);

    const nonEmptyErrors = errorKeys.filter((message) => message !== "");

    if (nonEmptyErrors.length === Object.keys(tempErrors).length) {
      return `Enter Full Name, Select Your Gender and Date of Birth`;
    } else {
      return nonEmptyErrors.join(" and ");
    }

  }

  let tempErrors = {
    name: "",
    sex: "",
    dob: "",
  };
  const [errors, setErrors] = useState(tempErrors);
  const validation = () => {
    let tempErrors = {
      name: formValues.name ? "" : "Add a Full Name",
      sex: formValues.sex === "" ? "Select a Gender" : "",
      dob: formValues.dob === "" ? "Add your Date of Birth" : "",
    };
    setErrors(tempErrors);

    return Object.values(tempErrors).every((x) => x == "");
  };

  //check for clinic Details
  //const  clinicDetails  = useSelector((state) => state.clinic.clincObj.clinic);

  const [isCropping, setIsCropping] = useState(false);

  const [imgSrc, setImgSrc] = useState("");

  const openCropper = (e) => {
    if (e.target.files && e.target.files.length > 0) {
      //setCrop(undefined) // Makes crop preview update between images.
      const reader = new FileReader();
      reader.addEventListener("load", () =>
        setImgSrc(reader.result.toString() || "")
      );
      reader.readAsDataURL(e.target.files[0]);
      handleShow();
    }
  };

  //modal

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);

  const handleShow = () => setShow(true);

  //cropper
  function dataURLtoFile(dataurl, filename) {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[1]),
      n = bstr.length,
      u8arr = new Uint8Array(n);

    while (n--) {
      u8arr[n] = bstr.charCodeAt(n);
    }

    var file1 = new File([u8arr], filename, { type: mime });
    var formData = new FormData();
    formData.append("file", file1);

    //MEDIA UPLOAD

    mediaUpload(formData, token)
      .then((response) => {
        if (response.status === "Success") {
          setFormValues({
            ...formValues,
            pictureId: response.response.pictureId,
            pictureUrl: response.response.pictureUrl,
          });
          setShow(false);
          setIsCropping(false);
        }
      })
      .catch((err) => {
        setIsCropping(false);
      });

    return new File([u8arr], filename, { type: mime });
  }
  const cropperRef = useRef(null);
  const handleCrop = () => {
    setIsCropping(true);
    const imageElement = cropperRef?.current;
    const cropper = imageElement?.cropper;


    var file1 = dataURLtoFile(
      cropper
        .getCroppedCanvas({ fillColor: "#fff", width: 320, height: 320 })
        .toDataURL(),
      "product-img-320X320"
    );
  };

  const checkNumber = (e) => {
    let tempNumber = e.target.value;
    let isnum = /^\d+$/.test(tempNumber);

    if (isnum || tempNumber === "") {
      handle12Change("appointmentPhone")(e);
      setPhoneNumber({ ...phoneNumber, number: e.target.value });
    }
  };


  React.useEffect(() => {
    setFormValues((prevFormValues) => {
      return {
        ...prevFormValues,
        countryCode: phoneNumber.countryCode,
      };
    });
  }, [phoneNumber.countryCode]);

  return (
    <Drawer
      className={`updateProfile `}
      anchor="right"
      open={isGenDrawerOpen}
    // onClose={() => {
    //   //setIsDrawerOpen(false);
    //   validationNormalizes()
    // }}
    >
      <form className="startup-form" onSubmit={onSubmit}>
        <Row>
          <Col xs={12} className="text-1">
            Update Your Profile
          </Col>
          <Col xs={12} className="camera">
            <div className="camera-in">
              <img
                src={formValues.pictureUrl}
                className="avatar"
                alt="userschiolete"
              />
              <div>
                <label htmlFor="file">
                  <img src={camera} alt="camera" />
                </label>

                <input
                  type="file"
                  id="file"
                  onChange={(e) => openCropper(e)}
                  hidden
                />
              </div>
            </div>
          </Col>
          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.name !== "" && {
                error: true,
                helperText: errors.name,
              })}
              label="Your Full name"
              value={formValues.name}
              type="text"
              onChange={handle12Change("name")}
              placeholder="Your Full name"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>
          <Col xs={12} className="form-ips d-none">
            <TextField
              label="Email Address"
              type="text"
              value={formValues.email}
              onChange={handle12Change("email")}
              placeholder="yourname@mail.com"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
            />
          </Col>

          <Col xs={12} className="form-ips">
            <TextField
              {...(errors.number !== "" && {
                error: true,
                helperText: errors.number,
              })}
              label="Phone Number"
              placeholder="987632XX"
              autoComplete="new-password"
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                startAdornment: (
                  <CountryCodeSelector
                    defaultValue={process.env.REACT_APP_DEFAULT_COUNTRYCODE}
                    phoneNumber={phoneNumber}
                    setPhoneNumber={setPhoneNumber}
                  />
                ),
              }}
              value={phoneNumber.number}
              type="text"
              onChange={(e) => checkNumber(e)}
            />
          </Col>

          <Col xs={12} className="form-ips">
            <FormControl className="selectfmc" error={errors.sex !== ""}>
              <InputLabel id="demo-simple-select-autowidth-label" shrink={true}>
                Gender
              </InputLabel>
              <Select
                labelId="demo-simple-select-autowidth-label"
                id="demo-simple-select-autowidth"
                className={formValues.sex === "none" ? "selectgray" : ""}
                value={formValues.sex}
                onChange={handle12Change("sex")}
                label="Gender"
              >
                <MenuItem className="d-none" value="none" disabled>
                  Select your gender
                </MenuItem>
                <MenuItem value={"male"}>Male</MenuItem>
                <MenuItem value={"female"}>Female</MenuItem>
                <MenuItem value={"others"}>Others</MenuItem>
              </Select>
              {errors.sex !== "" && (
                <FormHelperText>{errors.sex}</FormHelperText>
              )}
            </FormControl>
          </Col>

          <Col xs={12} className="form-ips">
            <LocalizationProvider dateAdapter={AdapterMoment}>
              <InputLabel
                id="demo-simple-select-autowidth-label2"
                shrink={true}
                className="datelabel"
              >
                Date of Birth
              </InputLabel>
              <DesktopDatePicker
                labelId="demo-simple-select-autowidth-label2"
                format="DD/MM/YYYY"
                value={moment.utc(formValues.dob)}
                disableFuture={true}
                onChange={(date) => {
                  setFormValues({
                    ...formValues,
                    dob: date,
                  });
                }}
                slotProps={{
                  textField: {
                    variant: "outlined",
                    ...(errors.dob !== "" && {
                      error: true,
                      helperText: errors.dob,
                    }),
                  },
                }}
              />
            </LocalizationProvider>
          </Col>

          <Col xs={12} className=" text-center">
            <button
              className="btnr btnr-secondary me-3"
              type="button"
              onClick={() => setIsGenDrawerOpen(false)}
            >
              Cancel
            </button>

            <button className="btnr btnr-primary" type="submit">
              {isSaving && (
                <Spinner
                  as="span"
                  animation="border"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
              )}
              Save
            </button>
          </Col>
        </Row>
        <Row>
          <Col md={12}>
            <Modal className="cropper-modal" show={show} onHide={handleClose}>
              <Modal.Header closeButton>
                {/* <Modal.Title>{t("ep-t-15")}</Modal.Title> */}
                <Modal.Title>Crop Image</Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Cropper
                  src={imgSrc}
                  style={{ height: 400, width: "100%" }}
                  // Cropper.js options
                  initialAspectRatio={1 / 1}
                  aspectRatio={1 / 1}
                  guides={true}
                  //crop={onCrop}
                  crop={() => handleCrop}
                  ref={cropperRef}
                />
              </Modal.Body>
              <Modal.Footer>
                <button className="btnr btnr-secondary" onClick={handleClose}>
                  Cancel
                </button>
                <button className="btnr btnr-primary" onClick={handleCrop}>
                  {isCropping && (
                    <Spinner
                      as="span"
                      animation="border"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                  )}
                  Crop
                </button>
              </Modal.Footer>
            </Modal>
          </Col>
        </Row>
      </form>
    </Drawer>
  );
}

export default GenProfileUpdate;
