import React from 'react';

const EditIcon = () => {
    return (
        <svg width="17" height="16" viewBox="0 0 17 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clipPath="url(#clip0_2666_7067)">
                <path fillRule="evenodd" clipRule="evenodd" d="M15.5909 3.06248C15.6622 3.17064 15.6939 3.30007 15.6808 3.42892C15.6677 3.55778 15.6105 3.67816 15.5189 3.76973L8.62418 10.6637C8.55364 10.7342 8.46564 10.7847 8.36918 10.81L5.49743 11.56C5.4025 11.5847 5.30275 11.5842 5.20807 11.5585C5.1134 11.5328 5.02709 11.4828 4.95772 11.4134C4.88835 11.3441 4.83833 11.2578 4.81262 11.1631C4.78692 11.0684 4.78642 10.9687 4.81118 10.8737L5.56118 8.00273C5.58307 7.91655 5.62437 7.83651 5.68193 7.76873L12.6022 0.852982C12.7076 0.747644 12.8506 0.688477 12.9997 0.688477C13.1487 0.688477 13.2917 0.747644 13.3972 0.852982L15.5189 2.97398C15.5458 3.00099 15.57 3.03064 15.5909 3.06248ZM14.3257 3.37148L12.9997 2.04623L6.61118 8.43473L6.14243 10.2295L7.93718 9.76073L14.3257 3.37148Z" fill="#536288" />
                <path d="M14.2302 11.8697C14.4352 10.1176 14.5006 8.35208 14.4259 6.58967C14.4243 6.54815 14.4313 6.50674 14.4464 6.46803C14.4615 6.42931 14.4844 6.39413 14.5137 6.36467L15.2517 5.62667C15.2718 5.60639 15.2974 5.59236 15.3254 5.58628C15.3533 5.58019 15.3824 5.58231 15.4092 5.59237C15.436 5.60243 15.4593 5.62 15.4763 5.64299C15.4933 5.66597 15.5034 5.69338 15.5052 5.72192C15.6441 7.81535 15.5914 9.91709 15.3477 12.0009C15.1707 13.5174 13.9527 14.7062 12.4429 14.8749C9.82194 15.1652 7.17692 15.1652 4.55593 14.8749C3.04693 14.7062 1.82818 13.5174 1.65118 12.0009C1.34023 9.34248 1.34023 6.65686 1.65118 3.99842C1.82818 2.48192 3.04618 1.29317 4.55593 1.12442C6.54521 0.903832 8.54948 0.850395 10.5477 0.964671C10.5763 0.966724 10.6037 0.97693 10.6267 0.994079C10.6496 1.01123 10.6672 1.0346 10.6773 1.06144C10.6874 1.08827 10.6896 1.11743 10.6837 1.14548C10.6777 1.17352 10.6638 1.19927 10.6437 1.21967L9.89893 1.96367C9.86976 1.99273 9.83495 2.01551 9.79664 2.0306C9.75833 2.04569 9.71733 2.05278 9.67618 2.05142C8.00875 1.99474 6.3394 2.05866 4.68118 2.24267C4.19664 2.2963 3.74432 2.51171 3.3973 2.85411C3.05027 3.19651 2.82881 3.64589 2.76868 4.12967C2.46797 6.70091 2.46797 9.29843 2.76868 11.8697C2.82881 12.3535 3.05027 12.8028 3.3973 13.1452C3.74432 13.4876 4.19664 13.703 4.68118 13.7567C7.19743 14.0379 9.80143 14.0379 12.3184 13.7567C12.803 13.703 13.2553 13.4876 13.6023 13.1452C13.9493 12.8028 14.1701 12.3535 14.2302 11.8697Z" fill="#536288" />
            </g>
            <defs>
                <clipPath id="clip0_2666_7067">
                    <rect x="0.5" width="16" height="16" rx="8" fill="white" />
                </clipPath>
            </defs>
        </svg>
    );
};

export default EditIcon;